// LoaderCube.tsx
import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { Mesh } from 'three';

interface CubeProps {
  position: [number, number, number]; // Ensure exactly three elements
}

const LoaderCube: React.FC<CubeProps> = ({ position }) => {
  const ref = useRef<Mesh>(null!);

  useFrame(() => {
    if (ref.current) {
      ref.current.rotation.x += 0.05;
      ref.current.rotation.y += 0.05;
    }
  });

  return (
    <mesh ref={ref} position={position}>
      <boxGeometry args={[0.25, 0.25, 0.25]} />
      <meshStandardMaterial color="white" />
    </mesh>
  );
};

export default LoaderCube;