import { useEffect, useRef } from 'react';
import { useThree } from '@react-three/fiber';
import { Audio, AudioListener } from 'three';

const useAudioListener = () => {
  const { camera } = useThree();
  const listenerRef = useRef<AudioListener | null>(null);

  useEffect(() => {
    let listener = camera.children.find(child => child instanceof AudioListener) as AudioListener;
    
    if (!listener) {
      listener = new AudioListener();
      camera.add(listener);
    }
    
    listenerRef.current = listener;

    return () => {
      if (listenerRef.current && listenerRef.current.children.length === 0) {
        camera.remove(listenerRef.current);
      }
    };
  }, [camera]);

  return listenerRef.current;
};

export default useAudioListener;