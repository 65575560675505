import React, { useState, useEffect, useRef } from "react";
import icoMenuHome from "../../../logos/ico_menu_home.svg";
import prodigyLogo from "../../../logos/ico_import.svg";
import backButton from "../../../logos/ico_chev_leftbackbutin.svg";
import { PopupModal } from "../Modal/PopupModal";
import { useLocation, useNavigate } from "react-router-dom";
import { signOut } from "../../../services/authService";
import { listProjectsAndOrgs } from "../../../services/projectService";
import localStorageService from "../../../services/localStorageService"; // Update the path accordingly
import { Organization } from "../../../types/projectTypes";
import altouralogo from "../../../logos/Altoura_Logo_White.svg";
import "./Header.css";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import CustomDropdown from "./CustomHeaderDropdown/CustomDropDown"; // Import the CustomDropdown component
interface Step {
  title: string;
  text: string;
  image?: string;
  PageNo: number;
  options?: string;
  CorrectAnswer?: string;
}

interface HeaderProps {
  data: string;
  list?: Organization[]; // Assuming the list prop is required and should always be provided
  onOrgChange?: (orgId: number) => void;
  isDropdownDisabled?: boolean; // New prop to control dropdown
  projectResponse?: any;
  displaypic?: any;
  username?: any;
  useremail?: any;
}

function useLocalStorage(key: any, initialValue: any) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const setValue = (value: any) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

export const Header: React.FC<HeaderProps> = ({
  data,
  list = [],
  onOrgChange,
  isDropdownDisabled,
  projectResponse,
  displaypic,
  username,
  useremail,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [userLogoText, setUserLogoText] = useState("");
  const [displayPicture, setDisplayPicture] = useState("");
  const [displayPictureLoading, setDisplayPictureLoading] = useState(true);
  const [selectedOrgId, setSelectedOrgId] = useLocalStorage(
    "selectedOrgId",
    ""
  );
  const [name, setName] = useState("");
  const [selectedOrgName, setSelectedOrgName] = useState("");
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const email = localStorage.getItem("email");
  const [isDropDownOpen, setIsDropdownOpen] = useState(false);
  const [isProjectsdisplay, setIsProjectsdisplay] = useState<string | null>("");
  const [isObjectsdisplay, setIsObjectsdisplay] = useState<string | null>("");
  const [istoggledisplay, setIsToggledisplay] = useState<string | null>("");
  const shouldDisplayTabs =
    location.pathname === "/dashboard" || location.pathname === "/ObjectList";



  useEffect(() => {
    const fetchData = async () => {
      try {
        if (projectResponse) {
          setDisplayPicture(projectResponse.DisplayPicture);
          setDisplayPictureLoading(false);
          localStorage.setItem(
            "displayPicture",
            projectResponse.DisplayPicture
          );
          setName(projectResponse.UserName);
          localStorage.setItem("username", projectResponse.UserName);
          const isProjects = localStorage.getItem("isProjects");

          setIsProjectsdisplay(isProjects);
          const isObjects = localStorage.getItem("isObjects");

          setIsObjectsdisplay(isObjects);
          localStorage.setItem("isToggleshow", "true");

          const toggledisp = localStorage.getItem("isToggleshow");
          setIsToggledisplay(toggledisp);

          if (!selectedOrgId || selectedOrgId === "") {
            setSelectedOrgId(projectResponse.orgsList[0].OrganizationID);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [projectResponse, selectedOrgId, setSelectedOrgId]);

  useEffect(() => {
    if (onOrgChange && selectedOrgId) {
      onOrgChange(selectedOrgId);
    }
  }, [selectedOrgId, onOrgChange]);

  useEffect(() => {
    const firstName = localStorage.getItem("FirstName");
    if (firstName) {
      setUserLogoText(firstName.substring(0, 2).toUpperCase());
    }
  }, []);

  const handleSignOut = async () => {
    await signOut();
    navigate("/signout");
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    // Retrieve the organization name from local storage when the component mounts
    const storedOrgName = localStorageService.getItem("selectedOrgName");
    if (storedOrgName) {
      setSelectedOrgName(storedOrgName);
    }
  }, []);

  return (
    <nav className="bg-[#1F1F1F] z-10 p-4 h-[64px] flex justify-between items-center fixed w-full">
      <div className="flex items-center">
        <div className="w-[167.44px] h-[22px] justify-center items-center inline-flex">
          <div
            className="w-[167.44px] h-[52px] relative"
            onClick={() => {
              navigate("/");
            }}
          >
            <img src={altouralogo} />
          </div>
        </div>
        {shouldDisplayTabs && (
          <div className="w-[64px] h-[43px] pl-6 justify-start items-center gap-6 inline-flex cursor-pointer">
            <div
              className={`px-1 py-2.5 border-b-2 ${
                isProjectsdisplay === "true"
                  ? "border-[#4295e7]"
                  : "border-none"
              }  justify-center items-center gap-2.5 flex`}
            >
              {isProjectsdisplay && (
                <div
                  className="text-white text-base font-normal font-['Selawk'] leading-normal hover:text-white/70"
                  onClick={() => {
                    navigate(`/`);
                  }}
                >
                  Projects
                </div>
              )}
            </div>
            <div
              className={`px-1 py-2.5 border-b-2 ${
                isObjectsdisplay === "true" ? "border-[#4295e7]" : "border-none"
              } justify-center items-center gap-2.5 flex`}
            >
              {isObjectsdisplay && (
                <div
                  className="text-white hover:text-white/70 text-base font-normal font-['Selawk'] leading-normal"
                  onClick={() => {
                    navigate(`/ObjectList`);
                  }}
                >
                  Objects
                </div>
              )}
            </div>
          </div>
        )}

        <div className="text-white ml-2">{data}</div>
      </div>

      <div
        className={`fixed inset-y-0 left-0 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } w-64 z-30 transition duration-300 ease-in-out`}
      >
        <div className="bg-[#1F1F1F] h-full p-full">
          <div className="flex items-center text-white mb-4">
            <img
              src={backButton}
              alt="Back"
              className="mr-2"
              onClick={() => setIsOpen(!isOpen)}
            />
            <span>
              <div
                className={
                  data.length > 20 ? "text-white text-xs" : "text-white"
                }
              >
                {data}
              </div>
            </span>
          </div>
          <hr className="border-t border-white opacity-25" />
          <a
            href="#"
            className="flex items-center text-white py-2 hover:bg-[#1F1F1F]"
            onClick={(e) => {
              e.preventDefault();
              setIsModalOpen(true);
            }}
          >
            <img
              src={prodigyLogo}
              alt="Prodigy Managed Files"
              className="mr-2"
            />{" "}
            Prodigy Managed Files
          </a>
          <hr className="border-t border-white opacity-25" />
          <a
            onClick={() => navigate("/")}
            className="flex items-center text-white py-2 hover:bg-[#1F1F1F] w-full"
          >
            <img src={icoMenuHome} alt="Home" className="mr-2" /> Altoura Home
          </a>
          <hr className="border-t border-white opacity-25 w-full" />
        </div>
      </div>
      <PopupModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />

      <div className="relative flex items-center" ref={dropdownRef}>
        {list.length > 0 && (
          <CustomDropdown
            list={list}
            selectedOrgId={selectedOrgId}
            setSelectedOrgId={setSelectedOrgId}
            isDropdownDisabled={isDropdownDisabled || false}
            onOrgChange={onOrgChange}
          />
        )}

        <div
          className="w-8 h-8 bg-[#1F1F1F] hover:bg-gray-300 active:bg-[#4295e7] text-white flex justify-center items-center rounded-full cursor-pointer"
          onClick={() => setDropdownOpen(!dropdownOpen)}
          style={{ position: "relative", overflow: "hidden" }}
        >
          {!displayPictureLoading && (
    <img
      src={
        displayPicture
          ? `https://fbxloader.imgix.net/${displayPicture}`
          : "https://as1.ftcdn.net/v2/jpg/03/16/12/52/1000_F_316125289_3GTL3Yd9JVQz3Nw50uAEEkOpX6GvK0LE.jpg"
      }
      alt=""
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "50%",
      }}
    />
  )}
        </div>
        {dropdownOpen && (
          <div
            style={{ marginTop: "10rem", zIndex: 1000 }}
            className="z-90 origin-top-right absolute right-10 mr-4 py-2 w-48 rounded-md"
          >
            <div className="w-[248px] h-[110px] py-2 bg-white rounded shadow border border-neutral-400 flex-col justify-start items-start inline-flex  ">
              <div className="self-stretch px-4 py-1 justify-end items-center gap-2.5 inline-flex">
                <div
                  className="text-center text-zinc-800 cursor-pointer text-sm font-normal font-['Selawk'] leading-tight mt-[-8px] hover:bg-[#f0f2f5] active:[#e8eaed] p-2 rounded-md"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSignOut();
                  }}
                >
                  Log out
                </div>
              </div>

              <div className="self-stretch h-[66px] px-4 py-2 flex items-center gap-4">
                {displayPicture && (
                  <img
                  src={
                    displayPicture
                      ? `https://fbxloader.imgix.net/${displayPicture}`
                      : `https://as1.ftcdn.net/v2/jpg/03/16/12/52/1000_F_316125289_3GTL3Yd9JVQz3Nw50uAEEkOpX6GvK0LE.jpg`
                  }
                    alt="Display Picture"
                    className="w-10 h-10 rounded-full"
                  />
                )}
                <div className="flex flex-col justify-center">
                  <div className="text-zinc-800 text-base font-light font-['Selawk'] leading-normal">
                    {name}
                  </div>
                  <div className="text-zinc-800 text-sm font-light font-['Selawk'] leading-normal">
                    {email}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
