import React, {
  useEffect,
  useState,
  useRef,
  ChangeEvent,
  startTransition,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import localStorageService from "../../../../services/localStorageService";
import { CreateObjectData } from "../../../../types/apitypes";
import {
  CreateObjects,
  updateObject,
  GetObjectsDetails,
} from "../../../../services/experienceService";
import GLBViewer from "./GLBview";
import "./CreateObj.css";
import trashicon from "../../../../logos/ico_trash_3.svg";
import importicon from "../../../../logos/ico_import.svg";
import { Player, BigPlayButton } from "video-react";
import { ObjectData, Objectdetails } from "../../../../types/objectTypes";
import config from "../../../../config";
import { ClipLoader, HashLoader } from "react-spinners";

interface NewExperiencePopupProps {
  isVisible: boolean;
  onClose: () => void;
  projName?: string | undefined;
  objresponse: ObjectData | undefined;
}

const CHUNK_SIZE = 35 * 1024 * 1024; // 35 MB
const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB

interface UploadResponse {
  data: {
    uri: string;
  };
}

export const CreateObj: React.FC<NewExperiencePopupProps> = ({
  isVisible,
  onClose,
  projName,
  objresponse,
}) => {
  const [isExperienceNameFocused, setIsExperienceFocused] = useState(false);
  const [isDescNameFocused, setIsDescFocused] = useState(false);
  const [isExperienceNameError, setIsExperienceNameError] = useState(false);
  const [isDescNameError, setIsDescNameError] = useState(false);
  const [ExpName, setExpName] = useState<string | undefined>("");
  const [descName, setDescName] = useState("");
  const [tags, setTags] = useState("");
  const [constraints, setConstraints] = useState("");
  const [unityVersion, setUnityVersion] = useState("");
  const [glbFile, setGlbFile] = useState<File | null>(null);
  const [glbFileUri, setGlbFileUri] = useState<string | null>(null);
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);
  const [thumbnailPreview, setThumbnailPreview] = useState<string | null>(null);
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [uploadedVideo, setuploadedVideo] = useState<any>(null);
  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [videoSlug, setVideoSlug] = useState<string | null>(null);
  const [downloadedFiles, setDownloadedFiles] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isGLBLoading, setIsGLBLoading] = useState<boolean>(false); // Updated state for GLB loading
  const navigate = useNavigate();
  const selectedorgID = localStorageService.getItem("selectedOrgId");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const projID = useParams();
  const projectIDString = projID.ProjectID ?? "0";
  const projectIDNumber = parseInt(projectIDString, 10);
  const [glbfilepath, setGlbfilepath] = useState<string>("");
  const thumbnailInputRef = useRef<HTMLInputElement | null>(null);
  const VideoInputRef = useRef<HTMLInputElement | null>(null);
  const glbInputRef = useRef<HTMLInputElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isFullScreenRecording, setIsFullScreenRecording] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Element;
      if (target.classList.contains("modal-overlay")) {
        onClose();
        setIsExperienceNameError(false);
        setIsExperienceFocused(false);
        setIsDropdownOpen(false);
        setIsGLBLoading(false); // Reset GLB loading state on close
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, onClose]);

  useEffect(() => {
    if (isVisible) {
      setIsGLBLoading(true);
      if (!objresponse) {
        resetForm();
      }
    }
  }, [isVisible]);

  useEffect(() => {
    if (objresponse) {
      setExpName(objresponse.ObjectName);
      setDescName(objresponse.ObjectDescription);
    }
  }, [objresponse]);

  useEffect(() => {
    const fetchObjectDetails = async () => {
      try {
        if (objresponse) {
          const responseglb = await GetObjectsDetails(objresponse?.ObjectID);
          setGlbfilepath(config.storageUrl + responseglb.data.MediaFilePath);

          // Download and cache the GLB file
          const glbFileResponse = await fetch(
            config.storageUrl + responseglb.data.MediaFilePath
          );
          const glbFileBlob = await glbFileResponse.blob();
          const glbFile = new File([glbFileBlob], "cached_glb_file.glb", {
            type: "model/gltf-binary",
          });
          setGlbFile(glbFile);

          // Simulate delay for loading spinner
          setTimeout(() => {
            setIsGLBLoading(false);
          }, 2000);
        }
      } catch (error) {
        console.error("Error fetching object details:", error);
        setIsGLBLoading(false); // Stop the loader in case of error
      }
    };

    if (isVisible) {
      fetchObjectDetails();
    }
  }, [objresponse, isVisible]);

  useEffect(() => {
    if (videoSlug) {
      setIsProcessing(true);
      const interval = setInterval(async () => {
        try {
          const response = await axios.get(
            `http://localhost:5000/status/${videoSlug}`
          );
          const { status, files } = response.data;
          if (status === "Done") {
            setDownloadedFiles(files);
            if (files.length > 0) {
              const fileUrl = files[0];
              const fileName =
                fileUrl.split("/").pop() || "downloaded_file.glb";
              const fileResponse = await axios.get(fileUrl, {
                responseType: "blob",
              });
              const file = new File([fileResponse.data], fileName, {
                type: "model/gltf-binary",
              });
              setGlbFile(file);
            }
            setIsProcessing(false);
            clearInterval(interval);
          } else if (status === "unknown") {
            console.error("Unknown status received");
            setIsProcessing(false);
            clearInterval(interval);
          }
        } catch (error) {
          console.error("Error checking video status", error);
          setIsProcessing(false);
          clearInterval(interval);
        }
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [videoSlug]);

  if (!isVisible) return null;

  const resetForm = () => {
    setExpName("");
    setDescName("");
    setTags("");
    setConstraints("");
    setUnityVersion("");
    setGlbFile(null);
    setGlbFileUri(null);
    setThumbnailFile(null);
    setThumbnailPreview(null);
    setUploadedImage(null);
    setIsDescFocused(false);
    setIsExperienceNameError(false);
    setIsDescNameError(false);
    setuploadedVideo(null);
    setVideoSlug(null);
    setDownloadedFiles([]);

    if (thumbnailInputRef.current) {
      thumbnailInputRef.current.value = "";
    }
    if (glbInputRef.current) {
      glbInputRef.current.value = "";
    }
  };

  const uploadFile = async (file: File) => {
    setIsVideoUploading(true);
    setIsProcessing(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "http://localhost:5000/submit",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.slug) {
        setVideoSlug(response.data.slug);
        setuploadedVideo(URL.createObjectURL(file));
      }
    } catch (error) {
      console.error("Error uploading video", error);
    }

    setIsVideoUploading(false);
  };

  const uploadChunk = async (uri: string, chunk: Blob, startByte: number) => {
    try {
      await axios.put(uri, chunk, {
        headers: {
          "x-ms-blob-type": "BlockBlob",
          "Content-Range": `bytes ${startByte}-${startByte + chunk.size - 1}/*`,
        },
      });
    } catch (error) {
      console.error("Error uploading chunk", error);
      throw error;
    }
  };

  const handleGLBUpload = async (
    file: File
  ): Promise<UploadResponse | null> => {
    if (!file || file.size > MAX_FILE_SIZE) {
      alert("File size exceeds the maximum limit of 50 MB.");
      return null;
    }

    try {
      const response = await axios.get<UploadResponse>(
        `https://portaldev.altoura.com/upload/generateStorageSignaureForObject?fileName=${file.name}&objectId=`
      );
      const uri = response.data.data.uri;
      if (!uri) return null;

      const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
      for (let i = 0; i < totalChunks; i++) {
        const startByte = i * CHUNK_SIZE;
        const chunk = file.slice(startByte, startByte + CHUNK_SIZE);
        await uploadChunk(uri, chunk, startByte);
      }

      return { data: { uri: uri.split("?")[0] } };
    } catch (error) {
      console.error("Error uploading file", error);
      return null;
    }
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // Reset previous GLB file and cancel ongoing download
      if (glbFile) {
        URL.revokeObjectURL(glbFileUri || "");
        setGlbFile(null);
        setGlbFileUri(null);
        setIsGLBLoading(false); // Stop any previous loading state
      }

      startTransition(() => {
        setGlbFile(file);
      });
      const response = await handleGLBUpload(file);
      if (response && response.data.uri) {
        startTransition(() => {
          setGlbFileUri(response.data.uri);
        });
      }
    }
  };

  const handleClick = () => {
    const fileInput = document.getElementById(
      "fileInput"
    ) as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleClickImage = () => {
    const fileInput = document.getElementById(
      "thumbnailInput"
    ) as HTMLInputElement | null;
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      // Reset previous GLB file and cancel ongoing download
      if (glbFile) {
        URL.revokeObjectURL(glbFileUri || "");
        setGlbFile(null);
        setGlbFileUri(null);
        setIsGLBLoading(false); // Stop any previous loading state
      }

      startTransition(() => {
        setGlbFile(file);
      });
      const response = await handleGLBUpload(file);
      if (response && response.data.uri) {
        startTransition(() => {
          setGlbFileUri(response.data.uri);
        });
      }
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      // Reset previous GLB file and cancel ongoing download
      if (glbFile) {
        URL.revokeObjectURL(glbFileUri || "");
        setGlbFile(null);
        setGlbFileUri(null);
        setIsGLBLoading(false); // Stop any previous loading state
      }

      startTransition(() => {
        setGlbFile(file);
      });
      const response = await handleGLBUpload(file);
      if (response && response.data.uri) {
        startTransition(() => {
          setGlbFileUri(response.data.uri);
        });
      }
    }
  };
  const handleMediaUpload = async (file: File): Promise<{ data: { uri: string } } | null> => {
    if (!file) return null;

    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = async () => {
        const result = reader.result;
        if (typeof result === 'string') {
          try {
            const response = await axios.get(
              `https://portaldev.altoura.com/upload/generateStorageSignaureForObjectImage?fileName=${file.name}&mediaType=Image`,
              {
                params: {
                  fileName: file.name,
                  token: localStorage.getItem("token")
                }
              }
            );

            const uri = response.data.data.uri;

            if (!uri) {
              console.error('URI is undefined or null');
              reject(null);
              return;
            }

            await axios.put(uri, file, {
              headers: {
                'x-ms-blob-type': 'BlockBlob',
                'Content-Type': file.type
              }
            });

            resolve({ data: { uri: uri.split('?')[0] } });
          } catch (error) {
            console.error('Error uploading file', error);
            reject(null);
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleMediaVideoUpload = async (file: File): Promise<{ data: { uri: string } } | null> => {
    if (!file) return null;

    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onloadend = async () => {
        const result = reader.result;
        if (typeof result === 'string') {
          try {
            const response = await axios.get(
              `https://portaldev.altoura.com/upload/generateStorageSignaureForObjectImage?fileName=${file.name}&mediaType=Video`,
              {
                params: {
                  fileName: file.name,
                  token: localStorage.getItem("token")
                }
              }
            );

            const uri = response.data.data.uri;

            if (!uri) {
              console.error('URI is undefined or null');
              reject(null);
              return;
            }

            await axios.put(uri, file, {
              headers: {
                'x-ms-blob-type': 'BlockBlob',
                'Content-Type': file.type
              }
            });

            resolve({ data: { uri: uri.split('?')[0] } });
          } catch (error) {
            console.error('Error uploading file', error);
            reject(null);
          }
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleThumbnailUpload = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnailPreview(reader.result as string);
        setUploadedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
      setThumbnailFile(file);
    }
  };

  const extractPath = (url: string): string | null => {
    const regex = /https:\/\/[^\/]+(\/altouradevcontainer\/ProjectThumbnails\/[^?]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };
  const handleVideoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setIsVideoUploading(true);

      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await axios.post(
          "http://localhost:5000/submit",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.slug) {
          setVideoSlug(response.data.slug);
          setuploadedVideo(URL.createObjectURL(file));
        }
      } catch (error) {
        console.error("Error uploading video", error);
      }

      setIsVideoUploading(false);
    }
  };

  const handleDeleteImage = () => {
    setUploadedImage(null);
    if (objresponse?.ObjectThumbnail) {
      objresponse.ObjectThumbnail = '';
    }
    if (thumbnailInputRef.current) {
      thumbnailInputRef.current.value = "";
    }
  };

  const handleDeleteVideo = () => {
    setuploadedVideo(null);
    setVideoSlug(null);
    if (VideoInputRef.current) {
      VideoInputRef.current.value = "";
    }
  };
  const handleDeleteGlb = () => {
    setGlbFile(null);
    setGlbFileUri(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleBrowseClick = (e: any) => {
    e.stopPropagation();
    setIsConfirmModalVisible(true);
  };

  const handleModalSubmit = async () => {
    if (!ExpName) {
      setIsExperienceNameError(true);
      return;
    } else {
      setIsExperienceNameError(false);
    }

    if (!descName) {
      setIsDescNameError(true);
      return;
    } else {
      setIsDescNameError(false);
    }

    try {
      const formData = new FormData();
      formData.append("ObjectName", ExpName);
      formData.append("ObjectDescription", descName);
      formData.append("OrganizationID", selectedorgID);
      formData.append("TagSource", tags);
      formData.append("Constraints", constraints);
      formData.append("SurfaceType", "1");
      formData.append("GLBFileStatus", "true");
      formData.append("GLBMediaFilePath", glbFileUri ?? "");
      if (glbFile) {
        formData.append("GLBMediaFilePath", URL.createObjectURL(glbFile));
      }
      if(thumbnailFile){
        let thumbnailUrl =
        "/altouradevcontainer/ProjectThumbnails/Default_Thumbnail/ico_default_thumbnail.png";

        if (uploadedImage) {
          let uploadedUrl = await handleMediaUpload(thumbnailFile);
          if (uploadedUrl?.data) {
            const path = extractPath(uploadedUrl.data.uri);
            if(path){
              thumbnailUrl = path;
            }
          }
        }
        formData.append(
          "ObjectThumbnail",
          thumbnailUrl
        );
      }
      if(uploadedVideo){
          let thumbnailUrl =
          "/altouradevcontainer/ProjectThumbnails/Default_Thumbnail/ico_default_thumbnail.png";
  
          if (uploadedVideo) {
              if (uploadedVideo instanceof File) {
                let uploadedUrl = await handleMediaVideoUpload(uploadedVideo);
                if (uploadedUrl?.data) {
                  const path = extractPath(uploadedUrl.data.uri);
                  if (path) {
                    thumbnailUrl = path;
                  }
                }
              } else {
                console.error("uploadedVideo is not a File");
              }
            }

        }
      formData.append("MediaFilePropertyBag", JSON.stringify({}));

      const createobjresponse = await updateObject(formData);
      console.log("Create Object Response:", createobjresponse);
    } catch (error) {
      console.error("Error during API call:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const startFullScreenRecording = () => {
    setIsFullScreenRecording(true);
  };

  const handleConfirm = async (isRecording: boolean) => {
    setIsConfirmModalVisible(false);
    if (isRecording) {
      startFullScreenRecording();

      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        const mediaRecorder = new MediaRecorder(stream);
        const chunks: BlobPart[] = [];

        mediaRecorder.ondataavailable = (event) => {
          chunks.push(event.data);
        };

        mediaRecorder.onstop = async () => {
          const blob = new Blob(chunks, { type: "video/mp4" });
          uploadFile(
            new File([blob], "recorded_video.mp4", { type: "video/mp4" })
          );
        };

        mediaRecorder.start();
        setTimeout(() => {
          mediaRecorder.stop();
        }, 5000);
      } else {
        alert("Your device does not support video recording.");
      }
    } else {
      fileInputRef.current?.click();
    }
  };

  return (
    <div className="fixed inset-0 z-10000 bg-black bg-opacity-50 flex justify-center items-center modal-overlay backdrop-blur-sm z-10">
      <div
        className="max-w-6xl p-4 bg-white rounded-xl flex flex-col mt-[8vh]"
        onClick={(e) => e.stopPropagation()}
        style={{ maxHeight: "calc(100vh - 2rem)" }}
      >
        <div
          className="px-6 py-4 bg-white rounded-xl flex-col justify-start items-center gap-4 flex overflow-y-auto"
          style={{ maxHeight: "calc(100vh - 4rem)" }}
        >
          <div className="text-[#1f1f1f]  text-xl font-normal font-['Selawk'] leading-[32px]">
            New Object
          </div>
          <div className="self-stretch h-full max-h-[calc(100vh - 10rem)] flex-col justify-start items-start gap-6 flex overflow-y-auto">
            <div className="h-full flex-col justify-start items-start flex">
              <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                <div className="self-stretch flex-col justify-center items-center gap-1 flex">
                  <div className="self-stretch px-1 flex-col justify-start items-start gap-2 flex">
                    <div
                      className="self-stretch px-8 py-4 rounded-xl border-2 border-dashed border-[#d6dae1] flex-col justify-center items-center gap-2 flex"
                      onDrop={handleFileDrop}
                      onDragOver={(e) => e.preventDefault()}
                    >
                      <input
                        type="file"
                        accept=".glb,video/*"
                        className="hidden"
                        id="fileInput"
                        onChange={handleFileUpload}
                        ref={fileInputRef}
                      />
                      
                      {isProcessing ? (
                        <>
                          <div className="w-full max-h-[calc(18vh)] flex justify-center items-center">
                            <Player src={uploadedVideo} aspectRatio="2:1">
                              <BigPlayButton position="center" />
                            </Player>
                            <button
                              className="absolute top-0 right-0 p-1 bg-white rounded-full"
                              onClick={handleDeleteVideo}
                            >
                              <img
                                src={trashicon}
                                alt="Delete"
                                className="w-4 h-4"
                              />
                            </button>
                          </div>
                          <div className="text-center mb-[-1vh] mt-2 text-gray-700 text-sm font-normal">
                            Processing...
                          </div>
                        </>
                      ) : isGLBLoading ? (
                        <div className="flex justify-center items-center h-20">
                          <HashLoader
                            color="#4295e7"
                            loading={true}
                            size={30}
                          />
                        </div>
                      ) : glbFile ? (
                        <>
                        <div className="glb-viewer-container  relative rounded-md  ">
                          <GLBViewer
                            glbFile={glbFile}
                            handleDeleteGlb={handleDeleteGlb}
                          />
                          
                        </div>
                        </>
                      ) : uploadedVideo && isProcessing ? (
                        <div className="w-full flex max-h-[calc(1px)] justify-center items-center">
                          <Player src={uploadedVideo} aspectRatio="4:3">
                            <BigPlayButton position="center" />
                          </Player>
                          <button
                            className="absolute top-0 right-0 p-1 bg-white rounded-full"
                            onClick={handleDeleteVideo}
                          >
                            <img
                              src={trashicon}
                              alt="Delete"
                              className="w-4 h-4"
                            />
                          </button>
                        </div>
                      ) : (
                        <div
                          className="flex-col justify-start items-center gap-8 flex cursor-pointer"
                          onClick={() => fileInputRef.current?.click()}
                        >
                          <div className="w-17 h-17 bg-[#f1f3f5] rounded-full justify-center items-center inline-flex">
                            <div className="opacity-25 p-3">
                              <svg
                                width="44"
                                height="44"
                                viewBox="0 0 64 64"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="ico_import">
                                  <path
                                    id="Vector"
                                    d="M33.25 12.5H30.75V37.0875H33.25V12.5Z"
                                    fill="#697483"
                                  />
                                  <path
                                    id="Vector_2"
                                    d="M40.5321 28.6894L30.2294 38.9634L31.9947 40.7336L42.2974 30.4596L40.5321 28.6894Z"
                                    fill="#697483"
                                  />
                                  <path
                                    id="Vector_3"
                                    d="M23.5228 28.6971L21.7526 30.4624L32.0001 40.7386L33.7703 38.9733L23.5228 28.6971Z"
                                    fill="#697483"
                                  />
                                  <path
                                    id="Vector_4"
                                    d="M51.3 45.2499H12.7V22.9624H27.925V25.4624H15.2V42.7499H48.8V25.4624H35.9V22.9624H51.3V45.2499Z"
                                    fill="#697483"
                                  />
                                </g>
                              </svg>
                            </div>
                          </div>
                          <div className="flex-col justify-center items-center gap-2 flex">
                            <div className="flex-col justify-start items-center gap-2 flex">
                              <div className="flex-col justify-start items-center flex">
                                <div className="mt-[-2vh] text-center text-[#1f1f1f] text-sm font-normal font-['Selawk'] leading-normal">
                                  Drag and drop file here
                                </div>
                                <div className="self-stretch justify-center items-center gap-2 inline-flex">
                                  <div className="text-center text-[#697483] text-sm font-normal font-['Selawk'] leading-normal">
                                    or{" "}
                                  </div>
                                  <div
                                    className="w-[40px] h-4 justify-center items-center flex"
                                    onClick={(e) => {
                                      handleBrowseClick(e);
                                    }}
                                  >
                                    <div className="text-[#4295e7] text-sm font-normal font-['Selawk'] leading-normal">
                                      Browse
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-center text-[#697483] text-xs font-normal font-['Selawk'] leading-[15px]">
                              You can add a GLB object or upload a video
                              <br />
                              to generate a 3d object
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                  <div className="self-stretch flex-col justify-start items-start flex">
                    <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                      <div className="self-stretch pl-2 justify-start items-start gap-2 inline-flex">
                        <div className="text-[#323e4f] text-sm font-normal font-['Selawk'] leading-tight">
                          Object Name
                        </div>
                      </div>
                      <div className="self-stretch flex-col justify-start items-start flex">
                        <div
                          className={`self-stretch pl-2 pr-40 py-2 bg-white rounded-lg border ${
                            isExperienceNameFocused
                              ? "border-blue-500"
                              : isExperienceNameError
                              ? "border-red-300"
                              : "border-[#d6dae1]"
                          } justify-start items-center gap-2 inline-flex`}
                        >
                          <input
                            type="text"
                            placeholder="Add project name here"
                            className="outline-none w-full text-black-500 text-sm font-normal"
                            onFocus={() => {
                              setIsExperienceFocused(true);
                              setIsExperienceNameError(false);
                            }}
                            onBlur={() => setIsExperienceFocused(false)}
                            value={ExpName}
                            onChange={(e) => {
                              setExpName(e.target.value);
                              setIsExperienceNameError(false);
                            }}
                            onClick={() => setIsExperienceFocused(true)}
                          />
                        </div>
                        {isExperienceNameError && (
                          <div className="self-stretch pl-2 justify-start items-start gap-2 inline-flex">
                            <div className="text-[#bc2e2e] text-xs font-normal font-['Selawk'] leading-[15px]">
                              Invalid Project Name
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="w-[400px] flex-col justify-start items-start gap-1 flex">
                        <div className="self-stretch pl-2 justify-start items-start gap-2 inline-flex">
                          <div className="text-[#323e4f] mt-2 text-sm font-normal font-['Selawk'] leading-tight">
                            Description
                          </div>
                        </div>
                        <div className="self-stretch grow shrink basis-0 flex-col justify-start items-start flex">
                          <div
                            className={`self-stretch grow shrink basis-0 pl-2 pr-40 py-2 bg-white rounded-lg border ${
                              isDescNameFocused
                                ? "border-blue-500"
                                : isDescNameError
                                ? "border-red-300"
                                : "border-[#d6dae1]"
                            } justify-start items-start gap-2 inline-flex`}
                          >
                            <textarea
                              placeholder="Type description here"
                              className="outline-none w-full text-black-500 text-sm font-normal leading-normal resize-none"
                              style={{
                                textAlign: "left",
                                paddingLeft: "0.5rem",
                              }}
                              onFocus={() => {
                                setIsDescFocused(true);
                                setIsDescNameError(false);
                              }}
                              onBlur={() => setIsDescFocused(false)}
                              value={descName}
                              onChange={(e) => {
                                setDescName(e.target.value);
                                setIsDescNameError(false);
                              }}
                              onClick={() => setIsDescFocused(true)}
                            />
                          </div>
                          {isDescNameError && (
                            <div className="self-stretch pl-2 justify-start items-start gap-2 inline-flex">
                              <div className="text-[#bc2e2e] text-xs font-normal font-['Selawk'] leading-[15px]">
                                Invalid Description
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                      <div className="self-stretch px-2 justify-start items-start inline-flex">
                        <div className="text-[#323e4f] text-sm font-normal font-['Selawk'] mt-3 leading-tight">
                          Object Thumbnail
                        </div>
                      </div>
                      <div className="w-[400px] p-2.5 rounded-md border border-dashed border-[#d6dae1] justify-start items-center gap-2 inline-flex mb-6">
                        <div className="grow shrink basis-0 py-2 flex-col justify-center items-center gap-2 inline-flex relative">
                          <div className="p-4 flex flex-col justify-center items-center gap-2 inline-flex">
                            <input
                              type="file"
                              accept="image/*"
                              className="hidden"
                              id="thumbnailInput"
                              onChange={handleThumbnailUpload}
                              ref={thumbnailInputRef}
                            />
                            {uploadedImage || objresponse?.ObjectThumbnail ? (
                              <>
                                <img
                                  src={
                                    uploadedImage ||
                                    `${config.storageUrl}${objresponse?.ObjectThumbnail}`
                                  }
                                  alt="Uploaded"
                                  className="w-30 h-20 object-cover rounded-md mt-[-3vh] mb-[-3vh]"
                                />
                                <button
                                  className="absolute top-0 right-0 p-1 bg-white rounded "
                                  onClick={handleDeleteImage}
                                >
                                  <img
                                    src={trashicon}
                                    alt="Delete"
                                    className="w-4 h-4"
                                  />
                                </button>
                              </>
                            ) : (
                              <div
                                className="text-center text-[#697483] text-sm font-normal font-['Selawk'] leading-tight cursor-pointer"
                                onClick={() =>
                                  thumbnailInputRef.current?.click()
                                }
                              >
                                <div className="flex flex-col items-center">
                                  <div className="w-17 h-17 bg-[#f1f3f5] rounded-full flex justify-center items-center mb-2">
                                    <div className="p-3 opacity-25">
                                      <svg
                                        width="48"
                                        height="48"
                                        viewBox="0 0 48 48"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <g
                                          opacity="0.25"
                                          clipPath="url(#clip0_2506_23997)"
                                        >
                                          <path
                                            d="M11.353 33.7125L20.2124 25.3687L26.0343 31.5281"
                                            stroke="#697483"
                                            strokeWidth="2"
                                          />
                                          <path
                                            d="M22.4717 27.7594L28.6029 21.6281L36.6186 28.0875"
                                            stroke="#697483"
                                            strokeWidth="2"
                                          />
                                          <path
                                            d="M20.4184 20.4281C21.837 20.4281 22.9871 19.2781 22.9871 17.8594C22.9871 16.4407 21.837 15.2906 20.4184 15.2906C18.9997 15.2906 17.8496 16.4407 17.8496 17.8594C17.8496 19.2781 18.9997 20.4281 20.4184 20.4281Z"
                                            fill="#697483"
                                            stroke="#697483"
                                            strokeWidth="2"
                                          />
                                          <path
                                            d="M35.625 12.375V35.625H12.375V12.375H35.625ZM37.5 10.5H10.5V37.5H37.5V10.5Z"
                                            fill="#697483"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_2506_23997">
                                            <rect
                                              width="48"
                                              height="48"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                Click to add image
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch flex-col justify-start items-start gap-4 flex">
                    <div className="self-stretch flex-col justify-start items-start flex">
                      <div className="self-stretch flex-col justify-start items-start gap-1 flex">
                        <div className="self-stretch flex-col justify-start items-start flex">
                          <div className="flex items-center gap-4">
                            <input
                              type="file"
                              accept="video/*,.glb"
                              className="hidden"
                              id="videoInput"
                              onChange={handleFileUpload}
                              ref={fileInputRef}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch justify-start items-start gap-4 inline-flex mt-[-2vh]">
                <div
                  className="h-[35px] w-full px-6 py-2 rounded-lg border border-[#4295e7] justify-center items-center hover:bg-blue-100 active:bg-blue-200 gap-2 flex cursor-pointer"
                  onClick={onClose}
                >
                  <div className="text-center text-[#4295e7] text-sm font-normal font-['Selawk'] leading-normal">
                    Cancel
                  </div>
                </div>
                <div
                  className="h-[35px] w-full px-6 py-2 bg-[#4295e7] rounded-lg justify-center items-center gap-2 flex cursor-pointer hover:bg-[#1581eb] active:bg-[#4295e7]"
                  onClick={handleModalSubmit}
                >
                  <div className="text-center text-white text-sm font-normal font-['Selawk'] leading-normal">
                  Update
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isConfirmModalVisible && (
        <div
          className="fixed  inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 modal-overlay"
          onClick={() => setIsConfirmModalVisible(false)}
        >
          <div
            className="bg-white p-4 rounded-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <p className="mb-4">Do you want to record a new video?</p>
            <div className="flex justify-end">
              <button
                className="mr-2 px-4 py-2 bg-gray-200 rounded"
                onClick={() => handleConfirm(false)}
              >
                Select Existing
              </button>
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded"
                onClick={() => {
                  handleConfirm(true);
                }}
              >
                Record New
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreateObj;
