import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Header } from '../../../components/shared/HeaderFooter/Header-Loggedout';
import { Footer } from '../../../components/shared/HeaderFooter/Footer';
import logo from "../../../logos/Vectorlogo.svg";
import { loadFull } from "tsparticles";
import { SignInData } from '../../../types/authTypes';
import { signIn, isSSO, isDomain } from '../../../services/authService'; // Import isSSO function
import Particles, { initParticlesEngine } from "@tsparticles/react";
import {
  type Container,
  type ISourceOptions,
  MoveDirection,
  OutMode,
  Engine,
} from "@tsparticles/engine";
import { useLocation } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import eye from "../../../logos/ico_visiblepasswordico.svg";
import axios from 'axios';

export const ResetPassword = () => {
    const [credentials, setCredentials] = useState<SignInData>({ email: '', password: '' });
    const [errors, setErrors] = useState<{ email: string; password: string }>({ email: '', password: '' });
    const [apiErrors, setApiErrors] = useState<string>('');
    const [loginError, setLoginError] = useState<string>('');
    const [init, setInit] = useState(false);
    const [view, setView] = useState<'login' | 'sso'>('login');
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordText, setShowPasswordText] = useState(false);
    const [hovered, setHovered] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPasswordText(!showPasswordText);
    };
    const location = useLocation();
    const params = useParams();

    useEffect(() => {
        initParticlesEngine(async (engine: Engine) => {
            await loadFull(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = async (container?: Container): Promise<void> => { };

    const options: ISourceOptions = useMemo(
      () => ({
        key: "parallax",
        name: "Parallax",
        particles: {
            number: {
                value: 100,
                density: {
                    enable: true,
                },
            },
            color: {
                value: "#ffffff",
            },
            shape: {
                type: "circle",
            },
            opacity: {
                value: {
                    min: 0.1,
                    max: 0.5,
                },
                animation: {
                    enable: true,
                    speed: 3,
                    sync: false,
                },
            },
            size: {
                value: {
                    min: 1,
                    max: 10,
                },
                animation: {
                    enable: true,
                    speed: 20,
                    sync: false,
                },
            },
            links: {
                enable: true,
                distance: 150,
                color: "#ffffff",
                opacity: 0.4,
                width: 1,
            },
            move: {
                enable: true,
                speed: 2,
            },
        },
        interactivity: {
            events: {
                onHover: {
                    enable: true,
                    mode: "grab",
                    parallax: {
                        enable: true,
                        smooth: 10,
                        force: 60,
                    },
                },
                onClick: {
                    enable: true,
                    mode: "push",
                },
            },
            modes: {
                grab: {
                    distance: 400,
                    links: {
                        opacity: 1,
                    },
                },
                bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 0.8,
                },
                repulse: {
                    distance: 200,
                },
                push: {
                    quantity: 4,
                },
                remove: {
                    quantity: 2,
                },
            },
        },
        background: {
            color: "transparent",
        },
      }),
      []
    );

    const validate = () => {
        let isValid = true;
        const errors = { email: '', password: '' };

        if (!credentials.email) {
            errors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(credentials.email)) {
            errors.email = 'Email is invalid';
            isValid = false;
        }

        if (!credentials.password && showPassword) {
            errors.password = 'Password is required';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
    };

    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        let response;

        try {
            if (view === "login") {
                response = await isSSO(credentials.email);
                localStorage.setItem('email', credentials.email)

            } else if (view === "sso") {
                const body = {
                    domain: credentials.email
                }
                response = await isDomain(body);
                localStorage.setItem('email', credentials.email)
            }

            if (response && response.data && response.data.isSSOUser !== undefined) {
                if (response.data.isSSOUser === false) {
                    setShowPassword(true);
                    localStorage.setItem('email', credentials.email)
                } else {
                    setShowPassword(false);
                    if (view === "login") {
                        response.data.redirectUrl = `https://portaldev.altoura.com/user/login/saml?email=${credentials.email}&source=WEB_CLIENT`;
                    } else if (view === "sso") {
                        response.data.redirectUrl = `https://portaldev.altoura.com/user/login/saml/v1?domain=${credentials.email}&source=WEB_CLIENT`;
                        localStorage.setItem("newurl", response.data.redirectUrl);
                    }
                    window.location.href = response.data.redirectUrl;

                    return;
                }
            }
        } catch (error) {
            console.error('Error checking SSO:', error);
            setShowPassword(false);
            if (axios.isAxiosError(error) && error.response && error.response.status >= 400 && error.response.status < 500) {
                setApiErrors(error.response.data.message || 'Client error occurred. Please try again.');
            } else {
                setApiErrors('An error occurred. Please try again.');
            }
        }

        if (!validate()) return;

        if (showPassword) {
            try {
                await signIn(credentials);
                window.location.reload();
            } catch (error: any) {
                console.error('Login failed', error);
                if (axios.isAxiosError(error) && error.response && error.response.status >= 400 && error.response.status < 500) {
                    setLoginError('Invalid credentials. Please try again.');
                } else {
                    setApiErrors('An error occurred. Please try again.');
                }
            }
        }
    };

    const handleSSOLogin = () => {
        setView('sso');
    };
    const handlePasswordLogin = () => {
        setView('login');
    };

    return (
        <>
            {init && <Particles id="tsparticles" particlesLoaded={particlesLoaded} options={options} />}
            <div className="min-h-screen flex flex-col" style={{ zIndex: 10 }}>
                {view === 'login' ? (
                    <div className="flex flex-grow justify-center items-center bg-zinc-800 px-4">
                        <div className="w-full max-w-4xl flex flex-col lg:flex-row items-center gap-8">
                            <div className="flex flex-col justify-center items-center text-center lg:w-1/2">
                                <h1 className="text-white text-6xl lg:text-8xl font-semibold">Create</h1>
                                <div className="text-white text-4xl lg:text-7xl font-semibold">more</div>
                                <div className="text-center text-white text-2xl lg:text-4xl font-semibold">
                                    with<br />AI-powered <br />learning
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div className="w-[285px] h-[87px] pl-[10vh] justify-center items-center inline-flex">
                                    <div className="w-[285px] h-[87px] relative">
                                        <img src={logo} alt="Logo" />
                                    </div>
                                </div>
                                <div className="bg-white p-6 rounded-3xl shadow-md w-full max-w-sm items-center justify-center h-[50vh]" style={{ zIndex: 10 }}>
                                    <h2 className="text-stone-900 text-xl justify-center items-center lg:text-3xl font-semibold mb-4 ml-8">Forgot Password?</h2>
                                    <form onSubmit={handleSubmit} className="space-y-4">
                                        <div>
                                        <div className="self-stretch text-stone-900 text-base font-normal font-['Selawk'] leading-normal">
                  Enter your email id below and we’ll send you<br />a link to reset your password.
                </div>
                                            <label htmlFor="email" className="block text-sm font-medium text-stone-900 mt-5">Email Id</label>
                                            <input type="email" id="email" name="email" value={credentials.email} onChange={handleChange} required
                                                className="mt-1 p-2 w-full bg-stone-50 rounded-md border border-stone-900/20" />
                                            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                                        </div>
                                        {showPassword && (
                                            <div>
                                                <label htmlFor="password" className="block text-sm font-medium text-stone-900">Password</label>
                                                <div className="relative">
                                                    <input
                                                        type={showPasswordText ? "text" : "password"}
                                                        id="password"
                                                        name="password"
                                                        value={credentials.password}
                                                        onChange={handleChange}
                                                        required
                                                        className="mt-1 p-2 w-full bg-stone-50 rounded-md border border-stone-900/20 custom-focus-border"
                                                    />
                                                    <div className="absolute inset-y-0 right-0 flex items-center px-2">
                                                        <button
                                                            type="button"
                                                            onClick={togglePasswordVisibility}
                                                            className="focus:outline-none hover:bg-gray-200"
                                                        >
                                                            {showPassword ? <><img src={eye}></img></> : <FaEye />}
                                                        </button>
                                                    </div>
                                                </div>
                                                {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
                                            </div>
                                        )}
                                        {apiErrors && <p className="text-red-500 text-xs mt-1">{apiErrors}</p>}
                                        {loginError && <p className="text-red-500 text-xs mt-1">{loginError}</p>}

                                        
                                        <button
                                            type="button"
                                            style={{ backgroundColor: '#4295E7' }}
                                            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#1177DC')}
                                            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#4295E7')}
                                            onClick={handleSSOLogin}
                                            className="w-full bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] text-white py-3 rounded-md mt-2"
                                        >
                                            Reset password
                                        </button>
                                        <Link
                                            to="/"
                                            style={{ color: hovered ? '#1278dd' : '#4295E7' }} // Change color on hover
                                            className="text-sm block text-center mt-2 pl-[33vh]"
                                            onMouseEnter={() => setHovered(true)}
                                            onMouseLeave={() => setHovered(false)}
                                        >
                                            Log in
                                        </Link>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-grow justify-center items-center bg-zinc-800 px-4 py-8 lg:py-16">
                        <div className="w-full max-w-4xl flex flex-col lg:flex-row items-center gap-8">
                            <div className="flex flex-col justify-center items-center text-center lg:w-1/2">
                                <h1 className="text-white text-4xl md:text-5xl lg:text-6xl xl:text-8xl font-semibold">Create</h1>
                                <div className="text-white text-3xl md:text-4xl lg:text-5xl xl:text-7xl font-semibold">more</div>
                                <div className="text-center text-white text-xl md:text-2xl lg:text-3xl xl:text-4xl font-semibold">
                                    with<br />AI-powered<br />learning
                                </div>
                            </div>
                            <div className="flex flex-col items-center lg:w-1/2">
                                <div className="w-48 h-16 lg:w-[285px] lg:h-[87px] flex justify-center items-center mb-8">
                                    <div className="relative w-full h-full">
                                        <img src={logo} alt="Logo" className="w-full h-full object-contain" />
                                    </div>
                                </div>
                                <div className="bg-white z-10 w-full max-w-sm lg:max-w-lg p-6 rounded-3xl shadow-md">
                                    <h2 className="text-stone-900 text-xl md:text-2xl lg:text-3xl text-center font-semibold mb-4">Log in with SSO !</h2>
                                    <form onSubmit={handleSubmit} className="space-y-4">
                                        <div>
                                            <label htmlFor="email" className="block text-sm font-medium text-stone-900">Domain</label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={credentials.email}
                                                onChange={handleChange}
                                                required
                                                className="mt-1 p-2 w-full bg-stone-50 rounded-md border border-stone-900/20"
                                            />
                                            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                                        </div>
                                        {apiErrors && <p className="text-red-500 text-xs mt-1">{apiErrors}</p>}
                                        <button
                                            type="submit"
                                            className="w-full text-white py-3 rounded-md"
                                            style={{ backgroundColor: '#4295E7', marginTop: '2vh' }}
                                            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#1177DC')}
                                            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#4295E7')}
                                            onClick={handleSubmit}
                                        >
                                            Continue
                                        </button>
                                        <button
                                            type="button"
                                            style={{ backgroundColor: 'white', borderColor: '#4295E7', color: '#4295E7' }}
                                            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = 'white')}
                                            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '')}
                                            onClick={handlePasswordLogin}
                                            className="w-full bg-white border text-white  rounded-md mt-2 "
                                        >
                                            Log in
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <Footer />
            </div>
        </>
    );
};
