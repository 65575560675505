import React, { useEffect, useRef } from 'react';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Object3D, Group, Vector3 ,Vector3 as vectorthree} from 'three';
import { unityToThreePosition, unityToThreeEuler, toThreeVector3 } from '../../../../types/transformTypes';
import { useFindGameObject } from '../../../../utility/threeUtlility';
import { Objectdetails } from '../../../../types/objectTypes';
import config from '../../../../config';
import { TransformControls } from '@react-three/drei';


interface GLBModelViewerSpawnedObjectProps {
  SpaceObject: Objectdetails;
  key:number;
}

const GLBModelViewerSpawnedObject: React.FC<GLBModelViewerSpawnedObjectProps> = ({ key,SpaceObject }) => {
  const parentRef = useFindGameObject("Floor");
  console.log("SpaceObject.MediaFilePath" );
  console.log(SpaceObject.MediaFilePath );
  const gltf = useLoader(GLTFLoader,SpaceObject.MediaFilePath);
  console.log("Object");
  console.log(SpaceObject);
  const groupRef = useRef<Group>(null);
  const pivotRef = useRef<Group>(null);


  useEffect(() => {
    if (!parentRef || !gltf.scene || !groupRef.current || !pivotRef.current) {
      return; // Early return if any required element is missing
    }

    const localPosition:vectorthree = SpaceObject.position!;
    const localRotation = unityToThreeEuler(new Vector3(0,0,0));
    const localScale = toThreeVector3(new Vector3(1,1,1));

    // Set up the hierarchy
    groupRef.current.position.copy(SpaceObject.position!);
    groupRef.current.rotation.copy(localRotation);
    groupRef.current.scale.copy(localScale);

    pivotRef.current.name = 'pivot';
    groupRef.current.name=SpaceObject.ObjectName;
    
    groupRef.current.add(pivotRef.current);
    pivotRef.current.add(gltf.scene);

    // Determine the parent to attach to
    let attachTarget: Object3D;
    if (parentRef.name.toLowerCase() !== 'Floor') {
      const parentPivot = parentRef.children.find(child => child.name.toLowerCase() === 'pivot');
      attachTarget = parentPivot || parentRef;
    } else {
      attachTarget = parentRef;
    }

    // Add the top-level group to the determined parent
    attachTarget.add(groupRef.current);

    return () => {
      // Clean up
      if (attachTarget && groupRef.current) {
        attachTarget.remove(groupRef.current);
      }
    };
  }, [parentRef, gltf, SpaceObject]);

  return (
    <>
   {groupRef.current && <TransformControls object={groupRef.current} />}
     <group ref={groupRef}>
      <group ref={pivotRef} />
    </group>
    </>
  );
};

export default GLBModelViewerSpawnedObject;