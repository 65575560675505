import React, { useState, useRef, useCallback } from 'react';
import { useThree, useFrame } from '@react-three/fiber';
import { Object3D, Euler, Vector3, Box3 } from 'three';
import { ChevronRight, ChevronDown } from 'lucide-react';
import Draggable from 'react-draggable';
import { useSceneControl } from '../../Providers/SceneContextProvider';

export interface SceneNode {
  id: string;
  name: string;
  type: string;
  visible: boolean;
  localPosition: Vector3;
  localRotation: Euler;
  localScale: Vector3;
  children: SceneNode[];
}

const SceneExtractor: React.FC<{ onChange: (data: SceneNode) => void }> = ({ onChange }) => {
  const { scene } = useThree();
  const previousSceneState = useRef<string>("");

  useFrame(() => {
    const extractSceneGraph = (obj: Object3D): SceneNode => ({
      id: obj.uuid,
      name: obj.name || obj.type,
      type: obj.type,
      visible: obj.visible,
      localPosition: obj.position.clone(),
      localRotation: obj.rotation.clone(),
      localScale: obj.scale.clone(),
      children: obj.children.map(extractSceneGraph),
    });

    const sceneGraph = extractSceneGraph(scene);
    const currentSceneState = JSON.stringify(sceneGraph);

    if (currentSceneState !== previousSceneState.current) {
      onChange(sceneGraph);
      previousSceneState.current = currentSceneState;
    }
  });

  return null;
};

interface TreeNodeProps {
  node: SceneNode;
  onNodeClick: (node: SceneNode) => void;
  selectedNodeId: string | null;
}

const TreeNode: React.FC<TreeNodeProps> = ({ node, onNodeClick, selectedNodeId }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasChildren = node.children.length > 0;
  const isSelected = node.id === selectedNodeId;

  return (
    <div className="ml-4">
      <div className="flex items-center">
        {hasChildren ? (
          <button onClick={() => setIsExpanded(!isExpanded)} className="mr-1">
            {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
          </button>
        ) : (
          <span className="w-4 h-4 mr-1" />
        )}
        <span 
          className={`cursor-pointer ${node.visible ? 'text-black' : 'text-gray-400'} ${isSelected ? 'bg-yellow-200' : ''}`}
          onClick={() => onNodeClick(node)}
        >
          {node.name} ({node.type})
        </span>
      </div>
      {isExpanded && hasChildren && (
        <div className="ml-4">
          {node.children.map((child) => (
            <TreeNode key={child.id} node={child} onNodeClick={onNodeClick} selectedNodeId={selectedNodeId} />
          ))}
        </div>
      )}
    </div>
  );
};

const TransformDetails: React.FC<{ node: SceneNode | null }> = ({ node }) => {
  if (!node) return null;

  const formatVector = (v: Vector3) => 
    v ? `(${v.x.toFixed(2)}, ${v.y.toFixed(2)}, ${v.z.toFixed(2)})` : 'N/A';
  const formatEuler = (e: Euler) => 
    e ? `(${e.x.toFixed(2)}, ${e.y.toFixed(2)}, ${e.z.toFixed(2)})` : 'N/A';

  return (
    <div className="bg-white border-t border-gray-200 p-4">
      <h3 className="text-lg font-semibold mb-2">{node.name} Transform</h3>
      <div className="text-sm">
        <p>Local Position: {formatVector(node.localPosition)}</p>
        <p>Local Rotation: {formatEuler(node.localRotation)}</p>
        <p>Local Scale: {formatVector(node.localScale)}</p>
      </div>
    </div>
  );
};

const HierarchyView: React.FC<{ data: SceneNode | null }> = ({ data }) => {
  const [selectedNode, setSelectedNode] = useState<SceneNode | null>(null);
  const { isInteracting } = useSceneControl();

  const handleDragStart = useCallback(() => {
    isInteracting.current = true;
  }, [isInteracting]);

  const handleDragStop = useCallback(() => {
    isInteracting.current = false;
  }, [isInteracting]);

  const handleNodeClick = (node: SceneNode) => {
    setSelectedNode(node);
    // Here you can add any additional logic for when a node is selected,
    // such as highlighting the corresponding object in the 3D scene
  };

  if (!data) return null;

  return (
    <Draggable 
      handle=".drag-handle" 
      onStart={handleDragStart}
      onStop={handleDragStop}
    >
      <div className="bg-white border border-gray-200 rounded-lg shadow-lg w-80">
        <div className="drag-handle bg-gray-100 p-2 cursor-move rounded-t-lg">
          <h2 className="text-lg font-semibold">Scene Hierarchy</h2>
        </div>
        <div className="p-4">
          <div className="max-h-96 overflow-auto">
            <TreeNode 
              node={data} 
              onNodeClick={handleNodeClick} 
              selectedNodeId={selectedNode ? selectedNode.id : null} 
            />
          </div>
        </div>
        <TransformDetails node={selectedNode} />
      </div>
    </Draggable>
  );
};

export { SceneExtractor, HierarchyView };