import React, { useEffect, useState ,useCallback} from 'react';
import { Header } from '../../../components/shared/HeaderFooter/Header';
import { StepNavigation } from '../../../components/shared/StepNavigationExperience';
import { Footer } from '../../../components/shared/HeaderFooter/Footer';
import { useParams, useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti'
import Canvas from '../../CanvasComponent/CanvasComponent';
import { useWindowSize } from 'react-use';
import { getExperienceData } from '../../../services/experienceService';
import { AnnotationDetails, AudioTextTextData, Choice, DisplayTemplate, Experience, ExperienceResponseData, findExperienceByExperienceID, findMediaFileByDisplayTemplateID, findNextNode, findNodeByID, getAudioTextTextData, getLinkedNodeData, getMediaFilePath, getRolePlayChoiceTextData, getRolePlayStepByStepTextData, getStartNodeForExperience, RolePlaySectionObject, SOTypeID } from '../../../types/experienceTypes';
import { Stack } from '../../../types/stack';
import "video-react/dist/video-react.css";
import { Player } from 'video-react';
import { AudioPlayer } from '../../../components/shared/AudioPlayer/AudioPlayer';
import SlidePanel from '../../../components/shared/SidePanel/SidePanel';
import winningicon from "../../../logos/12699838_Smart guy getting award 1.svg";
import { Organization } from '../../../types/projectTypes';
import { getSpaceBuilderData, listProjectsAndOrgs } from '../../../services/projectService';
import debounce from 'lodash/debounce';
import { Scene } from '../../3DViewer/Scene/Scene';
import { pathFragment, ContentState, NodeRenderDetails, defalutNodeRenderDetails } from '../../../types/nodeDataTypes';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { AssetData, getAssetDataBySectionObjectID, SpaceJsonResponse, SpaceObject } from '../../../types/spaceTypes';
import { HashLoader } from 'react-spinners';
import altourablue from "../../../logos/altourablue.svg";
import {
  CreateExperienceSession,
  GetExperienceSessionActivePath,
  GetExperienceSessionPath,
  GetLastExperienceSessionStatus,
  SendResumeExperience,
  SendUpdateExperienceSessionMode,
  AddExperienceSessionEndTime,
  AddExperienceSessionPath,
  AddUsersUnderExperienceSession,
} from "../../../services/experienceSessionUtils";

const pathStack: Stack<pathFragment> = new Stack<pathFragment>();
const linkStack: Stack<pathFragment> = new Stack<pathFragment>();

export const ExperienceComponent3D = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [content, setContent] = useState<ContentState>({ text: '', images: [], quizzes: [] });


  const [audio, setAudio] = useState<boolean>(false);
  const [player] = useState(() => new AudioPlayer());
  const [audioUrl, setAudioUrl] = useState("https://altouraonyx.azureedge.net/altouradevcontainer/blank.mp3");
  const [loading, setLoading] = useState(true); // Add this line


  const [Experiences, setExperiences] = useState<Experience[]>([]);
  const [mainExperience, setMainExperience] = useState<Experience | undefined>(undefined);
  const [currentExperience, setCurrentExperience] = useState<Experience | undefined>(undefined);
  const [currentNode, setCurrentNode] = useState<RolePlaySectionObject | undefined>(undefined);


  const [spaceobjects, setspaceobjects] = useState<AssetData| undefined>(undefined);

  const [nodeRenderDetails, setNodeRenderDetails] = useState<NodeRenderDetails>(defalutNodeRenderDetails);

  const [userAnswers, setUserAnswers] = useState<{ [key: number]: string }>({});
  const [completed, setCompleted] = useState(false);
  // const [steps, setSteps] = useState<Step[]>([
  //   // Populate with initial step data
  // ]);
  const [isPanelVisible, setIsPanelVisible] = useState(true); // Initially visible

  const { projectSectionID,spaceID,experienceID,projectID } = useParams();
  const experienceIDNumber = Number(experienceID) || -1;// Assuming the query param is '?myParam=value'
  const projectIDNumber = Number(projectID) || -1;// Assuming the query param is '?myParam=value'
  const projectSectionIDNumber = Number(projectSectionID) || -1;// Assuming the query param is '?myParam=value'
  const spaceIDNumber = Number(spaceID) || -1;// Assuming the query param is '?myParam=value'


  const navigate = useNavigate()

  const { width, height } = useWindowSize();

  const [isRunFirstTime,setIsRunFirstTime] = useState(false);
  const [hasSavedProgress, setHasSavedProgress] = useState(false);

  const fetchExperiences = async () => {
    setLoading(true); // Start loading
    try {
      const experienceDataResponse = await getExperienceData(experienceIDNumber); // Ensure expData is defined and correctly imported
  
      if (experienceDataResponse.code === 200) {
        const responseData: ExperienceResponseData = experienceDataResponse.data;
  
        const mainTsid = responseData.responseData.MAIN_TSID;
        const mainExperienceData = findExperienceByExperienceID(
          responseData.responseData.Experiences,
          Number(mainTsid)
        );
  
        setExperiences(responseData.responseData.Experiences);
        setMainExperience(mainExperienceData);
        setCurrentExperience(mainExperienceData);
  
        // Check for saved progress
        const savedProgress = JSON.parse(
          localStorage.getItem(`experience_${experienceIDNumber}_progresss`) || "{}"
        );
  
        if (
          savedProgress.nodeID &&
          savedProgress.experienceID === experienceIDNumber
        ) {
          setHasSavedProgress(true);
          const savedNode = findNodeByID(
            mainExperienceData?.rolePlaySectionObjects || [],
            savedProgress.nodeID
          );
          if (savedNode) {
            setCurrentNode(savedNode);
          }
        } else if (mainExperienceData) {
          const currentNodeData = getStartNodeForExperience(mainExperienceData);
          setCurrentNode(currentNodeData);
        }
  
        setIsRunFirstTime(true);
      } else {
        console.error("Failed to fetch experiences:", experienceDataResponse.message);
      }
    } catch (error) {
      console.error("Error fetching experiences:", error);
    } finally {
      setLoading(false); // End loading
    }
  };
  

  const fetchSpaceObjectDetails = async () => {
   try{


      const spaceBuilderDataResponse:SpaceJsonResponse= await getSpaceBuilderData(projectIDNumber,projectSectionIDNumber);
      const spaceBuilderData=await getAssetDataBySectionObjectID(spaceBuilderDataResponse,spaceIDNumber);

      console.log("spaceBuilderData");
      console.log(spaceBuilderData);

      setspaceobjects(spaceBuilderData);

    } catch (error) {
      console.error('Error fetching experiences:', error);
    }
  };


  useEffect(() => {
    let isMounted = true; // Track if the component is still mounted
  
    if (isMounted) {
      fetchExperiences();
      fetchSpaceObjectDetails();
    }
  
    return () => {
      isMounted = false; // Cleanup function to prevent state updates after unmount
    };
  }, []);

  useEffect(() => {
    if (currentNode) {

      if (currentNode.SOTypeID == SOTypeID.StartNode && currentExperience?.rolePlaySectionObjects) {
        if(isRunFirstTime){
        let nodeDataToSet: NodeRenderDetails =  Object.assign({}, defalutNodeRenderDetails);
        nodeDataToSet.isStartNode=true;
        nodeDataToSet.title = "Welcome";
        nodeDataToSet.text ="Are you ready to start Training??"
        nodeDataToSet.onNext=onNextClick;
        setNodeRenderDetails(nodeDataToSet);
        setIsRunFirstTime(false);
        }
        else{
          const nextNode: RolePlaySectionObject | null = findNextNode(currentExperience?.rolePlaySectionObjects, currentNode);
          if (nextNode) {
            setCurrentNode(nextNode);
          }
        }

      }

      if (currentNode.SOTypeID == SOTypeID.StepNode) {

        let nodeDataToSet: NodeRenderDetails = Object.assign({}, defalutNodeRenderDetails);;

        let rolePlayStepByStepMediaFile = findMediaFileByDisplayTemplateID(currentNode.mediaFiles, DisplayTemplate.RolePlayStepByStep);
        if (rolePlayStepByStepMediaFile) {
          let rolePlayStepByStepTextData = getRolePlayStepByStepTextData(rolePlayStepByStepMediaFile);
          nodeDataToSet.title = rolePlayStepByStepTextData.Title;
        }

        let audioTextMediaFile = findMediaFileByDisplayTemplateID(currentNode.mediaFiles, DisplayTemplate.AudioText);
      
        if (audioTextMediaFile) {
          let audioTextTextData = getAudioTextTextData(audioTextMediaFile);
          nodeDataToSet.text = audioTextTextData.DisplayText;
   
          if(audioTextMediaFile.MediaFilePath&&audioTextMediaFile.MediaFilePath!="")
            nodeDataToSet.audio = "https://altouraonyx.azureedge.net" + audioTextMediaFile.MediaFilePath;

        }

        let rolePlayVideoMediaFile = findMediaFileByDisplayTemplateID(currentNode.mediaFiles, DisplayTemplate.RolePlayVideo);
        if (rolePlayVideoMediaFile) {
          let MediaFilePath = getMediaFilePath(rolePlayVideoMediaFile);
          if(MediaFilePath)
          nodeDataToSet.video ="https://altouraonyx.azureedge.net"+ MediaFilePath;
          else
          nodeDataToSet.video =MediaFilePath;
        }

        let rolePlayDisplayImageMediaFile = findMediaFileByDisplayTemplateID(currentNode.mediaFiles, DisplayTemplate.RolePlayDisplayImage);
        if (nodeDataToSet.video == "" && rolePlayDisplayImageMediaFile) {
          let MediaFilePath = getMediaFilePath(rolePlayDisplayImageMediaFile);
          if(MediaFilePath)
            nodeDataToSet.image ="https://altouraonyx.azureedge.net"+ MediaFilePath;
            else
            nodeDataToSet.image =MediaFilePath;
        }


        let rolePlay3DMediaFile = findMediaFileByDisplayTemplateID(currentNode.mediaFiles, DisplayTemplate.SBYS3DModel);
        if (rolePlay3DMediaFile) {
          let MediaFilePath = getMediaFilePath(rolePlay3DMediaFile);
          if(MediaFilePath)
          nodeDataToSet.threeDfile ="https://altouraonyx.azureedge.net"+ MediaFilePath;
          else
          nodeDataToSet.threeDfile =MediaFilePath;

        }
   





        nodeDataToSet.sectionObjectName=currentNode.SectionObjectName

        nodeDataToSet.onNext=onNextClick;
        nodeDataToSet.onBack=onBackClick;

        
        setNodeRenderDetails(nodeDataToSet);
      }
      else if (currentNode.SOTypeID == SOTypeID.LinkNode) {

        const nextExperienceMediafileData = findMediaFileByDisplayTemplateID(currentNode.mediaFiles, DisplayTemplate.LinkedNode)

        if (nextExperienceMediafileData && currentExperience) {

           linkStack.push({node:currentNode,ExperienceID:currentExperience?.ExperienceID})

          const nextExperienceData = getLinkedNodeData(nextExperienceMediafileData);
          const ExperienceData = findExperienceByExperienceID(Experiences, Number(nextExperienceData.TrainingSectionID))

          setCurrentExperience(ExperienceData);
          if (ExperienceData) {
            const currentNodeData = getStartNodeForExperience(ExperienceData);
            setCurrentNode(currentNodeData);
          }
        }
      }
      else if (currentNode.SOTypeID == SOTypeID.ChoiceNode) {
        let nodeDataToSet: NodeRenderDetails = Object.assign({}, defalutNodeRenderDetails);;

        let rolePlayChoiceTextDataMediaFile = findMediaFileByDisplayTemplateID(currentNode.mediaFiles, DisplayTemplate.RolePlayChoice);
        if (rolePlayChoiceTextDataMediaFile) {
          let rolePlayChoiceTextData = getRolePlayChoiceTextData(rolePlayChoiceTextDataMediaFile);
          nodeDataToSet.title = rolePlayChoiceTextData.HeaderText;
          nodeDataToSet.text = rolePlayChoiceTextData.QuestionText;
          nodeDataToSet.options = rolePlayChoiceTextDataMediaFile.Annotations;
        }

        nodeDataToSet.onAnswerSelect=onAnswerSelect;
        
        setNodeRenderDetails(nodeDataToSet);
      }





    }
    /*
    if (experience && currentStep < experience.Steps.length) {
      const { Steps } = experience;
      const step = Steps[currentStep];
      const hasImages = step.image && step.image !== "";
      if (step.title === "Assessment") {
        const questionPart = step.text.split('?')[0] + '?';
        const optionsPart = JSON.parse(step.options?.replace(/'/g, '"') || '[]');
        setContent({
          text: step.text,
          images: hasImages ? [{ imgSrc: step.image || "", altText: step.title }] : [],
          quizzes: [{ question: questionPart, options: optionsPart }],
        });
      } else {
        setContent({
          text: step.text,
          images: hasImages ? [{ imgSrc: step.image || "", altText: step.title }] : [],
          quizzes: [],
        });
      }
    } else if (experience && currentStep === experience.Steps.length) {
      //setCompleted(true); // User has completed the experience
    }
    */
  }, [currentNode])
  useEffect(() => {
    console.log('Current Node:', currentNode);
    console.log('Is Run First Time:', isRunFirstTime);
    console.log('Has Saved Progress:', hasSavedProgress);

    if (currentNode && isRunFirstTime) {
        if (currentNode.SOTypeID === SOTypeID.StartNode) {
            const nodeDataToSet = {
                ...defalutNodeRenderDetails,
                isStartNode: true,
                title: hasSavedProgress ? "Resume or Start" : "Welcome",
                text: hasSavedProgress ? 
                      "Would you like to resume your previous session or start training from the beginning?" : 
                      "Are you ready to start Training?",
                onNext: onNextClick
            };

            console.log('Setting Node Render Details:', nodeDataToSet);
            setNodeRenderDetails(nodeDataToSet);
            setIsRunFirstTime(false);
        }
    }
}, [currentNode, isRunFirstTime, hasSavedProgress]);

  useEffect(() => {
    if (nodeRenderDetails.audio) {
      setAudioUrl(nodeRenderDetails.audio);
      //  handlePlay();
    } else {
      setAudioUrl("https://altouraonyx.azureedge.net/altouradevcontainer/blank.mp3");
      //handlePlay();
    }
  }, [nodeRenderDetails]);

  const handlePlay = async (enable: boolean = false) => {
    return true;
    //   if(enable==true){
    //     setAudio(false);
    //   }
    //   else{

    //   if(nodeRenderDetails.audio&&nodeRenderDetails.audio!=""){
    //    await player.loadAndPlay(nodeRenderDetails.audio);
    //   setAudioUrl(nodeRenderDetails.audio);
    //   }
    // }
  };
  const saveProgress = () => {
    if (currentExperience && currentNode) {
      localStorage.setItem(
        `experience_${currentExperience.ExperienceID}_progresss`,
        JSON.stringify({
          nodeID: currentNode.SectionObjectID,
          experienceID: currentExperience.ExperienceID,
        })
      );
    }
  };
  

  const onNextClick = async () => {
    if (currentExperience && currentNode) {
      let nextNode: RolePlaySectionObject | null = null;
  
      const expid = await CreateExperienceSession();
      const path = await GetExperienceSessionActivePath(expid.data.ExperienceSessionID);
  
      const res = await GetExperienceSessionPath(
        expid.data.ExperienceSessionID,
        currentNode.SectionObjectID
      );
  
      const sendres = await SendUpdateExperienceSessionMode();
      const addExperienceSessionEndTime = await AddExperienceSessionEndTime();
      const addExperienceSessionPath = await AddExperienceSessionPath();
  
      if (currentNode.SOTypeID === SOTypeID.StepNode || isRunFirstTime) {
        setIsRunFirstTime(false);
  
        nextNode = findNextNode(
          currentExperience.rolePlaySectionObjects,
          currentNode
        );
        if (nextNode == null && !linkStack.isEmpty()) {
          const linkNode: pathFragment | undefined = linkStack.pop();
          if (linkNode) {
            let ExperienceOfLinkNode = findExperienceByExperienceID(
              Experiences,
              linkNode.ExperienceID
            );
            setCurrentExperience(ExperienceOfLinkNode);
            if (ExperienceOfLinkNode)
              nextNode = findNextNode(
                ExperienceOfLinkNode.rolePlaySectionObjects,
                linkNode.node
              );
          }
        }
      }
  
      if (nextNode) {
        pathStack.push({
          node: currentNode,
          ExperienceID: currentExperience.ExperienceID,
        });
        setCurrentNode(nextNode);
        saveProgress();  // Save progress
      }
  
      if (currentNode.DisplayOrder == 0 && linkStack.isEmpty()) {
        let nodeDataToSet: NodeRenderDetails = Object.assign(
          {},
          defalutNodeRenderDetails
        );
        nodeDataToSet.isCompleted = true;
        nodeDataToSet.isLastStep = true;
        nodeDataToSet.title = "Thank You";
        nodeDataToSet.text = "Completed";
        nodeDataToSet.onRetry = () => {
          window.location.reload();
        };
        nodeDataToSet.onGoToHome = () => {
          navigate("/");
        };
  
        setNodeRenderDetails(nodeDataToSet);
        setCompleted(true);
      }
    }
  };
  
  const onBackClick = () => {
    if (currentExperience && currentNode && pathStack.size() != 0) {
      const nextNodeData: pathFragment | undefined = pathStack.pop();
      if (nextNodeData != undefined) {
        const nextNode = nextNodeData.node;
        setCurrentExperience(
          findExperienceByExperienceID(Experiences, nextNodeData.ExperienceID)
        );
  
        if (nextNode) {
          setCurrentNode(nextNode);
          saveProgress();  // Save progress
        }
      }
    }
  };
  

  const debouncedOnBackClick = useCallback(debounce(
    () => onBackClick(),
    300,  // Delay in ms
  ), [onBackClick]);  // Dependency array
  
  const onAnswerSelect = (SectionObjectLinkID: number) => {
    if (currentExperience && currentNode) {
      const nextNode: RolePlaySectionObject | null = findNodeByID(currentExperience.rolePlaySectionObjects, SectionObjectLinkID);
      if (nextNode) {
        pathStack.push({ node: currentNode, ExperienceID: currentExperience.ExperienceID });
        setCurrentNode(nextNode);
      }

    }

  };


  const updateCurrentSlide = (newStepIndex: any) => {
    setCurrentStep(newStepIndex);
  };
  const onPrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
    }
  };
  const debouncedOnPrevious = useCallback(debounce(
    () => onPrevious(),
    300,  // Delay in ms
  ), [onPrevious]);  // Dependency array
  
  const onAnswerSubmit = (stepIndex: number) => {
    // Save the answer
    /* setUserAnswers(prev => ({
       ...prev,
       [stepIndex]: selectedOption[stepIndex],
     }));
   
     // Remove the selected option for the current step from the state
     setSelectedOption(prev => {
       const newState = { ...prev };
       delete newState[stepIndex]; // Remove the entry for the current step
       return newState;
     });
   
     // Logic to move to the next question
     if (stepIndex < (experience?.Steps.length || 0) - 1) {
       setCurrentStep(stepIndex + 1);
     } else {
       // Last question, handle accordingly
       setCompleted(true);
     }
     */
  };

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight + 100 });
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions(); // Initialize dimensions

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  // const onAnswerSelect = (option: string, stepIndex: number) => {
  //   setUserAnswers((prevAnswers) => ({
  //     ...prevAnswers,
  //     [stepIndex]: option,
  //   }));

  //   // Only move to the next step if it's not the last question
  //   if (stepIndex < (experience?.Steps.length || 0) - 1) {
  //     setTimeout(() => {
  //       onNext();
  //     }, 300); // Delay to allow for a smooth user experience
  //   } else {
  //     // If it's the last question, mark the experience as completed
  //     setTimeout(() => {
  //       setCompleted(true);
  //     }, 300);
  //   }
  // };
  const onRetry = () => {
    fetchExperiences(); // Mark the experience as not completed
  
  };
  const [selectedOption, setSelectedOption] = useState<{ [key: number]: string }>({});
  const [list, setList] = useState<Organization[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectResponse = await listProjectsAndOrgs(parseInt(localStorage.getItem('userID') || "-1"));
        if (projectResponse.orgsList.length === 0 || projectResponse.data.length === 0) {
          throw new Error("Empty data array received from API");
        }
        setList(projectResponse.orgsList);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
      }
    };

    fetchData();
  }, []);


  nodeRenderDetails.isFirstStep = currentExperience && currentNode
  ? currentExperience.rolePlaySectionObjects.findIndex(obj => obj.DisplayOrder === currentNode.DisplayOrder) === 1
  : false;

  nodeRenderDetails.isLastStep = currentNode ? currentNode.DisplayOrder === -1 : false;
  if (loading) {
    return (
      <>
      <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-r from-white  overflow-hidden">
           <div className="flex flex-col items-center">
             <HashLoader 
               color="#4295e7"
               loading={true}
             />
             <div className="text-2xl mt-4 text-gray-800 font-bold">
             <img src={altourablue}/>

             </div>
           </div>
         </div>
         </>
    );
  }
  
  return (
  <Scene nodeRenderDetails={nodeRenderDetails} spaceobjects={spaceobjects}></Scene>    
  );
}



