import config from "../config";
import { fetchSpaceObjectDetails } from "../services/projectService";
import { Vector3 } from "./transformTypes";

export interface SpaceObject {
    UniqueId: number;
    SectionObjectID: number;
    MediaFileID: number;
    MediaFileName: string;
    MediaFileDesc: string;
    ContentTypeID: number;
    MediaFilePath: string;
    MediaFileSize: string;
    ObjectID: number;
    SourceID: number;
    SourceType: string;
    CreatedOn: string;
    UpdatedOn: string;
    TextContent: string;
    TextDataID: number;
    ObjectName: string;
    ObjectThumbnail: string;
    ObjectContentTypeID: number;
    ObjectTextContent: string;
    ObjectTextDataID: number;
    MediaFilePropertyBag: string;
    tags: any[];
    SpaceObjectDetails:SpaceObjectDetails;
  }
  
  export interface AssetData {
    ProjectSectionID: number;
    ProjectID: number;
    SectionObjectID: number;
    SectionObjectName: string;
    SectionObjectSourceType: string;
    Thumbnail: string;
    DisplayOrder: number;
    SectionGroupID: number;
    MarkerID: number;
    PlayStartTime: number;
    ObjectCreatedOn: string;
    ObjectUpdatedOn: string;
    MediaFileID: number;
    ContentTypeID: number;
    MediaFilesSourceType: string;
    MediaFilePropertyBag: MediaFilePropertyBag;
    CreatedOn: string;
    UpdatedOn: string;
    MediaFilePath: string;
    PlatformID: number;
    UnityVersionID: number;
    BuildTypeID: number;
    MFABUpdatedOn: string | null;
    SOTypeID: number;
    annotations: any[];
    spaceObjects: SpaceObject[];
  }
  
  interface Data {
    ProjectSectionID: number;
    UpdatedOn: string;
    assetURL: string;
    assetdata: AssetData[];
  }

  interface MediaFilePropertyBag {
    OverrideDollhouseHeight: boolean;
    DollhouseHeight: number;
    DollhouseSize: number;
    OverrideTableTopRingLocalScale: number;
    OverrideDollhouseScaleFactor: boolean;
    DollhouseScaleFactor: number;
    OverrideDollhouseLocalScale: number;
    MeepleDisabled: boolean;
    DisableTableRing: boolean;
    IsRealscaleDisabled: boolean;
    IsCameraLookStraight: boolean;
    IsAnnotationUIBillboardDisabled: boolean;
    AnnotationUIScale: number;
    AnnotationColliderScale: number;
    ObjectTapAndHoldTime: number;
    Panoramas: any[];
    Scenarios: any[];
    InitialPanorama: {
      x: number;
      y: number;
    };
    IsDollhouseAnnotationUIWorldSpace: boolean;
    DollhouseAnnotations: any[];
    RealscaleAnnotations: any[];
    isPanoSpace: boolean;
    InitialPosition: string;
    TrainingMenuType: any[];
    IsDollHouseDisabled: boolean;
    IsDollHouseDisabledARMode: boolean;
    cameraNonARHeight: number;
    orbitModeFOV: number;
    StartInFreeLook: boolean;
    ExperienceData: {
      Name: string;
      TrainingUDID: string;
    };
    IsFloorCollab: boolean;
    IsRealWorldAligned: boolean;
    DefaultMeeplePositionYOffset: number;
    TrainingIdsToBeCached: any[];
    AutoStartExperienceID: string;
    OverrideAutoCacheExperience: boolean;
    CameraType: string;
    DefaultMeeplePositionOffset: Vector3;
    DefaultMeepleRotationOffset: Vector3;
    IsAcousticsEnable: boolean;
    acousticsACCURL: string;
    deadZoneDistanceForFingerTouch: number;
    MeeplePosition: Vector3;
    MeepleRotation: Vector3;
    AudioOff: boolean;
    AIBotObjectMap: any[];
    ObjectMetadataPowerAppUrl: string;
    HighlightTrainingObject: boolean;
  }
  
  
  export interface SpaceJsonResponse {
    code: number;
    data: Data;
    message: string;
  }

  interface TextData {
    source: string;
    textDataPath: string;
}

interface SpaceObjectDetails {
  ObjectName: string;
  IsActive: boolean;
  ObjectID: number;
  UniqueId: number;
  ParentUniqueId: number;
  LocationEnabled: {
    ReferenceGameObject: string;
    LocalPosition: Vector3;
    LocalRotation: Vector3;
    LocalScale: Vector3;
  };
  OriginalPivot: TransformData;
  OriginalCollider: Collider;
  CurrentPivot: TransformData;
  CurrentCollider: Collider;
  CapabilityDataList: any[];
  ChildObjectList: ChildObject[];
}

interface TransformData {
  position: Vector3;
  rotation: Vector3;
  scale: Vector3;
}

interface Collider {
  center: Vector3;
  size: Vector3;
}

interface ChildObject {
  name: string;
  isEnabled: boolean;
  transformData: TransformData;
  meshData: MeshData;
}

interface MeshData {
  isAvailable: boolean;
  isEnabled: boolean;
}

  
  
//   export function getAssetDataBySectionObjectID(json: SpaceJsonResponse, sectionObjectID: number): AssetData | undefined {
//     return json.data.assetdata.find(asset => asset.SectionObjectID === sectionObjectID);
//   }

  export function getTextContentFromSpaceObjects(assetData: AssetData): string[] {
    return assetData.spaceObjects.map(spaceObject => spaceObject.TextContent);
    }
  

    export async function getAssetDataBySectionObjectID(json: SpaceJsonResponse, sectionObjectID: number): Promise<AssetData | undefined> {
        const assetData = json.data.assetdata.find(asset => asset.SectionObjectID === sectionObjectID);
        if (!assetData) return undefined;
       // assetData.MediaFilePath=config.storageUrl+assetData.MediaFilePath;
   

        const spaceObjectPromises = assetData.spaceObjects.map(async (spaceObject) => {
        try {
          
        const textData:TextData=JSON.parse(spaceObject.TextContent);
        console.log(textData);
        const spaceObjectDetails = await fetchSpaceObjectDetails(config.storageUrl+ textData.textDataPath);
        console.log("spaceObject");
        spaceObject.MediaFilePath=config.storageUrl+ spaceObject.MediaFilePath;
        console.log(spaceObject);
        spaceObject["SpaceObjectDetails"]=spaceObjectDetails;
        return spaceObject;
        } catch (error) {
        console.error("Failed to fetch details for space object ${spaceObject.UniqueId}:", error);
        return spaceObject;
        }
        });
        
        assetData.spaceObjects = await Promise.all(spaceObjectPromises);
        return assetData;
        }
