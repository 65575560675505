import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { userDetailsFromToken } from '../../../services/authService';

const AuthCallback = () => {
  const [isVerified, setIsVerified] = useState(true);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const token = query.get('token'); 

  console.log("param value");
  console.log(token);


    if (token !== null) {

      (async () => {
        try {
        
          let userData = await userDetailsFromToken(token);
      
          if (userData && userData.code === 200) {
            localStorage.clear();
            localStorage.setItem("token", token);
            localStorage.setItem("userID", userData.data.userID.toString());
            window.location.href = "/dashboard";
            setIsVerified(true);
          } else {
            setIsVerified(false);
            // Handle the case where userData is not as expected or code is not 200
            console.error("Failed to fetch user data or invalid response code");
          }
        } catch (error) {
          // Handle any errors that occur during the fetch
          console.error("An error occurred while fetching user data:", error);
        }
      })();
    
   
      //window.location.href = "/dashboard";
    } else {
      console.warn('Token parameter is null');
    }


  return (
    <>
         <div className="flex items-center justify-center min-h-screen bg-gray-100">
      {isVerified ? (
        <div className="flex items-center space-x-2">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
        <div className="text-lg text-gray-600">Verifying...</div>
      </div>      ) : (
        <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4">
          <div className="flex-shrink-0">
            <svg className="h-12 w-12 text-red-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
          <div>
            <div className="text-xl font-medium text-black">User not verified</div>
            <p className="text-gray-500">Please contact support for assistance.</p>
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default AuthCallback;
