import React, { useState } from "react";
import { ProjectCardComponent } from "./ProjectCardComponent";
import { Project } from "../../../../types/projectTypes";
import { useNavigate } from 'react-router-dom';
import cactus from "../../../../logos/EmptyProjects.svg";
import localStorageService from "../../../../services/localStorageService";
import add from "../../../../logos/ico_add_white.svg";
import "./ProjectListView";

interface ProjectListViewProps {
  projects: Project[];
}

export const ProjectListView: React.FC<ProjectListViewProps> = ({ projects }) => {
  const navigate = useNavigate();

  const handleCardClick = (project: Project) => {
    localStorageService.setItem("ProjectName", project.ProjectName);
    navigate(`/Project/${project.ProjectId}/ExperienceList`);
  };

  if (projects.length === 0) {
    return (
      <div className="flex justify-center items-center h-[70vh] lg:h-[75vh] px-4 sm:px-6 lg:px-8 overflow-hidden">
        <div className="flex flex-col justify-center items-center gap-8 w-full max-w-screen-lg mx-auto">
          <div className="flex flex-col justify-start items-center gap-4">
            <div className="w-full max-w-[200vh] h-auto mt-4">
              <img src={cactus} className="w-full max-w-[411.97px] h-auto" alt="Not Found" />
            </div>
            <div className="flex flex-col justify-start items-center mt-2">
              <div className="text-center text-stone-900 text-xl font-normal font-['Selawk'] leading-[29px]">
                No projects found
              </div>
            </div>
          </div>
          <div className="w-[166px] h-10 pl-4 pr-8 py-2 bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] rounded-lg justify-center items-center gap-2 inline-flex cursor-pointer">
            <div className="w-6 h-6 justify-center items-center flex">
              <div className="w-6 h-6 relative">
                <img src={add} alt="Add" />
              </div>
            </div>
            <div className="text-center text-white text-base font-normal font-['Selawk'] leading-normal">New Project</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full overflow-hidden justify-center items-center">
      <div className="flex mt-10 flex-wrap gap-2 sm:gap-6 md:gap-8 w-full max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
        {projects.map((project, index) => (
          <div
            onClick={() => handleCardClick(project)}
            key={`${project.ProjectId}-${index}`}
            className="w-full sm:w-auto mt-[-2vh]"
          >
          <ProjectCardComponent project={project} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectListView;
