import apiClient from "./apiClient";

//CreateExperienceSession
export const CreateExperienceSession = async () => {
  try {
    const response = await apiClient.post(
      `/training/createExperienceSession/v1`
    );
    console.log("create exp session");
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//AddUsersUnderExperienceSession
export const AddUsersUnderExperienceSession = async () => {
  try {
    const response = await apiClient.post(
      `/training/addUsersUnderExperienceSession/v1`
    );
    console.log("AddUsersUnderExperienceSession");
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//AddExperienceSessionPath
export const AddExperienceSessionPath = async () => {
  try {
    const response = await apiClient.post(
      `training/addExperienceSessionPath/v1`
    );
    console.log("AddExperienceSessionPath");
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//AddExperienceSessionEndTime
export const AddExperienceSessionEndTime = async () => {
  try {
    const response = await apiClient.post(
      `/training/addExperienceSessionEndTime/v1`
    );
    console.log("AddExperienceSessionEndTime");
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//GetExperienceSessionPath
export const GetExperienceSessionPath = async (experienceSessionID:any , sectionObjectID:any) => {
  try {
    const response = await apiClient.get(
      `/training/experienceSession/getExperienceSessionPath?expSessionID=${experienceSessionID}&sectionObjectID=${sectionObjectID}`
    );
    console.log("GetExperienceSessionPath");
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//GetExperienceSessionActivePath
export const GetExperienceSessionActivePath = async (experienceSessionID:any) => {
  try {
    const response = await apiClient.get(
      `/training/experienceSession/getExperienceSessionActivePath?expSessionID=${experienceSessionID}`
    );
    console.log("GetExperienceSessionActivePath");
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//GetLastExperienceSessionStatus
export const GetLastExperienceSessionStatus = async (trainingSectionId:any) => {
  try {
    const response = await apiClient.get(
      `/training/experienceSession/getLastExperienceSessionStatus?trainingSectionID=${trainingSectionId}`
    );
    console.log("GetLastExperienceSessionStatus");
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//SendResumeExperience
export const SendResumeExperience = async () => {
  try {
    const response = await apiClient.post(
      `/training/experienceSession/resumeExperienceSession`
    );
    console.log("SendResumeExperience");
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};

//SendUpdateExperienceSessionMode
export const SendUpdateExperienceSessionMode = async () => {
  try {
    const response = await apiClient.post(
      `/training/experienceSession/updateExpSessionMode`
    );
    console.log("SendUpdateExperienceSessionMode");
    console.log(response);
    return response.data;
  } catch (error) {
    throw error;
  }
};
