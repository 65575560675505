import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import localStorageService from "../../../../../services/localStorageService";
import {
  CreateExperience,
  CreateProduct,
  CreateSpace,
  SectionIDresponse
} from "../../../../../services/experienceService";

interface NewExperiencePopupProps {
  isVisible: boolean;
  onClose: () => void;
  projName?: string;
  cards: Experience[];
}

interface ExpData {
  TrainingSectionID: number;
  SectionObjectID: number;
}

interface ProductDetails {
  ProductID: number | undefined;
  ProductUniqueID: string;
}

interface Experience {
  ExperienceId: number;
  ExperienceName: string;
  spaceID: string;
  productID: number;
  sectionObjectName: string;
  ProjectID: number;
  projectSectionID: number;
}

const CreateExpPopUp: React.FC<NewExperiencePopupProps> = ({
  isVisible,
  onClose,
  projName,
  cards
}) => {
  const [isExperienceNameFocused, setIsExperienceFocused] = useState(false);
  const [isDescNameFocused, setIsDescFocused] = useState(false);
  const [isExperienceNameError, setIsExperienceNameError] = useState(false);
  const [isDescNameError, setIsDescNameError] = useState(false);
  const [ExpName, setExpName] = useState("");
  const [descName, setDescName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [productDetails, setProductDetails] = useState<ProductDetails | null>(null);
  const orgID = localStorageService.getItem("selectedOrgId");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const projID = useParams();
  const projectIDString = projID.ProjectID ?? "0";
  const projectIDNumber = parseInt(projectIDString, 10);
  const now = new Date();
  const body = {
    ProjectSectionName: "projNamesection" + now.toISOString(),
    ProjectSectionDesc: "projNamesection" + now.toISOString()
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Element;
      if (target.classList.contains("modal-overlay")) {
        onClose();
        resetForm();
        setIsExperienceNameError(false);
        setIsExperienceFocused(false);
        setIsDropdownOpen(false);
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, onClose]);

  if (!isVisible) return null;

  const resetForm = () => {
    setExpName("");
    setDescName("");
    setIsExperienceNameError(false);
    setIsDescFocused(false);
    setIsDescNameError(false);
  };

  const handleModalSubmit = async () => {
    if (!ExpName.trim()) {
      setIsExperienceNameError(true);
    }
    if (!descName.trim()) {
      setIsDescNameError(true);
    }
    if (!ExpName.trim() || !descName.trim()) {
      return;
    }

    const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    let expData: ExpData | undefined;
    let ProjandSpaceData: any = {}; // Declare ProjandSpaceData outside of the try block

    setIsLoading(true);
    try {
      console.log('Starting product creation...');
      const productResponse = await CreateProduct(ExpName ?? '', descName ?? '', orgID);
      if (productResponse.status < 200 || productResponse.status >= 300) {
        throw new Error('Failed to create product due to network or server issue.');
      }
      const productData = productResponse.data;
      setProductDetails(productData);
      console.log('Product created successfully:', productData);

      // Add timeout before proceeding to the next API call
      await timeout(30);

      console.log('Starting experience creation...');
      const expResponse = await CreateExperience(productData.ProductID, orgID, ExpName, descName);
      if (expResponse.status < 200 || expResponse.status >= 300) {
        throw new Error('Failed to create experience due to network or server issue.');
      }
      expData = expResponse.data;
      console.log('Experience created successfully:', expData);

      if (!expData?.TrainingSectionID) {
        throw new Error("Exp details undefined");
      }

      // Add timeout before the next iteration
      await timeout(30);

      let projectSectionID;
      if (cards.length === 0 || !cards[0].projectSectionID) {
        if (cards.length > 0) {
          projectSectionID = cards[0].projectSectionID;
        }
        if (!projectSectionID) {
          console.log('Fetching new project section ID...');
          const body = {
            ProjectSectionName: "projNamesection" + now.toISOString(),
            ProjectSectionDesc: "projNamesection" + now.toISOString()
          };
          console.log(projName);
          if (ExpName && descName) {
            const response = await SectionIDresponse(projectIDNumber, body);
            if (response.status < 200 || response.status >= 300) {
              throw new Error('Failed to fetch project section ID due to network or server issue.');
            }
            projectSectionID = response.data?.ProjectSectionID;
            console.log('Project section ID fetched successfully:', projectSectionID);
            if (!projectSectionID) {
              throw new Error('Project section ID is undefined.');
            }
          }
        }
      }

      const CPAS = {
        SectionObjectName: ExpName,
        projectId: productData.projectID,
        ProjectSectionID: projectSectionID,
        isARREnabled: "false",
        Thumbnail: "/altouradevcontainer/ProjectThumbnails/2024-07-17_12%3A08%3A58%3A783AltouraLogo.png",
        MediaFilePropertyBag: `{\"AutoStartExperienceID\":\"${productData.ProductUniqueID}\"}`
      };

      console.log('Creating project and space...');
      const ProjandSpace = await CreateSpace(CPAS);
      if (ProjandSpace.status < 200 || ProjandSpace.status >= 300) {
        throw new Error('Failed to create project and space due to network or server issue.');
      }
      ProjandSpaceData = ProjandSpace.data;
      console.log('Project and space created successfully:', ProjandSpaceData);

      setSuccessMessage('Product and experience created successfully!');
      setErrorMessage('');
    } catch (error) {
      console.error('Error in creating product or experience:', error);
      setErrorMessage(error + ' An unexpected error occurred.');
      setSuccessMessage('');
    } finally {
      setIsLoading(false);
      if (ProjandSpaceData?.ProjectID) {
        navigate(`/experience/${ProjandSpaceData?.ProjectID}/ExperienceList`);
      }
    }
  };

  return (
    <div className="fixed inset-0 z-[1000] bg-black bg-opacity-50 flex justify-center items-center modal-overlay backdrop-blur-sm">
      <div
        className="w-full max-w-lg p-6 bg-white rounded-xl relative"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="text-stone-900 text-2xl font-normal font-['Selawk'] leading-[42.72px] text-center">
          Create Experience
        </div>
        <div className="mt-4 flex flex-col justify-start items-start gap-8">
          <div className="flex flex-col justify-start items-start gap-6 w-full">
            <div className="flex flex-col justify-start items-start w-full">
              <div className="w-full flex flex-col justify-start items-start gap-1">
                <div className="w-full pl-2 justify-start items-start gap-2.5 inline-flex">
                  <div className="text-gray-700 text-sm font-normal font-['Selawk'] leading-tight">
                    Experience Name
                  </div>
                </div>
                <div className="w-full flex flex-col justify-start items-start">
                  <div
                    className={`w-full pl-2 pr-2 py-2 bg-white rounded-lg border ${
                      isExperienceNameFocused
                        ? "border-blue-500"
                        : isExperienceNameError
                        ? "border-red-300"
                        : "border-gray-300"
                    } flex items-center`}
                  >
                    <input
                      type="text"
                      placeholder="Add Experience name here"
                      className="outline-none w-full text-black-500 text-base font-normal font-['Selawk'] leading-normal"
                      onFocus={() => {
                        setIsExperienceFocused(true);
                        setIsExperienceNameError(false);
                      }}
                      onBlur={() => setIsExperienceFocused(false)}
                      value={ExpName}
                      onChange={(e) => {
                        setExpName(e.target.value);
                        setIsExperienceNameError(false);
                      }}
                      onClick={() => {
                        setIsExperienceFocused(true);
                      }}
                    />
                  </div>
                  {isExperienceNameError && (
                    <div className="w-full pl-2 justify-start items-start gap-2.5 inline-flex">
                      <div className="text-red-700 text-xs font-normal font-['Selawk'] leading-[17.40px]">
                        Invalid Experience Name
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col mt-4 justify-start items-start gap-1 w-full">
                <div className="px-2 justify-start items-start inline-flex">
                  <div
                    className="text-gray-700 text-sm font-normal font-['Selawk'] leading-tight"
                    onClick={() => {
                      setIsDescFocused(true);
                    }}
                  >
                    Description
                  </div>
                </div>
                <div className="w-full flex flex-col justify-start items-start">
                  <div
                    className={`w-full h-[150px] pl-2 pr-2 py-2 bg-white rounded-lg border ${
                      isDescNameFocused
                        ? "border-blue-500"
                        : isDescNameError
                        ? "border-red-300"
                        : "border-gray-300"
                    } flex items-center`}
                  >
                    <input
                      type="text"
                      placeholder="Type description here"
                      className="outline-none w-full text-black-500 text-base font-normal font-['Selawk'] mt-[-10vh] leading-normal "
                      style={{ textAlign: "left", paddingLeft: "0.5rem" }} // Ensure text alignment and padding are set
                      onFocus={() => {
                        setIsDescFocused(true);
                        setIsDescNameError(false);
                      }}
                      onBlur={() => setIsDescFocused(false)}
                      value={descName}
                      onChange={(e) => {
                        setDescName(e.target.value);
                        setIsDescNameError(false);
                      }}
                      onClick={() => {
                        setIsDescFocused(true);
                      }}
                    />
                  </div>
                  {isDescNameError && (
                    <div className="w-full pl-2 justify-start items-start gap-2.5 inline-flex">
                      <div className="text-red-700 text-xs font-normal font-['Selawk'] leading-[17.40px]">
                        Invalid Description
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center items-center gap-4">
            <button
              onClick={onClose}
              className="h-[39px] w-1/2 px-8 py-2 rounded-lg border border-blue-500 flex justify-center items-center hover:bg-blue-100 active:bg-blue-200"
            >
              <div className="text-center text-blue-500 text-base font-normal font-['Selawk'] leading-normal">
                Cancel
              </div>
            </button>
            <button
              onClick={handleModalSubmit}
              className="h-[39px] w-1/2 px-8 py-2 bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] rounded-lg flex justify-center items-center "
            >
              <div className="text-center text-white text-base font-normal font-['Selawk'] leading-normal">
                Continue
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateExpPopUp;
