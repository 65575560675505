// Ground.tsx
import React from 'react';
import { usePlane } from '@react-three/cannon';


export const PlaneGround: React.FC = () => {
  usePlane(() => ({
    rotation: [-Math.PI / 2, 0, 0],
    position: [0, -0.1, 0], // Slightly below 0 to avoid z-fighting
  }));

  return (
    <group name='Floor'>
    <mesh rotation={[-Math.PI / 2, 0, 0]} receiveShadow position={[0, -0.1, 0]}>
      <planeGeometry args={[100, 100]} /> // Reduced size for better performance
      <meshStandardMaterial color="grey" />
    </mesh>
    </group>
  );
};