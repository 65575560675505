import React, { useState, useEffect, useRef } from "react";
import icokabob from "../../../logos/ico_kabob.svg";
import { xapiDownload } from "../../../services/xapi";
import { Navigate, useNavigate } from "react-router-dom";

interface Step {
  title: string;
  text: string;
  image?: string;
  PageNo: number;
  options?: string;
  CorrectAnswer?: string;
}

interface Experience {
  ExperienceId: number;
  ExperienceName: string;
  Steps?: Step[];
}

type CardProps = {
  data: Experience;
  experienceId: number;
  spaceID: string;
  projectSectionID: number;
  projectID: number;
  handleDropdownClick: (
    event: React.MouseEvent<Element, MouseEvent>,
    option: string,
    id: number,
    spaceID: string,
    projectSectionID: number,
    projectID: number
  ) => void;
};

export const CardComponent: React.FC<CardProps> = ({
  data,
  experienceId,
  spaceID,
  projectSectionID,
  projectID,
  handleDropdownClick,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const toggleDropdown = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsDropdownOpen((prev) => !prev);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const handlexapi = async (e: React.MouseEvent, spaceID: any, experienceId: any) => {
    e.stopPropagation();
    try {
      const response = await xapiDownload(spaceID, experienceId);
      console.log("Response:", response);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  
  const logoText = data.ExperienceName.substring(0, 2).toUpperCase();
  const svgLogo = encodeURIComponent(
    `<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
      <defs>
        <filter id="shadow" x="0" y="0" width="150%" height="150%">
          <feOffset result="offOut" in="SourceGraphic" dx="5" dy="5" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
      </defs>
      <rect width="100" height="100" fill="#FFFFFF" filter="url(#shadow)"></rect>
      <text x="50%" y="50%" alignment-baseline="middle" text-anchor="middle" fill="#0077be" font-size="40" font-family="Arial">${logoText}</text>
    </svg>`
  );

  return (
    <div className="w-full md:w-[1128px] max-w-full h-auto pb-5 p-4 bg-white rounded-xl border border-stone-900/10 flex flex-col justify-start items-start gap-1 hover:bg-[#f1f3f5] active:bg-[#e8eaed] cursor-pointer">
      <div className="w-full flex flex-col md:flex-row justify-between items-center gap-2.5">
        <div className="flex-grow flex flex-col justify-center items-start gap-1">
          <div className="w-full text-stone-900 text-base font-normal leading-normal">
            {data.ExperienceName}
          </div>
          <div className="w-full md:w-[90%] text-gray-400 text-sm font-normal leading-tight">
            Experience description, Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
            exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat.
          </div>
        </div>
        <div className="flex-shrink-0 w-full md:w-auto flex flex-col md:flex-row md:justify-end items-center gap-4 mt-2 md:mt-0">
          <div className="w-full flex justify-end gap-4 md:gap-11">
            <div className="mt-2 text-center text-gray-500 text-sm font-normal font-['Selawk'] leading-tight">
              Jun 05, 2024
            </div>
            <div className="mt-2 text-center text-gray-500 text-sm font-normal font-['Selawk'] leading-tight">
              Anurag Sarkar
            </div>
            <div className="relative" ref={dropdownRef}>
              <button
                className="h-8 w-8 flex items-center justify-center text-gray-500 rounded-full hover:text-gray-700 focus:outline-none cursor-pointer hover:bg-gray-300"
                onClick={toggleDropdown}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2457_142389)">
                    <path
                      d="M10.625 11.875C11.3154 11.875 11.875 11.3154 11.875 10.625C11.875 9.93464 11.3154 9.375 10.625 9.375C9.93464 9.375 9.375 9.93464 9.375 10.625C9.375 11.3154 9.93464 11.875 10.625 11.875Z"
                      fill="gray"
                    />
                    <path
                      d="M10.625 8.125C11.3154 8.125 11.875 7.56536 11.875 6.875C11.875 6.18464 11.3154 5.625 10.625 5.625C9.93464 5.625 9.375 6.18464 9.375 6.875C9.375 7.56536 9.93464 8.125 10.625 8.125Z"
                      fill="gray"
                    />
                    <path
                      d="M10.625 15.625C11.3154 15.625 11.875 15.0654 11.875 14.375C11.875 13.6846 11.3154 13.125 10.625 13.125C9.93464 13.125 9.375 13.6846 9.375 14.375C9.375 15.0654 9.93464 15.625 10.625 15.625Z"
                      fill="gray"
                    />
                  </g>
                </svg>
              </button>
              {isDropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                  <ul className="py-1">
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={(event) =>
                        handleDropdownClick(
                          event,
                          "3d view",
                          experienceId,
                          spaceID,
                          projectSectionID,
                          projectID
                        )
                      }
                    >
                      3d View
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={(event) =>
                        handleDropdownClick(
                          event,
                          "Edit Space",
                          experienceId,
                          spaceID,
                          projectSectionID,
                          projectID
                        )
                      }
                    >
                      Edit Space
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={(event) =>
                        handleDropdownClick(
                          event,
                          "edit",
                          experienceId,
                          spaceID,
                          projectSectionID,
                          projectID
                        )
                      }
                    >
                      Edit
                    </li>
                    <li
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.location.href = `https://portaldev.altoura.com/xapi/${spaceID}/${experienceId}/generateZipFile`;
                      }}
                    >
                      XAPI
                    </li>
                    <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
                      Delete
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
