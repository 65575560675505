import { useThree } from '@react-three/fiber';
import { Object3D } from 'three';

// Custom hook to find a game object by name
export function useFindGameObject(name: string): Object3D | null {
  const { scene } = useThree();
  let foundObject: Object3D | null = null;

  scene.traverse((object) => {
    if (object.name === name) {
      foundObject = object;
    }
  });

  return foundObject;
}