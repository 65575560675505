import axios from "axios";

const CHUNK_SIZE = 35 * 1024 * 1024; // 35 MB
const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB

export async function uploadFileToAzure(
  file: File,
  sasUrl: string,
  progressCallback: (progress: number) => void,
  statusCallback: (status: string) => void,
  chunkSize: number = CHUNK_SIZE
): Promise<void> {
  if (!file) {
    statusCallback("No file selected.");
    return;
  }

  try {
    statusCallback("Uploading...");
    const totalChunks = Math.ceil(file.size / chunkSize);
    let uploadedChunks = 0;

    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(file.size, start + chunkSize);
      const chunk = file.slice(start, end);

      const headers = {
        "x-ms-blob-type": "BlockBlob",
        "Content-Type": "application/octet-stream",
        "x-ms-blob-content-type": file.type,
      };

      await axios.put(sasUrl, chunk, { headers });

      uploadedChunks++;
      progressCallback((uploadedChunks / totalChunks) * 100);
    }

    statusCallback("File uploaded successfully!");
  } catch (error) {
    console.error("Error uploading file:", error);
    statusCallback("An error occurred during upload. Please try again.");
  }
}

export async function handleGLBUpload(
  file: File
): Promise<{ uri: string } | null> {
  if (!file || file.size > MAX_FILE_SIZE) {
    alert("File size exceeds the maximum limit of 50 MB.");
    return null;
  }

  try {
    const response = await axios.get(
      `https://portaldev.altoura.com/upload/generateStorageSignaureForObject?fileName=${file.name}&objectid=`
    );
    const { uri } = response.data.data;
    if (!uri) return null;

    await uploadFileToAzure(
      file,
      uri,
      (progress) => {
        console.log(`Upload progress: ${progress}%`);
      },
      (status) => {
        console.log(status);
      }
    );

    return { uri: uri.split("?")[0] };
  } catch (error) {
    console.error("Error uploading file", error);
    return null;
  }
}

export async function handleMediaUpload(
  file: File,
  mediaType: "Image" | "Video"
): Promise<{ uri: string } | null> {
  if (!file) return null;

  try {
    const response = await axios.get(
      `https://portaldev.altoura.com/upload/generateStorageSignaureForObjectImage?fileName=${file.name}&mediaType=${mediaType}`,
      { params: { fileName: file.name, token: localStorage.getItem("token") } }
    );

    const uri = response.data.data.uri;
    if (!uri) {
      console.error("URI is undefined or null");
      return null;
    }

    await axios.put(uri, file, {
      headers: {
        "x-ms-blob-type": "BlockBlob",
        "Content-Type": file.type,
      },
    });

    return { uri: uri.split("?")[0] };
  } catch (error) {
    console.error("Error uploading file", error);
    return null;
  }
}
