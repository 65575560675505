import apiClient from "./apiClient";
// In src/services/authService.ts
import {
  SignUpData,
  SignInData,
  UserDataApiResponse,
} from "../types/authTypes";

// Function to handle sign-up
export const signUp = async (userData: SignUpData) => {
  try {
    const response = await apiClient.post(`/user/signup`, userData);
    // You can handle the response further, e.g., storing the user token
    return response.data;
  } catch (error) {
    console.error("Error during sign-up:", error);
    throw error;
  }
};

// Function to handle sign-in
// Function to handle sign-in
export const signIn = async (credentials: SignInData) => {
  try {
    const response = await apiClient.post(
      `/user/loginWithOtpOrPassword`,
      credentials
    );
    console.log(response.data);

    // Using optional chaining to safely access nested properties
    console.log(response.data.userData?.FirstName);

    // Check if the response code is not 200 and throw an error
    if (response.data.code !== 200) throw new Error("Authentication failed");

    // Assuming response.data.data contains token and userID, otherwise use optional chaining here as well
    localStorage.setItem("token", response.data.data.token);
    localStorage.setItem("userID", response.data.data.userID);

    // Check if userData exists and has FirstName before trying to set it
    if (response.data.userData?.FirstName) {
      localStorage.setItem("FirstName", response.data.userData.FirstName);
    }

    return response.data;
  } catch (error) {
    console.error("Error during sign-in:", error);
    throw error;
  }
};

// Function to handle sign-in
export const signOut = async () => {
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("userID");
    return;
  } catch (error) {
    console.error("Error during sign-out:", error);
    throw error;
  }
};

// Function to handle sign-in
export const isAuthenticated = async () => {
  try {
    const response = await apiClient.get(`/user/isAuthenticated`);
    console.log(response);
    localStorage.setItem("FirstName", response.data.userData.FirstName);
    // The API should set a cookie with the token; withCredentials ensures cookies are included in the request
    return response.data.isAuthenticated;
  } catch (error) {
    return false;
  }
};

export const isTokenCookieSet = async () => {
  // Split document.cookie on semicolons and trim leading/trailing whitespaces
  const cookies = document.cookie.split(";").map((cookie) => cookie.trim());

  // Look for a cookie that starts with "token="
  const tokenCookie = cookies.find((cookie) => cookie.startsWith("token="));

  // If the cookie is found, return true, else false
  return tokenCookie !== undefined;
};

export const isSSO = async (email: string) => {
  try {
    const response = apiClient.get(`/user/getAuthTypeForUser?email=${email}`);
    console.log("isSSOlogin response");
    console.log((await response).data);
    return (await response).data;
  } catch (error) {
    console.log(error);
  }
};
export const isDomain = async (body: any) => {
  try {
    const response = apiClient.post(`user/getAuthtypeForDomain`, body);
    console.log((await response).data);
    return (await response).data;
  } catch (error) {
    console.log(error);
  }
};

// Function to handle sign-in
export const userDetailsFromToken = async (token: string) => {
  try {
    const response: UserDataApiResponse = await (
      await apiClient.get(`/user/getUserDetailsForToken?token=${token}`)
    ).data;
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return null;
  }
};
