import { Euler, MathUtils, Vector3 as vectorthree } from "three";

export interface Vector2 {
    x: number;
    y: number;
  }

export interface Vector3 {
    x: number;
    y: number;
    z: number;
  }


  export function toThreeVector3(vector: Vector3): vectorthree{
    return new vectorthree(vector.x, vector.y, vector.z);
  }

  export function toThreeEuler(vector: Vector3): Euler{
    return new Euler(vector.x, vector.y, vector.z);
  }
  
  export function unityToThreePosition(vector: Vector3): vectorthree {
    return new vectorthree(vector.x, vector.y, -vector.z);
  }

  export function unityToThreeEuler(vector: Vector3): Euler {
    // Convert Unity Euler angles to Three.js Euler angles
    // Note: Unity uses degrees, Three.js uses degrees too, but we need to invert the Z-axis rotation.
    console.log();
    return new Euler(
      MathUtils.degToRad(vector.x),
      Math.PI+MathUtils.degToRad(-vector.y),
      MathUtils.degToRad(-vector.z),
      'YXZ' // Assuming Unity's default rotation order, which is 'YXZ'
    );
  }