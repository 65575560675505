import React, { useEffect } from 'react';
import './CardComponent.css';

interface CardskeletonLoaderProp {
  count: number;
}

const CardskeletonLoader: React.FC<CardskeletonLoaderProp> = ({ count }) => {
  useEffect(() => {
    // Scroll to the top of the container when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
<>
      {Array.from({ length: count }).map((_, index) => (
           <div className="max-w-full md:w-[1128px] h-[100px] mt-2 pb-5 p-4 bg-white animate-shimmer rounded-xl border border-stone-900/10 flex flex-col justify-start items-start gap-4 cursor-pointer">
           <div className="w-full flex flex-col md:flex-row justify-start items-center gap-2.5">
             <div className="flex-grow flex flex-col justify-center items-start gap-1">
               {/* <div className="w-full h-6 bg-gray-200 rounded animate-shimmer"></div>
               <div className="w-full h-4 bg-gray-200 rounded animate-shimmer mt-2"></div>
               <div className="w-full h-4 bg-gray-200 rounded animate-shimmer mt-2"></div>
               <div className="w-full h-4 bg-gray-200 rounded animate-shimmer mt-2"></div> */}
             </div>
             <div className="w-[466px] h-5 justify-start items-center gap-4 inline-flex mt-2 md:mt-0 relative">
               {/* <div className="w-[133px] h-5 bg-gray-200 rounded animate-shimmer"></div>
               <div className="w-[117px] h-5 bg-gray-200 rounded animate-shimmer"></div>
               <div className="w-8 h-8 bg-gray-200 rounded-full animate-shimmer"></div> */}
             </div>
           </div>
         </div>
      ))}
      </>
  );
};

export default CardskeletonLoader;
