// Ground.tsx
import React, { useEffect, useRef } from 'react';
import { usePlane } from '@react-three/cannon';
import { Group, BufferGeometry, LineBasicMaterial, LineSegments, Float32BufferAttribute, DoubleSide } from 'three';
import { useThree } from '@react-three/fiber';
import { Grid } from '@react-three/drei';

export const GridGround: React.FC = () => {
  const groupRef = useRef<Group>(null);
  const { scene } = useThree();

  useEffect(() => {
    if (groupRef.current) {
      scene.add(groupRef.current);
    }
    return () => {
      if (groupRef.current) {
        scene.remove(groupRef.current);
      }
    };
  }, [scene]);

  usePlane(() => ({
    rotation: [0, 0, 0],
    position: [0, 0, 0],
  }));

  

  return (
  
    <Grid  
     infiniteGrid={true} 
     cellSize={.1} 
     cellColor={"white"}
     sectionSize={1}
     sectionColor={"white"} 
    fadeDistance= { 20 } 
    fadeStrength= { 1}
    followCamera={true}
    name={"Floor"}
    />
  );
};