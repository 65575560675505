import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

interface StartTrainingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onStart: () => void;
  onResume: () => void;
  hasSavedProgress: boolean; // New prop
}

const StartTrainingModal: React.FC<StartTrainingModalProps> = ({
  isOpen,
  onClose,
  onStart,
  onResume,
  hasSavedProgress,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  if (!isOpen) return null;

  return (
<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm transition-opacity">
  <div
    ref={modalRef}
    className="relative bg-white p-8 rounded-md shadow-xl text-center max-w-md w-full transform transition-transform duration-300 ease-out scale-95"
  >
    {/* Close Button */}
    <button
      onClick={() => navigate(-1)}
      className="absolute -top-4 -right-4 px-3 bg-white bg-opacity-70 rounded-full text-gray-500 hover:text-gray-700 focus:outline-none transition-colors duration-200 p-1.5 shadow-md"
    >
      &times;
    </button>

    <h2 className="text-3xl font-bold text-gray-800 mb-4">
      {hasSavedProgress ? "Resume or Start" : "Welcome"}
    </h2>
    <p className="text-gray-600 mb-8 text-lg">
      {hasSavedProgress
        ? "Would you like to resume your previous session or start training from the beginning?"
        : "Are you ready to start Training?"}
    </p>
    <div className="flex flex-col space-y-4">
      {hasSavedProgress && (
        <button
          onClick={onResume}
          className="bg-[#4295e7] text-white px-6 py-3 rounded-md font-semibold text-lg shadow-md hover:bg-[#1581eb] focus:outline-none focus:ring-2 focus:ring-blue-400 transition-colors duration-200"
        >
          Resume Training
        </button>
      )}
      <button
        onClick={onStart}
        className="bg-white text-[#4295e7] px-6 py-3 rounded-md font-semibold text-lg shadow-md border border-[#4295e7] hover:bg-[#f2f9fe] focus:outline-none focus:ring-2 focus:ring-blue-400 transition-colors duration-200"
      >
        {hasSavedProgress ? "Start Over" : "Start Training"}
      </button>
    </div>
  </div>
</div>

  );
};

export default StartTrainingModal;
