export class Stack<T> {
    private storage: T[] = [];

    push(item: T): void {
        const clonedItem = JSON.parse(JSON.stringify(item));  // Deep clone
        this.storage.push(clonedItem);
    }

    pop(): T | undefined {
        return this.storage.pop();
    }

    peek(): T | undefined {
        return this.storage[this.storage.length - 1];
    }

    isEmpty(): boolean {
        return this.storage.length === 0;
    }

    size(): number {
        return this.storage.length;
    }
}
