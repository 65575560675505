const localStorageService = {
    setItem(key: string, value: any): void {
      let valueToStore: string;
      if (typeof value === 'object') {
        valueToStore = JSON.stringify(value);
      } else {
        valueToStore = value;
      }
      localStorage.setItem(key, valueToStore);
    },
  
    getItem(key: string): any {
      const value = localStorage.getItem(key);
      if (value) {
        try {
          return JSON.parse(value);
        } catch (e) {
          // If error, then it was a string, so return it directly
          return value;
        }
      }
      return null;
    },
  
    deleteItem(key: string): void {
      localStorage.removeItem(key);
    },
  
    keyExists(key: string): boolean {
      return localStorage.getItem(key) !== null;
    },
  };
  
  export default localStorageService;
  