import React, { useEffect, useState, useCallback } from 'react';
import { Header } from '../../components/shared/HeaderFooter/Header';
import { StepNavigation } from '../../components/shared/StepNavigationImportExperience';
import { Footer } from '../../components/shared/HeaderFooter/Footer';
import { useParams, useNavigate } from 'react-router-dom';
import Confetti from 'react-confetti'
import { useWindowSize } from 'react-use';
import { AnnotationDetails, Experience, findExperienceByExperienceID, findNodeByID,RolePlaySectionObject } from '../../types/experienceTypes';
import { Stack } from '../../types/stack';
import "video-react/dist/video-react.css";
import SlidePanel from '../../components/shared/SidePanel/SidePanel';
import winningicon from "../../logos/12699838_Smart guy getting award 1.svg";
import {  getStepsfromProcedure, } from '../../services/experienceService';
import { Organization } from '../../types/projectTypes';
import { listProjectsAndOrgs } from '../../services/projectService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import debounce from 'lodash/debounce';

import "./SidePanel.css"

interface NodeRenderDetails {
  title: string;
  text: string;
  image?: string;
  audio?: string;
  video?: string;
  PageNo: number;
  options?: AnnotationDetails[];
}

// interface Step {
//   title: string;
//   text: string;
//   image?: string;
//   PageNo: number;
//   options?: string;
//   CorrectAnswer?: string;
// }

interface Step {
  AIStepID: number;
  AiStepName: string;
  AistepMedia: string;
  AIStepDescription: string;
  AIStepDisplayOrder: number;
}


// interface ContentState {
//   text: string;
//   images: { imgSrc: string; altText: string }[];
//   quizzes: Quiz[];
// }

interface pathFragment {
  node: RolePlaySectionObject;
  ExperienceID: number;
}

const pathStack: Stack<pathFragment> = new Stack<pathFragment>();

export const ImportExperienceComponent = () => {
  const [currentStep, setCurrentStep] = useState(0);



  const [list, setList] = useState<Organization[]>([]);

  const [Experiences, setExperiences] = useState<Experience[]>([]);
  const [mainExperience, setMainExperience] = useState<Experience | undefined>(undefined);
  const [currentExperience, setCurrentExperience] = useState<Experience | undefined>(undefined);
  const [currentNode, setCurrentNode] = useState<RolePlaySectionObject | undefined>(undefined);
  const [steps, setSteps] = useState<Step[]>([]);
  const defalutNodeRenderDetails = { title: "", text: "", image: "", audio: "https://altouraonyx.azureedge.net/altouradevcontainer/blank.mp3", video: "", PageNo: 0, options: [] };
  const [nodeRenderDetails, setNodeRenderDetails] = useState<NodeRenderDetails>(defalutNodeRenderDetails);
  const [newcurrentNode, setNewCurrentNode] = useState<any|undefined>(undefined);
  const [loading, setLoading] = useState(true); // Add this line

  const [userAnswers, setUserAnswers] = useState<{ [key: number]: string }>({});
  const [completed, setCompleted] = useState(false);
  // const [steps, setSteps] = useState<Step[]>([
  //   // Populate with initial step data
  // ]);
  const [isPanelVisible, setIsPanelVisible] = useState(true); // Initially visible
  const [nodes, setNodes] = useState<NodeRenderDetails[]>([]); // State to hold all nodes

  const { procedureID } = useParams();
  const procedureIDNumber = Number(procedureID) || -1;// Assuming the query param is '?myParam=value'
  const [isNameEditable, setIsNameEditable] = useState(false);
  const [isDescriptionEditable, setIsDescriptionEditable] = useState(false);

  // Temporary state to hold edits
  const [editableName, setEditableName] = useState("");
  const [editableDescription, setEditableDescription] = useState("");
  // Function to handle image file upload
  // Function to handle image file upload
  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // Check if reader.result is a string before updating the state
        if (typeof reader.result === 'string') {
          setUploadedImage(reader.result);
          setSteps(currentSteps => {
            const newSteps = [...currentSteps];
            if (typeof reader.result === 'string') { // Ensure this is a string before assigning
              newSteps[currentStep].AistepMedia = reader.result;
            }
            return newSteps;
          });
        }
      };
      reader.readAsDataURL(file); // Converts the file to a base64 string
    }
  };



  const [uploadedImage, setUploadedImage] = useState("");
  const toggleNameEdit = () => {
    setIsNameEditable(!isNameEditable);
    setEditableName(currentStepDetails.AiStepName);
  }

  const toggleDescriptionEdit = () => {
    setIsDescriptionEditable(!isDescriptionEditable);
    setEditableDescription(currentStepDetails.AIStepDescription);
  }

  // Handle changes in the inputs
  const handleNameChange = (e: any) => {
    const newName = e.target.value;
    setEditableName(newName);
    // Update the steps array immutably
    setSteps(currentSteps => {
      const newSteps = [...currentSteps];
      newSteps[currentStep].AiStepName = newName; // Assuming currentStep is the index of the step being edited
      return newSteps;
    });
  }

  const handleDescriptionChange = (e: any) => {
    const newDescription = e.target.value;
    setEditableDescription(newDescription);
    setSteps(currentSteps => {
      const newSteps = [...currentSteps];
      newSteps[currentStep].AIStepDescription = newDescription; // Assuming currentStep is the index of the step being edited
      return newSteps;
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectResponse = await listProjectsAndOrgs(parseInt(localStorage.getItem('userID') || "-1"));
        if (projectResponse.orgsList.length === 0 || projectResponse.data.length === 0) {
          throw new Error("Empty data array received from API");
        }
        setList(projectResponse.orgsList);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate()

  const { width, height } = useWindowSize();

  let stepHeaderText = "";

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getStepsfromProcedure(procedureIDNumber);
        console.log("Response from getProcedureData:", response);
        setSteps(response.data.steps); // Directly use the steps from the procedure found
        localStorage.setItem("content", JSON.stringify(steps));


      } catch (error) {
        console.error('Failed to fetch procedure data', error);
      }finally{
        setLoading(false)
      }
    };

    fetchData();
  }, [procedureIDNumber]); // Ensure the effect runs when procedureIDNumber changes


  const currentStepDetails = steps[currentStep] || {};
  console.log("currentStepDetails")
  console.log(currentStepDetails)

  const onNextClick = () => {
    setUploadedImage("")

    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setCompleted(true);
    }
  };
  const debouncedOnNextClick = useCallback(debounce(
    () => onNextClick(),
    300,  // Delay in ms
  ), [onNextClick]);  // Dependency array


  const onBackClick = () => {

    if (currentExperience && currentNode && pathStack.size() != 0) {

      const nextNodeData: pathFragment | undefined = pathStack.pop();
      if (nextNodeData !== undefined) {
        const nextNode = nextNodeData.node;
        setCurrentExperience(findExperienceByExperienceID(Experiences, nextNodeData.ExperienceID));

        console.log("currentNode", currentNode);
        console.log("nextNode", nextNode);
        if (nextNode) {
          setCurrentNode(nextNode);
        }
      }

    }

  };





  const updateCurrentSlide = (newStepIndex: any) => {
    setUploadedImage("")
    setCurrentStep(newStepIndex);
  };
  const onPrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(prev => prev - 1);
    }
  };
  const debouncedOnPrevious = useCallback(debounce(onPrevious, 300), [onPrevious])
  const onAnswerSubmit = (stepIndex: number) => {
    // Save the answer
    /* setUserAnswers(prev => ({
       ...prev,
       [stepIndex]: selectedOption[stepIndex],
     }));
   
     // Remove the selected option for the current step from the state
     setSelectedOption(prev => {
       const newState = { ...prev };
       delete newState[stepIndex]; // Remove the entry for the current step
       return newState;
     });
   
     // Logic to move to the next question
     if (stepIndex < (experience?.Steps.length || 0) - 1) {
       setCurrentStep(stepIndex + 1);
     } else {
       // Last question, handle accordingly
       setCompleted(true);
     }
     */
  };

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({ width: window.innerWidth, height: window.innerHeight + 100 });
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions(); // Initialize dimensions

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);


  const onRetry = () => {
    setCurrentStep(0); // Reset to the first step
    setUserAnswers({}); // Clear previous answers
    setCompleted(false); // Mark the experience as not completed
  };
  const [selectedOption, setSelectedOption] = useState<{ [key: number]: string }>({});
  const createNewNode = (type: string) => {
    const previousNodes = [...nodes]; // Copy the existing nodes

    // Create a new node with default values
    const newNode: NodeRenderDetails = {
      title: "Add a new Title",
      text: 'Add a new Text',
      image: '',
      audio: '',
      video: '',
      PageNo: previousNodes.length + 1,
      options: []
    };

    // Add the new node to the list of nodes
    previousNodes.push(newNode);

    // Update the state with the new list of nodes
    setNodes(previousNodes);

    // Set the new node as the current node
    setNewCurrentNode(newNode);
    setNodeRenderDetails(newNode);
  };

  useEffect(() => {
    if (currentNode) {
      setNodes(prevNodes => [...prevNodes, nodeRenderDetails]);
    }
  }, [nodeRenderDetails, currentNode]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="loader"></div> {/* Replace with your spinner component */}
      </div>
    );
  }
  



  if (completed) {

    return (
      <>
        <Header data={''} list={list} isDropdownDisabled={true} />
        <div className="overflow-hidden flex justify-center items-center h-[680px] bg-gray-100">
          <div className="overflow-hidden h-[650px] w-[500px] relative bg-white rounded-xl top-0 shadow w-full max-w-md px-4 py-8 mt-2 md:max-w-xl lg:max-w-2xl xl:max-w-4xl">
            <div className="confetti-wrapper absolute top-0 left-0 w-full h-[800px] overflow-hidden z-0 pointer-events-none">
              <Confetti width={dimensions.width} height={dimensions.height} />
            </div>
            <div className="flex flex-col  justify-center items-center gap-8 z-0 relative">
  <div className="relative flex flex-col justify-center items-center p-4">
    <img src={winningicon} alt="" className="w-full max-w-[411.97px] h-[250px]" />
  </div>


              <div className="text-center text-zinc-800 text-base md:text-lg lg:text-xl font-semibold">Congratulations!!</div>
              <div className="z-0 bg-white text-gray-500 text-sm md:text-base leading-relaxed px-2 md:text-left text-center">
                You have successfully completed the experience <b><span className='text-zinc-800'>{mainExperience?.ExperienceName ?? ""}.  </span> </b> <br/>  Click continue to go to Altoura home or click retry to try the experience again.
              </div>

              <div className="flex flex-col justify-center items-center gap-4 w-full px-4 sm:px-0">
                <button className="w-full sm:w-[408px] h-[45px] px-8 py-4 bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] rounded-md flex justify-center items-center text-white text-lg font-semibold" style={{ fontFamily: "'Selawk'" }} onClick={() => navigate('/')}>
                  Continue
                </button>

                <button className="w-full sm:w-[408px] h-[45px] px-8 py-4 bg-white text-blue-500 border border-blue-500 rounded-md flex justify-center items-center text-lg font-semibold" style={{ fontFamily: "'Selawk'" }} onClick={onRetry}>
                  Retry
                </button>
              </div>
            </div>
          </div>
        </div>




      </>

    );
  }





  return (





    <>

      {/* {audio==false && (
        <div style={{ position: 'absolute', top: '20%', left: '50%', transform: 'translate(-50%, -50%)', padding: '20px', backgroundColor: 'white', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
          <p>Please click "Enable Audio" to enable background music.</p>
          <button onClick={() =>handlePlay(true)}>Enable Audio</button>
        </div>
      )} */}




      <Header data={''} list={list} isDropdownDisabled={false} />
      <div className="flex  flex-col-reverse md:flex-row justify-start items-start w-full ">
        {/* <div className="slide-panel-container">       */}
        {isPanelVisible && steps.length > 0 && (
          <SlidePanel content={steps} onUpdateCurrentSlide={updateCurrentSlide} createNewNode={createNewNode}/>
        )}
        {/* </div> */}



        <div className="flex flex-col md:flex-row justify-center items-center  mt-20 w-full">


          <div className="font-selwak flex flex-col items-center justify-center ">
            <div className="fixed right-3  top-14 z-50 sm:right-3.5 sm:top-20">
              {/* <button onClick={() => setIsPanelVisible(prev => !prev)} className="p-1.5 text-xs  text-black border-none rounded-md sm:p-2 sm:text-base">
    {isPanelVisible ? 'Hide Panel' : 'Show Panel'}
  </button> */}
            </div>


            {/* <div 
  className="mt-5   mx-4 md:mx-20 my-4 md:w-auto rounded-md p-7 pt-0 bg-white flex justify-center items-center shadow-lg"
  style={{ boxShadow: '0 6px 12px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(0, 0, 0, 0.1)' }}
> */}
            <div className="flex justify-center  w-full">
              <div className="flex   flex-col  lg:flex-row mx-auto max-w-5xl">
                {currentStepDetails && (
                  <StepNavigation
                    onPrevious={() => debouncedOnPrevious()}
                    onNext={() => debouncedOnNextClick()}
                    title={
                      isNameEditable ? (
                        <input
                          type="text"
                          value={editableName}
                          onChange={handleNameChange}
                          onBlur={toggleNameEdit} // Save on blur or add a save button
                          autoFocus
                        />
                      ) : (
                        <span onClick={toggleNameEdit}>
                          {currentStepDetails.AiStepName || 'Loading...'}
                          <FontAwesomeIcon icon={faPen} className="inline ml-5 cursor-pointer text-sm" />
                        </span>
                      )
                    }
                  />
                )}
              </div>
            </div>

            <div className="flex justify-center w-full">
              <div className="flex flex-col lg:flex-row mx-auto max-w-4xl">

                {currentStepDetails.AistepMedia || uploadedImage ? (

                  <div
                    className="mr-5 flex flex-col overflow-hidden rounded-lg border-b border-transparent px-8 gap-2 bg-white max-w-full lg:w-[910px] min-h-[90vh] md:min-h-[62vh] mb-4 ml-4 lg:max-h-[50vh] h-full md:order-2"

                    style={{
                      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.08), 0 5px 15px rgba(0, 0, 0, 0.08)',
                      boxSizing: 'border-box'
                    }}
                  >
                    <div className="flex items-center justify-center p-4  shadow hover:bg-gray-300 cursor-pointer">
                      <label htmlFor="file-upload" className="flex items-center justify-center gap-2 w-full h-full">
                        <div className="text-2xl">+</div>
                        <span className="text-sm">Change Image</span>
                        <input
                          id="file-upload"
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                          className="hidden"
                        />
                      </label>
                    </div>

                    <img src={currentStepDetails.AistepMedia || uploadedImage} alt="Step Media" className="self-stretch grow shrink basis-0" />
                  </div>
                ) : (
                  // <div className="mr-4 flex flex-col overflow-auto rounded-lg border-b border-transparent px-8 gap-2 bg-white max-w-full lg:w-[900px] min-h-[50vh] md:min-h-[75vh] mb-4 ml-2 lg:h-[30vh] h-full md:order-1"
                  //   style={{
                  //     boxShadow: '0 6px 12px rgba(0, 0, 0, 0.08), 0 5px 15px rgba(0, 0, 0, 0.08)',
                  //     boxSizing: 'border-box'
                  //   }}
                  // >
                  <div className="flex  items-center justify-center p-4 min-h-[90vh] md:min-h-[63vh]  lg:max-h-[50vh]  ml-5  shadow hover:bg-stone-99 cursor-pointer">
                    <label htmlFor="file-upload" className="flex items-center justify-center gap-2 w-full">
                      <div className="text-2xl ">+</div>
                      <span className="text-sm ">Upload Image</span>
                      <input
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                        className="hidden"
                      />
                    </label>
                  </div>

                  // </div>
                )}

                <div
                  className="mr-5 flex flex-col overflow-hidden rounded-lg border-b border-transparent px-8 gap-2 bg-white max-w-full lg:w-[910px] min-h-[90vh] md:min-h-[62vh] mb-4 ml-4 lg:max-h-[50vh] h-full md:order-2"

                  style={{
                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.08), 0 5px 15px rgba(0, 0, 0, 0.08)',
                    boxSizing: 'border-box'
                  }}
                >      {nodeRenderDetails.options && nodeRenderDetails.options.length > 0 ? (
                  <>
                    <div className="p-8 pl-0 flex flex-col text-zinc-800 text-lg font-normal font-['Selawk'] leading-relaxed">
                      {currentStepDetails.AIStepDescription}
                    </div>

                    {selectedOption[currentStep] && (
                      <div className="mt-5 flex justify-end items-center w-full">
                        <div
                          className="w-[164px] h-[45px] px-8 py-4 bg-blue-500 rounded-md flex justify-center items-center gap-2.5 cursor-pointer"
                          onClick={() => {
                            // Your submit action here
                            onAnswerSubmit(currentStep);
                          }}
                        >
                          <div className="text-center text-white text-lg font-semibold font-['Selawk'] leading-relaxed">Submit</div>
                        </div>
                      </div>


                    )}
                  </>
                ) : (
                  <>
                    {currentStepDetails.AIStepDescription && (
                      <div className="w-full mt-10">
                        <div className="h-full text-gray-500 text-lg font-normal font-['Selawk'] leading-relaxed">
                          {isDescriptionEditable ? (
                            <textarea
                              value={editableDescription}
                              onChange={handleDescriptionChange}
                              onBlur={toggleDescriptionEdit} // Save on blur or add a save button
                              autoFocus
                              className="w-full h-full"
                            />
                          ) : (
                            <span onClick={toggleDescriptionEdit}>
                              {currentStepDetails.AIStepDescription}
                              <FontAwesomeIcon icon={faPen} className="inline ml-2 cursor-pointer" />
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  </>

                )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}


export default ImportExperienceComponent;
