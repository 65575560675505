import React, { useRef, useState } from "react";
import { ObjectListCard } from "./components/ObjectListCard";
import { useNavigate } from 'react-router-dom';
import cactus from "../../../logos/EmptyProjects.svg";
import localStorageService from "../../../services/localStorageService";
import add from "../../../logos/ico_add_white.svg";
import { ObjectData } from "../../../types/objectTypes";
import CreateObj from "./components/UpdateObjPopup";
interface ObjectListViewProps {
  projects: ObjectData[];
}

export const ObjectListView: React.FC<ObjectListViewProps> = ({ projects = [] }) => {
  const navigate = useNavigate();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [objresponse  , setObjresponse] = useState<any>();
  const [objdetails , setObjdetails] = useState<ObjectData>()



  const handleCardClick = (project: ObjectData) => {
    localStorageService.setItem("ProjectName", project.ObjectName);
    setObjdetails(project);
    console.log("onj details");
    console.log(objdetails);
    openModal();
    // navigate(`/Project/${project.ObjectID}/ExperienceList`);
  };

  if (!Array.isArray(projects) || projects.length === 0) {
    return (
      <div className="flex justify-center items-center h-[70vh] lg:h-[75vh] px-4 sm:px-6 lg:px-8 overflow-hidden">
        <div className="flex flex-col justify-center items-center gap-8 w-full max-w-screen-lg mx-auto">
          <div className="flex flex-col justify-start items-center gap-4">
            <div className="w-full max-w-[200vh] h-auto mt-4">
              <img src={cactus} className="w-full max-w-[411.97px] h-auto" alt="Not Found" />
            </div>
            <div className="flex flex-col justify-start items-center mt-2">
              <div className="text-center text-stone-900 text-xl font-normal font-['Selawk'] leading-[29px]">
                No Objects found
              </div>
            </div>
          </div>
          <div className="w-[166px] h-10 pl-4 pr-8 py-2 bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] rounded-lg justify-center items-center gap-2 inline-flex cursor-pointer">
            <div className="w-6 h-6 justify-center items-center flex">
              <div className="w-6 h-6 relative">
                <img src={add} alt="Add" />
              </div>
            </div>
            <div className="text-center text-white text-base font-normal font-['Selawk'] leading-normal">New Object</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full overflow-hidden justify-center items-center">
      <div className="flex mt-10 flex-wrap gap-2 sm:gap-6 md:gap-8 w-full max-w-[1200px] mx-auto px-4 sm:px-6 lg:px-8">
        {projects.map((project, index) => (
          <div
            onClick={() => handleCardClick(project)}
            key={`${project.ObjectID}-${index}`}
            className="w-full sm:w-auto mt-[-2vh]"
          >
            <ObjectListCard project={project} />
          </div>
        ))}
      </div>
      <CreateObj isVisible={isModalVisible} onClose={closeModal} objresponse={objdetails} />
    </div>
  );
};

export default ObjectListView;
