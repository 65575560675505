import axios from "axios";

const apiClient = axios.create({
  baseURL: "https://portaldev.altoura.com/",
  withCredentials: false, // Needed to include cookies
});

// Add a request interceptor
apiClient.interceptors.request.use(
  (config) => {
    // Retrieve token from local storage
    const token = localStorage.getItem("token");

    // If token is found, add it to the request's Authorization header
    if (token) {
      config.headers["x-access-token"] = token;
    }

    // You can also add other configurations here

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default apiClient;
