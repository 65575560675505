import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import importicon from "../../../../logos/ico_import_icon.svg";
import Header from '../../../../components/shared/HeaderFooter/Header';
import tick from '../../../../logos/ico_tick.svg';
import backbutton from '../../../../logos/ico_left_arrow.svg';
import { importClientPDF, getProcedureData, deleteSourceForImports, CreateProjectandSpace, LinkStepNodes, CreateStepNode, CreateExperience, CreateProduct } from '../../../../services/experienceService';
import localStorageService from '../../../../services/localStorageService'; 
import downarrow from '../../../../logos/ico_down_2.svg';
import uparrow from '../../../../logos/Small.svg';
import trashicon from '../../../../logos/ico_trash_3.svg';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import search from '../../../../logos/ico_search.svg'
import add from '../../../../logos/ico_add.svg'
import polygon from '../../../../logos/Polygon 1.svg'
import closeicon from '../../../../logos/ico_close.svg'

interface CreateWithProdigyProps{
    view:number
}
const CreateWithProdigy:React.FC<CreateWithProdigyProps> = ({ view: initialView }) => {
    interface FileStatus {
        id: number;
        name: string;
        progress: number;
        status: 'uploading' | 'success' | 'error';
    }
    interface Step {
        title: string;
        text: string;
        image: string;
        PageNo: number;
        options?: string;
        CorrectAnswer?: string;
    }
    interface Experience {
        ExperienceID?: number;
        ExperienceName: string;
        Steps: Step[];
    }
    interface SourceInfo {
        SourceID: number;
        SourceName: string;
        SourceStatus: number;
        SourceStatusMessage: string;
        SourceStatusPercentage: number;
        Experiences: Experience[];
    }
    interface OpenState {
        [key: number]: boolean;
    }
    interface ExpData {
        TrainingSectionID: number;
        SectionObjectID: number;
    }
    const [view, setView] = useState(initialView || 1);
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [latestStatusQueryUri, setLatestStatusQueryUri] = useState('');
    const [fileStatuses, setFileStatuses] = useState<FileStatus[]>([]);
    const [latestFileId, setLatestFileId] = useState<number | null>(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [showPreloader, setShowPreloader] = useState(false);
    const [sourceInfo, setSourceInfo] = useState<SourceInfo[]>([]);
    const [accOpen, setAccOpen] = useState<{ [key: number]: boolean }>({});
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);
    const easeOutQuart = (x: number) => 1 - Math.pow(1 - x, 4); 
    const progressBarContainerWidth = 746; 
    const progressBarWidth = Math.min(easeOutQuart(progress / 100) * progressBarContainerWidth, progressBarContainerWidth);
    const [sortField, setSortField] = useState<string>(''); 
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); 
    let projectName = localStorageService.getItem("ProjectName");
    const history = useNavigate();
    const [projresp , setProjresp] = useState<any>([]);
    const [listres , setListres] = useState<any>([]);
    const [isLoading , setIsLoading] = useState(false);
    const orgID = localStorageService.getItem("selectedOrgId");
    const [productDetails , setProductDetails] = useState()
    const [importSettings, setImportSettings] = useState({
        allPages: true,
        range: '',
        isRange: false,
        importImage: false,
        isAssessment: false,
        questionsCount: '',
    });


    const [searchActive, setSearchActive] = useState(false);
    const [searchText, setSearchText] = useState('');

    const handleSearchClick = () => {
        setSearchActive(true);
    };

    const handleSearchChange = (event: any) => {
        setSearchText(event.target.value);
    };

    const handleSearchSubmit = () => {
    };
    useEffect(() => {
        const projres = localStorage.getItem("projresp");
        console.log("projres");
        console.log(projres);
        const listresp = localStorage.getItem("listresp");
        console.log("listresp");
        console.log(listresp);
        if (projres) {
            const jsonresp = JSON.parse(projres);
            setProjresp(jsonresp);
        }
        if(listresp){
            const jsonresp = JSON.parse(listresp);
            setListres(jsonresp);
        }
    }, []); // Ensure the empty dependency array so it runs only on mount
    

    const handleDrop = (event: any) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length > 0 && files[0].type === 'application/pdf') {
            setFileName(files[0].name);
            setView(2); 
            setFile(files[0]);
            simulateUpload();
        }
    };

    const handleDragOver = (event: any) => {
        event.preventDefault();
    };

    const handleFileChange = (event: any) => {
        const files = event.target.files;
        if (files.length > 0 && files[0].type === 'application/pdf') {
            setFileName(files[0].name);
            setView(2); 
            setFile(files[0]);
            simulateUpload();
        }
    };

    const handleImportSettingsChange = (event: any) => {
        const { name, value, type, checked } = event.target;
        setImportSettings((prevSettings) => ({
            ...prevSettings,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleRadioChange = (event: any) => {
        const { name } = event.target;
        setImportSettings((prevSettings) => ({
            ...prevSettings,
            allPages: name === 'allPages',
            isRange: name === 'isRange',
        }));
    };

    const simulateUpload = () => {
        setUploadProgress(0);
        const interval = setInterval(() => {
            setUploadProgress((prev) => {
                if (prev >= 100) {
                    clearInterval(interval);
                    setView(3); 
                    return 100;
                }
                return prev + 1;
            });
        }, 0); 
    };

    const pollStatus = async () => {
        const checkStatus = async () => {
            try {
                const response = await fetch(latestStatusQueryUri);
                const data = await response.json();
                if (response.ok && data) {
                    setFileStatuses(prev => prev.map(file => file.id === latestFileId ? {
                        ...file,
                        progress: data.sourceStatusPercentage,
                        status: data.sourceStatusPercentage === 100 ? 'success' : 'uploading'
                    } : file));

                    if (data.sourceStatusPercentage === 100) {
                    } else {
                        setTimeout(checkStatus, 2000);
                    }
                } else {
                    throw new Error(`Failed to fetch status: ${response.statusText}`);
                }
            } catch (error) {
                console.error('Polling error:', error);
                setFileStatuses(prev => prev.map(file => file.id === latestFileId ? {
                    ...file,
                    status: 'error'
                } : file));
            }
        };

        checkStatus();
    };
    const handleSubmitToAPI = async () => {
        if (!file) {
            alert("Please select a file first.");
            return;
        }

        const fileId = Date.now();
        const pdfName = fileName || file.name;

        setFileStatuses(prev => [...prev, { id: fileId, name: fileName, progress: 0, status: 'uploading' }]);

        try {
            let orgID = localStorageService.getItem("selectedOrgId");
            const importImageString = importSettings.importImage.toString();
            const response = await importClientPDF(orgID, file, pdfName, importImageString);

            if (response.code === 201) {
                setView(4);

                setLatestFileId(fileId);
                setLatestStatusQueryUri(response.statusQueryGetUri);
                pollStatus(); 
            } else {
                throw new Error('Failed to send data');
            }
        } catch (error) {
            console.error('Error:', error);
            setErrorMessage(error instanceof Error ? error.message : String(error));
            setShowPreloader(false);
            setView(5);
            setFileStatuses(prev => prev.map(f => f.id === fileId ? { ...f, status: 'error' } : f));
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (view !== 4) {
                return; 
            }

            try {
                const orgID = localStorageService.getItem('selectedOrgId');


                const response = await getProcedureData(orgID);

                if (response && response.data && response.data.Sources && response.data.Sources.length > 0) {
                    setSourceInfo(response.data.Sources);
                    setView(response.data.Sources.length > 0 ? 4 : 1);
                    const openState: OpenState = {};
                    response.data.Sources.forEach((source: SourceInfo) => {
                        openState[source.SourceID] = false;
                    });
                    setAccOpen(openState);
                } else {
                    console.error('No sources found in the data');
                }
            } catch (error) {
                console.error('Failed to fetch procedure data:', error);
            }
        };

        fetchData();
    }, []); 
    const refreshData = async () => {
        if (view !== 4) {
            return;
        }
        try {
            let orgID = localStorageService.getItem("selectedOrgId")

            const response = await getProcedureData(orgID);
            console.log("Data refreshed:", response);
            if (response && response.data) {
                setSourceInfo(response.data.Sources);
                setView(response.data.Sources.length > 0 ? 4 : 1);
            } else {
                setSourceInfo([]);
                setView(1);
            }
        } catch (error) {
            console.error("Failed to refresh data:", error);
            setErrorMessage("Failed to load data");
        }
    };
    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;
        if (view===4) {
          interval = setInterval(async () => {
            await refreshData();
            const allCompleted = sourceInfo.every(source => source.SourceStatusMessage === "Completed");
            if (allCompleted) {
              if (interval) {
                clearInterval(interval);
              }
            }
          }, 10000810); // Refresh every 10.8 seconds
        }
        return () => {
          if (interval) {
            clearInterval(interval);
          }
        };
      }, [view===4, sourceInfo]);




    const toggleAccordion = (sourceID: number) => {
        setAccOpen(prev => ({ ...prev, [sourceID]: !prev[sourceID] }));
    };

    const handleSort = (field: string) => {
        const newOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(newOrder);
    };

    useEffect(() => {
        const sortedData = [...sourceInfo].sort((a, b) => {
            if (sortField === 'fileName') {
                return sortOrder === 'asc' ? a.SourceName.localeCompare(b.SourceName) : b.SourceName.localeCompare(a.SourceName);
            } else if (sortField === 'importedOn') {
                // return sortOrder === 'asc' ? new Date(a.SourceStatusMessage).getTime() - new Date(b.importedOn).getTime() : new Date(b.importedOn).getTime() - new Date(a.importedOn).getTime();
            }
            return 0;
        });
        setSourceInfo(sortedData);
    }, [sortField, sortOrder]);

    const filteredSourceInfo = sourceInfo.filter((source) =>
        source.SourceName.toLowerCase().includes(searchText.toLowerCase())
    );
    const handleClearSearch = () => {
        setSearchText('');
        setSearchActive(false);
    };
    // const handleModalSubmit = async (experienceName:string) => {
    //     const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    //     let expData: ExpData | undefined;
    //     let ProjandSpaceData: any = {}; // Declare ProjandSpaceData outside of the try block
      
    //     setIsLoading(true);
    //     try {
    //       const productResponse = await CreateProduct(experienceName, experienceName, orgID);
    //       if (productResponse.status < 200 || productResponse.status >= 300) {
    //         throw new Error('Failed to create product due to network or server issue.');
    //       }
    //       const productData = productResponse.data;
      
    //       setProductDetails(productData);
    //       console.log('Product created successfully:', productData.message);
    //       console.log('Product created successfully:', productData.data);
    //       console.log("dsdsdsdsds" + productData.ProductID);
    //       console.log('Proceeding to create experience...');
      
    //       // Add timeout before proceeding to the next API call
    //       await timeout(30);
      
    //       const expResponse = await CreateExperience(productData.ProductID, orgID, experienceName, experienceName);
    //       if (expResponse.status < 200 || expResponse.status >= 300) {
    //         throw new Error('Failed to create experience due to network or server issue.');
    //       }
    //       expData = expResponse.data;
    //       console.log("expData", expData);
      
    //       // setExpDetails(expData);
    //       if (!expData?.TrainingSectionID) {
    //         throw new Error("Exp details undefined");
    //       }
      
    //       const newStepNodeArray = content?.map((step, index) => ({
    //         NodeType: "StepNode",
    //         SectionObjectName: expData?.TrainingSectionID + "_Node_" + Math.round(Math.random() * 1000 + Math.random() * 1000),
    //         DisplayText: step.AIStepDescription,
    //         Title: step.AiStepName,
    //         ImageUrl: step.AistepMedia ? new URL(step.AistepMedia).pathname : "",
    //         NodeWorldLocation: { OrientationBehavior: "", GameObject: "" },
    //         poisitonX: 200 + (200 * index) - 200 * Math.floor(index / 6) * 6,
    //         poisitonY: 300 + 100 * Math.floor(index / 6),
    //         TargetObject: "",
    //         TransformMatrix: {
    //           position: { X: 0, Y: 0, Z: 0 },
    //           rotation: { X: 0, Y: 0, Z: 0 },
    //           scale: { X: 1, Y: 1, Z: 1 }
    //         },
    //         EventType: 1,
    //         ColliderName: "",
    //         ColliderTypeID: 2,
    //         SnapToPosition: "",
    //         PanelObject: "",
    //         PanelObjectTransformmatrix: {
    //           position: { X: 0, Y: 0, Z: 0 },
    //           rotation: { X: 0, Y: 0, Z: 0 },
    //           scale: { X: 1, Y: 1, Z: 1 }
    //         },
    //         TrainingObject: "",
    //         Constraints: [],
    //         InstructionAnimationObject: "",
    //         IAOHandDisplay: 1,
    //         JSONData: "{\"test\":\"test\"}"
    //       }));
      
    //       if (newStepNodeArray === undefined) return;
      
    //       let StepNodeIDs = [];
      
    //       for (const newStepNode of newStepNodeArray) {
    //         const CreateStepNodeResponse = await CreateStepNode(expData?.TrainingSectionID, newStepNode);
    //         if (CreateStepNodeResponse.code !== 200) {
    //           throw new Error('Failed to create step nodes');
    //         }
    //         console.log("CreateStepNodeResponseData", CreateStepNodeResponse);
    //         StepNodeIDs.push(CreateStepNodeResponse.data.SectionObjectID);
      
    //         // Add timeout before the next iteration
    //         await timeout(30);
    //       }
      
    //       let previousStepId = expData.SectionObjectID;
    //       for (const StepNodeID of StepNodeIDs) {
    //         const linkjson = {
    //           type: "Section",
    //           from: previousStepId,
    //           to: StepNodeID
    //         };
    //         console.log("BRUHHHHH IM HERE", productData.productID)
    //         const linknodes = await LinkStepNodes(expData.TrainingSectionID, linkjson);
    //         if (linknodes.status < 200 || linknodes.status >= 300) {
    //           throw new Error('Failed to create project and space due to network or server issue.');
    //         }
    //         const LinkNodesdata = linknodes.data;
    //         console.log(LinkNodesdata);
      
    //         previousStepId = StepNodeID;
      
    //         // Add timeout before the next iteration
    //         await timeout(30);
    //       }
      
    //       const CPAS = {
    //         ProjectName: experienceName,
    //         ProjectDesc: experienceName,
    //         OrganizationID: orgID,
    //         ThumbnailStretched: "/altouradevcontainer/ProjectThumbnails/Default_Thumbnail/ico_default_thumbnail.png",
    //         SectionObjectName: experienceName,
    //         SpaceThumbnail: "/altouradevcontainer/ProjectThumbnails/Default_Thumbnail/ico_default_thumbnail.png",
    //         MediaFilePropertyBag: `{\"AutoStartExperienceID\":"${productData.ProductUniqueID}"}`
    //       };
      
    //       const ProjandSpace = await CreateProjectandSpace(CPAS);
    //       if (ProjandSpace.status < 200 || ProjandSpace.status >= 300) {
    //         throw new Error('Failed to create project and space due to network or server issue.');
    //       }
    //       ProjandSpaceData = ProjandSpace.data;
    //       console.log("ProjandSpaceData", ProjandSpaceData);
      
    //     } catch (error) {
    //       console.error('Error in creating product or experience:', error);
    //       setErrorMessage(error + 'An unexpected error occurred.');
    //     } finally {
    //       console.log('Operation completed.');
    //       setIsLoading(false);
    //       console.log("ProjandSpaceData", ProjandSpaceData)
    //       if (ProjandSpaceData?.ProjectID) {
    //         navigate(`/Project/${ProjandSpaceData?.ProjectID}/ExperienceList`);
    //       }
    //     }
    //   };
      
    const renderView = () => {
        switch (view) {
            case 1:
                return (
                    <div className="w-[1128px] overflow-hidden h-full   pt-6 pb-4 flex-col justify-start items-start inline-flex" style={{ backgroundColor: '#f8fafc' }}>
                        <div className="self-stretch h-full flex-col justify-start items-start gap-2 flex">
                            <div className="flex-col justify-start items-start flex">
                                <div className="w-[1130px]  justify-start items-center gap-2 inline-flex">
                                    <div className="p-2 bg-white/opacity-0 rounded-3xl justify-center items-center gap-2.5 flex">
                                        <div className="w-6 h-6 justify-center items-center flex">
                                            <div className="w-6 h-6 relative">
                                                <div className="w-[13.38px] h-[11.05px] left-[5.37px] top-[6.47px] absolute">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grow shrink pl-8 basis-0 text-stone-900 text-m font-normal font-['Selawk'] leading-9 flex flex-row" onClick={() => navigate(-1)}>
                                        <div className="w-10 h-10 p-2 bg-white/opacity-0 rounded-3xl justify-center items-center gap-2.5 inline-flex">
                                            <div className="w-6 h-6 justify-center items-center flex">
                                                <div className="w-6 h-6 relative hover:bg-gray-200 rounded ">
                                                    <img src={backbutton} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        {projectName}
                                    </div>
                                </div>
                                <div className="w-[1130px] px-2 justify-start items-center gap-2 inline-flex">
                                    <div className="justify-start items-center gap-4 flex">
                                        <div className="justify-start items-center gap-2.5 flex">
                                            <div className="text-center text-stone-900 text-[24px] font-normal font-['Selawk'] leading-[49.84px] pl-20">Generate with Prodigy</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="w-full pl-20 h-[294px] bg-stone-50  px-1 flex-col justify-start items-start gap-2.5 inline-flex"
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <div className="w-[928px] h-[250px] justify-center items-center self-stretch h-[294px] p-16 rounded-xl border-2 border-gray-300 border-dashed flex-col justify-center items-center gap-2.5 flex">
                                    <input
                                        type="file"
                                        accept="application/pdf"
                                        onChange={handleFileChange}
                                        className="hidden"
                                        id="fileInput"
                                    />
                                    <label htmlFor="fileInput" className="flex-col justify-center items-center flex cursor-pointer">
                                        <div className="p-4 bg-stone-50 rounded-[60px] flex-col justify-start items-center gap-12 flex">
                                            <div className="w-16 h-16 opacity-25 rounded-[60px] justify-center items-center inline-flex">
                                                <div className="w-16 h-16 relative">
                                                    <img src={importicon} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-col justify-start mt-[-4vh] items-center gap-4 flex">
                                            <div className="w-[221px] h-[83px] flex-col justify-center items-center gap-2 inline-flex">
                                                <div className="flex-col justify-start items-center gap-4 flex">
                                                    <div className="flex-col justify-start items-center flex">
                                                        <div className="pt-6 text-center text-stone-900 text-xl font-normal font-['Selawk'] leading-[29px]">Drag and drop file here</div>
                                                        <div className="self-stretch justify-center items-center gap-2 inline-flex">
                                                            <div className="text-center pl-[12vh] text-gray-500 text-xl font-normal font-['Selawk'] leading-[29px]">or </div>
                                                            <div className="w-[224px] h-5 justify-start items-center flex">
                                                                <div className="text-blue-500 text-xl font-normal font-['Selawk'] leading-[29px]">Choose file</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-center w-[30vh] text-gray-500 text-xs font-normal font-['Selawk'] leading-[17.40px]">Supported formats: pdf, Max file size: 1gb</div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                );

            case 2:
                const radius = 45;
                const circumference = 2 * Math.PI * radius;
                const offset = circumference - (uploadProgress / 100) * circumference;

                return (
                    <div className="w-[1128px] h-full pt-6 pb-4 flex-col justify-start items-start inline-flex">
                        <div className="self-stretch h-[392px] flex-col justify-start items-start gap-2 flex">
                            <div className="flex-col justify-start items-start flex">
                                <div className="w-[1130px] justify-start items-center gap-2 inline-flex">
                                    <div className="p-2 bg-white/opacity-0 rounded-3xl justify-center items-center gap-2.5 flex">
                                        <div className="w-6 h-6 justify-center items-center flex">
                                            <div className="w-6 h-6 relative">
                                                <div className="w-[13.38px] h-[11.05px] left-[5.37px] top-[6.47px] absolute">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grow shrink pl-8 basis-0 text-stone-900 text-m font-normal font-['Selawk'] leading-9 flex flex-row" onClick={() => { setView(1) }}>
                                        <div className="w-10 h-10 p-2 bg-white/opacity-0 rounded-3xl justify-center items-center gap-2.5 inline-flex">
                                            <div className="w-6 h-6 justify-center items-center flex">
                                                <div className="w-6 h-6 relative hover:bg-gray-200 rounded">
                                                    <img src={backbutton} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        {projectName}
                                    </div>
                                </div>
                                <div className="w-[1130px] px-2 justify-start items-center gap-2 inline-flex">
                                    <div className="justify-start items-center gap-4 flex">
                                        <div className="justify-start items-center gap-2.5 flex">
                                            <div className="text-center text-stone-900 text-[12px] font-normal font-['Selawk'] leading-[49.84px] pl-20">Generate with Prodigy</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="w-full pl-20 h-[294px] px-1 flex-col justify-start items-start gap-2.5 inline-flex"
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                            >
                                <div className="w-[928px] h-[250px] justify-center items-center self-stretch h-[534px] p-16 rounded-xl border-2 border-gray-300 border-dashed flex-col justify-center items-center gap-2.5 flex">
                                    <input
                                        type="file"
                                        accept="application/pdf"
                                        onChange={handleFileChange}
                                        className="hidden"
                                        id="fileInput"
                                    />
                                    <label htmlFor="fileInput" className="flex-col justify-center items-center flex cursor-pointer ">
                                        <div className="w-24 h-24 relative" >
                                            <svg className="w-full h-full" viewBox="0 0 100 100">
                                                <circle
                                                    className="text-gray-200"
                                                    strokeWidth="4"
                                                    stroke="currentColor"
                                                    fill="transparent"
                                                    r={radius}
                                                    cx="50%"
                                                    cy="50%"
                                                    transform="rotate(-90 50 50)"

                                                />
                                                <circle
                                                    className="text-blue-500"
                                                    strokeWidth="4"
                                                    strokeDasharray={circumference}
                                                    strokeDashoffset={offset}
                                                    strokeLinecap="round"
                                                    stroke="currentColor"
                                                    fill="transparent"
                                                    r={radius}
                                                    cx="50%"
                                                    cy="50%"
                                                    transform="rotate(-90 50 50)"
                                                />
                                            </svg>
                                            <div className="left-[29px] top-[33.50px] absolute text-center text-gray-500 text-xl font-normal font-['Selawk'] leading-[29px]">{uploadProgress}%</div>
                                        </div>
                                        <div className="flex-col justify-start items-center gap-4 flex">
                                            <div className="w-[221px] h-[83px] flex-col justify-center items-center gap-2 inline-flex">
                                                <div className="flex-col justify-start items-center gap-4 flex">
                                                    <div className="flex-col justify-start items-center flex">
                                                        <div className="pt-2 text-center text-stone-900 text-xl font-normal font-['Selawk'] leading-[29px]">Importing file...</div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 3:
                return (
<div className="h-full pt-6 pb-4 flex-col justify-start items-start inline-flex">
    <div className="self-stretch flex-col justify-start items-start gap-2 flex">
        <div className="flex-col justify-start items-start flex">
            <div className="w-[800px] justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 text-stone-900 text-m font-normal font-['Selawk'] leading-9 flex flex-row" onClick={() => { setView(1) }}>
                    <div className="w-10 h-10 p-2 bg-white/opacity-0 rounded-3xl justify-center items-center gap-2.5 inline-flex">
                        <div className="w-6 h-6 justify-center items-center flex">
                            <div className="w-6 h-6 relative hover:bg-gray-200 rounded">
                                <img src={backbutton} alt="" />
                            </div>
                        </div>
                    </div>
                    {projectName}
                </div>
            </div>
            <div className="w-[800px] px-2 justify-start items-center gap-2 inline-flex">
                <div className="justify-start items-center gap-4 flex">
                    <div className="justify-start items-center gap-2.5 flex">
                        <div className="text-center text-stone-900 text-[24px] font-normal font-['Selawk'] leading-[49.84px]">Generate with Prodigy</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="w-[980px] h-[250px] p-10 bg-stone-200/opacity-30 rounded-xl border-2 border-dashed  flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="flex-col  justify-center items-center flex">
                <div className="p-4 mt-2 bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] rounded-[60px] flex-col justify-start items-center gap-12 flex" style={{transform:"scale(.8)"}}>
                    <div className="w-16 h-16 rounded-[60px] justify-center items-center inline-flex">
                        <div className="w-16 h-16 relative">
                            <img src={tick} alt='' />
                        </div>
                    </div>
                </div>
                <div className="flex-col justify-start mt-1 items-center gap-4 flex">
                    <div className="flex-col justify-start items-center flex">
                        <div className="text-center text-stone-900 text-[20px] font-normal font-['Selawk'] leading-[34.80px]">{fileName} imported successfully!</div>
                        <div className="w-[80px] h-[31px] px-4 py-1 rounded-lg border border-blue-500 justify-center items-center gap-2.5 inline-flex">
                            <div className="text-center text-blue-500 text-base font-normal font-['Selawk'] leading-normal" onClick={()=>{setView(1)}}>Delete</div>
                        </div>
                        <div className="opacity-0 text-center text-gray-500 text-2xl font-normal font-['Selawk'] leading-[34.80px]">or click to browse</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="self-stretch h-[200px] p-4 rounded-lg flex-col justify-start items-start gap-4 flex">
            <div className="text-center text-stone-900 text-lg font-normal font-['Selawk'] leading-relaxed">Import Settings:</div>
            <div className="px-2 flex-col justify-start items-start gap-2 flex">
                <div className="justify-start items-start gap-2 inline-flex">
                    <label className="flex items-center gap-2">
                        <input
                            type="radio"
                            name="allPages"
                            checked={importSettings.allPages}
                            onChange={handleRadioChange}
                            className="hidden"
                        />
                        <div className="w-[18px] h-[18px] relative">
                            {importSettings.allPages && (
                                <div className="w-3 h-3 left-[3px] top-[3px] absolute bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] rounded-full" />
                            )}
                            <div className="w-[18px] h-[18px] left-0 top-0 absolute rounded-full border border-blue-500" />
                        </div>
                        <div className="text-stone-900 text-base font-normal font-['Selawk'] leading-normal">All Pages</div>
                    </label>
                </div>
                <div className="justify-start items-center gap-2 inline-flex">
                    <label className="flex items-center gap-2">
                        <input
                            type="radio"
                            name="isRange"
                            checked={importSettings.isRange}
                            onChange={handleRadioChange}
                            className="hidden"
                        />
                        <div className="w-[18px] h-[18px] relative">
                            {importSettings.isRange && (
                                <div className="w-3 h-3 left-[3px] top-[3px] absolute bg-blue-500 rounded-full" />
                            )}
                            <div className="w-[18px] h-[18px] left-0 top-0 absolute rounded-full border border-blue-500" />
                        </div>
                        <div className="text-stone-900 text-base font-normal font-['Selawk'] leading-normal">Range</div>
                    </label>
                    <input
                        type="text"
                        name="range"
                        value={importSettings.range}
                        onChange={handleImportSettingsChange}
                        disabled={importSettings.allPages}
                        className="h-[31px] w-[80px] px-2 py-1 opacity-40 bg-stone-50 rounded-lg border border-stone-900/opacity-10 justify-start items-center gap-2.5 flex"
                    />
                </div>
                <div className="justify-start items-center gap-2 inline-flex">
                    <label className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            name="importImage"
                            checked={importSettings.importImage}
                            onChange={handleImportSettingsChange}
                            className="hidden"
                        />
                        <div className="w-[18px] h-[18px] relative">
                            {importSettings.importImage && (
                                <div className="w-[18px] h-[18px] bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] flex items-center justify-center rounded">
                                    <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                </div>
                            )}
                            {!importSettings.importImage && (
                                <div className="w-[18px] h-[18px] border border-blue-500 rounded" />
                            )}
                        </div>
                        <div className="text-center text-stone-900 text-base font-normal font-['Selawk'] leading-normal">Import Images</div>
                    </label>
                </div>
                <div className="w-[250px] justify-start items-center gap-2.5 inline-flex">
                    <div className="w-[15vh] text-stone-900 text-base font-normal font-['Selawk'] leading-normal">File Name</div>
                    <input
                        type="text"
                        name="fileName"
                        value={fileName}
                        onChange={(e) => setFileName(e.target.value)}
                        className="w-[30vh] h-[31px] px-2 py-1 bg-stone-50 rounded-lg border border-stone-900/opacity-10 flex items-center"
                    />
                </div>
                <div className="justify-start items-center gap-2 inline-flex">
                    <label className="flex items-center gap-2">
                        <input
                            type="checkbox"
                            name="isAssessment"
                            checked={importSettings.isAssessment}
                            onChange={handleImportSettingsChange}
                            className="hidden"
                        />
                        <div className="w-[18px] h-[18px] relative">
                            {importSettings.isAssessment && (
                                <div className="w-[18px] h-[18px] bg-[#4295e7]  flex items-center justify-center rounded">
                                    <svg className="w-3 h-3 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                    </svg>
                                </div>
                            )}
                            {!importSettings.isAssessment && (
                                <div className="w-[18px] h-[18px] border border-blue-500 rounded" />
                            )}
                        </div>
                        <div className="text-center text-stone-900 text-base font-normal font-['Selawk'] leading-normal">Is Assessment</div>
                    </label>
                </div>
                <div className="w-[400px] justify-start items-center gap-2.5 inline-flex">
                    <div className="text-stone-900 text-base font-normal font-['Selawk'] leading-normal">Questions Count</div>
                    <input
                        type="text"
                        name="questionsCount"
                        value={importSettings.questionsCount}
                        onChange={handleImportSettingsChange}
                        className="h-[31px] w-[30px] px-1 py-1 bg-stone-50 rounded-lg border border-stone-900/opacity-10 justify-start items-center flex"
                    />
                </div>
            </div>
        </div>
        <div className="self-stretch h-[71px] mt-[10vh] py-4 flex-col justify-center items-center flex">
            <button
                className="px-8 py-2 bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] rounded-lg justify-center items-center gap-2.5 inline-flex text-white"
                onClick={handleSubmitToAPI}
            >
                Analyze File
            </button>
        </div>
    </div>
</div>


                );
            case 4:
                return (
                    <>
                    <div className="h-full bg-stone-50 pt-6 pb-4 flex-col justify-start items-center inline-flex">
                        <div className="fixed mt-[-4.1vh] pt-2 w-full z-10 bg-stone-50 flex justify-center">
                            <div className="w-[1130px] flex-col bg-stone-50 z-10 justify-start items-start flex">
                                <div className="w-full justify-start items-center gap-2 inline-flex">
                                    <div className="grow shrink basis-0 text-stone-900 text-m font-normal font-['Selawk'] leading-9 flex flex-row  mt-1" onClick={() => navigate(-1)}>
                                        <div className="w-14 h-10 p-2 bg-white/opacity-0  justify-center items-center gap-2.5 inline-flex">
                                                    <img src={backbutton} alt="" className='hover:bg-[#f0f2f5] active:bg-[#e8eaed] rounded p-2'/>
                                        </div>
                                        {projectName}
                                    </div>
                                </div>
                                <div className="w-full px-2 justify-start items-center gap-2 inline-flex">
                                    <div className="w-full justify-start items-center gap-4 flex">
                                        <div className="justify-start items-center gap-2.5 flex">
                                            <div className="text-center text-stone-900 text-[28px] font-normal font-['Selawk'] leading-[49.84px]">Prodigy Managed Files</div>
                                        </div>
                                    </div>
                                    <div className="w-[425px] h-10 ml-[17vh] justify-start items-center gap-1 inline-flex ">
                                    {!searchActive && 
    <div className="pl-2 pr-4 py-2 ml-8 rounded border border-[#d6dae1] justify-center hover:bg-[#f1f3f5] active:bg-[#e8eaed] items-center gap-1 flex cursor-pointer" onClick={() => { setView(1) }}>
        <div className="w-6 h-6 justify-center items-center flex">
            <div className="w-6 h-6 relative">
                <img src={add} />
            </div>
        </div>
        <div className="text-left text-stone-900 text-xs font-normal font-['Selawk'] leading-normal">
            Generate with Prodigy
        </div>
    </div>
}

                                        <div className="p-2 bg-white/opacity-0 flex flex-row rounded-3xl justify-center items-center gap-2.5 flex ">
                                            {searchActive ? (
                                                <div className="w-[250px] flex flex-row h-10 pl-3 pr-2 py-2 bg-white rounded border border-blue-500 flex-col justify-center items-center inline-flex">
                                                    <div className="self-stretch flex flex-row justify-start items-center gap-1 inline-flex">
                                                        <input
                                                            type="text"
                                                            value={searchText}
                                                            onChange={handleSearchChange}
                                                            onKeyPress={(e) => e.key === 'Enter' && handleSearchSubmit()}
                                                            className="grow w-[250px] flex flex-row border-none focus:outline-none h-5 shrink basis-0 text-gray-500 text-sm font-normal font-['Selawk'] leading-tight "
                                                            placeholder="Search Files"
                                                        />
                                                        <div className="w-4 h-4 ml-2 flex flex-row cursor-pointer" onClick={handleClearSearch}>
                                                            <img src={closeicon} alt="Close" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="w-8 h-8 relative hover:bg-[#f1f3f5] active:bg-[#e8eaed] p-1 cursor-pointer rounded-full flex items-center justify-center" onClick={handleSearchClick}>
    <img src={search} className="w-5 h-5" />
</div>

                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='flex flex-row justify-center items-center mt-6'>
                                    <div className="w-[940px] flex flex-row h-9 p-2 bg-stone-50 rounded-lg ml-2 justify-start items-end gap-1 inline-flex">
                                        <div className="text-gray-700 text-sm font-normal font-['Selawk'] leading-tight" onClick={() => handleSort('fileName')}>File Name</div>
                                        <img src={polygon} alt="" className='ml-1 mb-[5.5px]' />
                                        <div className="w-1.5 self-stretch origin-top-left -rotate-180 justify-start items-center gap-2.5 flex" />
                                    </div>
                                    <div className="w-[146px] flex flex-row h-9 p-2 bg-stone-50 rounded-lg justify-start items-end gap-1 inline-flex">
                                        <div className="text-gray-700 flex flex-row text-sm ml-[-20px] font-normal font-['Selawk'] leading-tight">Imported On
                                            <img src={polygon} alt="" className='ml-2' />
                                        </div>
                                        <div className="w-1.5 self-stretch origin-top-left -rotate-180 justify-start items-center gap-2.5 flex" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full flex justify-center mt-[16vh]">
                            <div className="max-w-full md:w-[1130px] h-auto px-2.5 flex flex-col justify-start items-center gap-1.5">
                                {filteredSourceInfo.map((source) => (
                                    <div key={source.SourceID} className="w-full flex flex-col justify-start items-center">
                                        <div className="w-full p-4 bg-white border border-grey-200 rounded-lg flex-col justify-start items-start gap-4 inline-flex transition-all duration-300">
                                            <div className="self-stretch justify-center items-center gap-2 inline-flex">
                                                <div className="grow shrink basis-0 h-[23px] pr-1 justify-start items-center flex">
                                                    <div className="w-[881px] text-stone-900 text-base font-normal font-['Selawk'] leading-normal">{source.SourceName}</div>
                                                </div>
                                                <div className="w-full text-center text-gray-500 text-sm font-normal font-['Selawk'] leading-tight">Feb 23, 2024</div>
                                                <div className="justify-end items-center flex">
                                                    <div className="p-1 rounded-3xl justify-start items-start flex">
                                                        <div className="w-5 h-5 justify-center items-center flex  rounded  ">
                                                            <div className="w-5 h-5 relative rounded-full   hover:bg-gray-200">
                                                                <button onClick={() => deleteSourceForImports(source.SourceID)} className="p-0 gap-0 relative rounded-full hover:bg-gray-200">
                                                                    <img src={trashicon} alt="Delete" />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {(source.SourceStatusMessage !== "No Content" && Array.isArray(source.Experiences) && source.Experiences.length > 0) ? (
    <div className="p-1 bg-red rounded-3xl justify-start items-start flex" onClick={() => toggleAccordion(source.SourceID)}>
        <div className="w-5 h-5 justify-center items-center flex">
            <div className="w-5 h-5 relative">
                <img src={accOpen[source.SourceID] ? uparrow : downarrow} alt="arrow" className="mr-2" />
            </div>
        </div>
    </div>
) : (
    <div className="p-1 bg-red rounded-3xl justify-start items-start flex" onClick={() => toggleAccordion(source.SourceID)}>
        <div className="w-5 h-5 justify-center items-center flex">
            <div className="w-5 h-5 relative">
                {/* Placeholder or empty content */}
                <div className="w-5 h-5"></div> {/* Placeholder div */}
            </div>
        </div>
    </div>
)}


                                                </div>
                                            </div>
                                            {accOpen[source.SourceID] && source.Experiences && (
                                                <div className="w-full pt-4 pb-6 bg-white rounded-lg flex-col justify-start items-start gap-2 inline-flex">
                                                    {source.Experiences.map((experience) => (
                                                        <div key={experience.ExperienceID} className="w-full h-9 p-2 bg-sky-50 rounded-md border border-slate-200 justify-center items-center inline-flex" >
                                                            <div className="grow shrink basis-0 text-gray-500 text-sm font-normal font-['Selawk'] leading-tight">{experience.ExperienceName}</div>
                                                            <div className="w-[190px] h-3.5 justify-end items-end flex" >
                                                                <div className="text-blue-500 text-sm font-normal font-['Selawk'] leading-tight cursor-pointer" style={{ color: "#4295E7" }} >Create Experience</div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        {filteredSourceInfo.map((file, index) => (
                                            source.SourceStatusMessage !== "Completed" && file.SourceID === source.SourceID && (
                                                <div key={index} className="w-full bg-red-22 h-[70px] mb-3 flex-col justify-center items-start gap-2.5 inline-flex">
                                                    <div className="w-full rounded-b-lg  border-grey-300 border border-t-0 px-4 pt-4 pb-6 bg-white flex-col justify-start items-start gap-1 inline-flex">
                                                        <div className="self-stretch justify-start items-center gap-2 inline-flex">
                                                            <div className="grow shrink basis-0 h-[23px] pr-1 justify-start items-center flex">
                                                            </div>
                                                            <div className="justify-end items-center flex">
                                                                <div className="p-1 opacity-0 bg-white/opacity-0 rounded-3xl justify-start items-start flex">
                                                                    <div className="w-5 h-5 justify-center items-center flex">
                                                                        <div className="w-5 h-5 relative">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-1 bg-white/opacity-0 rounded-3xl justify-start items-start flex">
                                                                    <div className="w-5 h-5 justify-center items-center flex">
                                                                        <div className="w-5 h-5 relative">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {(file.SourceStatusMessage === "No Content" || file.SourceStatusMessage === "Process Complete") && (<div className="text-gray-500 text-sm font-normal font-['Selawk'] leading-tight">{`Status: ${source.SourceStatusMessage}`}</div>)}


                                                        {file.SourceStatusPercentage !== 100 && source.SourceStatusMessage !== "Process Complete"  &&
                                                            (<div className="self-stretch bg-red-33 h-7 flex-col justify-start items-start flex">
                                                                <>
                                                                    <div className="text-gray-500 text-sm font-normal font-['Selawk'] leading-tight">{`Status: ${source.SourceStatusMessage}`}</div>
                                                                    <div className="self-stretch grow shrink basis-0 relative rounded-2xl">
                                                                        <div className="w-full h-2 left-0 top-0 absolute bg-gray-200 rounded-2xl"></div>
                                                                        <div
                                                                            className="h-2 left-0 top-0 absolute bg-[#4295E7] overflow-hidden rounded-2xl"
                                                                            style={{ width: `${file.SourceStatusPercentage}%` }}
                                                                        >

                                                                        </div>
                                                                    </div>
                                                                </>
                                                            </div>
                                                            )}
                                                    </div>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    </>
                );
            default:
                return <div>CreateWithProdigy</div>;
        }
    };

    return (
        <div className="flex min-h-screen flex-col bg-stone-50 ">
            <Header data={''}  list={listres} isDropdownDisabled={true} projectResponse={projresp} />
            <div className="flex h-full justify-center items-center mt-[8vh] ">
                {renderView()}
            </div>
        </div>
    );
};

export default CreateWithProdigy;
