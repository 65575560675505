import React, { useState, useRef, useEffect } from 'react';
import importButton from '../../../logos/ico_import_2.svg';
import successicon from '../../../logos/ico_rnd_confrim_obj.svg'
import trashicon from '../../../logos/ico_trash.svg'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom'
import localStorageService from '../../../services/localStorageService'; // Update the path accordingly
import { Modal } from "../SidePanel/CreateExpPopUp/CreateExpPopup"
import { CreateProjectandSpace, deleteSourceForImports, CreateProduct, CreateExperience, CreateStepNode, LinkStepNodes, getProcedureData,  importClientPDF } from '../../../services/experienceService';

interface PopupModalProps {
  isOpen: boolean;
  onClose: () => void;
}
interface FileStatus {
  id: number;
  name: string;
  progress: number;
  status: 'uploading' | 'success' | 'error';
}
interface OpenState {
  [key: number]: boolean; // Use 'number' or 'string' depending on your key type
}
interface ProductDetails {
  ProductID: number | undefined;
  ProductUniqueID: string;
}

interface ExpData {
  SectionObjectID: number;
  TrainingSectionID: number;
}


export const PopupModal: React.FC<PopupModalProps> = ({ isOpen, onClose }) => {
  const [view, setView] = useState('default');
  const [file, setFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [pdfName, setPdfName] = useState('');
  const [progress, setProgress] = useState(0);
  const [sourceInfo, setSourceInfo] = useState<SourceInfo[]>([]);
  const [steps, setSteps] = useState([{ id: 'step1', content: 'Step 1 content' }, { id: 'step2', content: 'Step 2 content' }]); // Example steps state
  const [accOpen, setAccOpen] = useState<{ [key: number]: boolean }>({});
  const [showPreloader, setShowPreloader] = useState(false);
  const [isTest, setIsTest] = useState("true");
  const [sources, setSources] = useState<SourceInfo[]>([]);;
  const [importImage, setImportImage] = useState("true");
  const [fileStatuses, setFileStatuses] = useState<FileStatus[]>([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [productName, setProductName] = useState('');
  const [productDesc, setProductDesc] = useState('');
  const orgID = localStorageService.getItem("selectedOrgId")
  const [productDetails, setProductDetails] = useState<ProductDetails | null>(null);
  const [expDetails, setExpDetails] = useState<ExpData | null>(null);
  const content = localStorage.getItem("content")
  const easeOutQuart = (x: number) => 1 - Math.pow(1 - x, 2);
  const progressBarContainerWidth = 746; // The width of the container in pixels
  const progressBarWidth = Math.min(easeOutQuart(progress / 100) * progressBarContainerWidth, progressBarContainerWidth);
  const navigate = useNavigate();

  interface Step {
    title: string;
    text: string;
    image: string;
    PageNo: number;
    options?: string;
    CorrectAnswer?: string;
  }
  interface Experience {
    ExperienceName: string;
    ExperienceID: number;
  }

  interface SourceInfo {
    SourceID: number;
    SourceName: string;
    SourceStatus: number;
    SourceStatusMessage: string;
    SourceStatusPercentage: number;
    Experiences: Experience[];
  }


  interface Experience {
    ExperienceId?: number;
    ExperienceName: string;
    Steps: Step[];
  }

  interface Data {
    Experiences: Experience[];
    images: string[];
  }




  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };
  const handleCreateExperienceClick = () => {
    setShowModal(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      if (!isOpen) {
        return; // Exit early if the popup is not open
      }

      try {
        const orgID = localStorageService.getItem('selectedOrgId')

        const response = await getProcedureData(orgID);
        console.log("Procedure data fetched:", response);

        if (response && response.data && response.data.Sources && response.data.Sources.length > 0) {
          setSourceInfo(response.data.Sources);
          console.log("sourceInfo")
          console.log(sourceInfo)
          setView('uploadsuccess');
          const openState: OpenState = {};
          response.data.Sources.forEach((source: SourceInfo) => {
            openState[source.SourceID] = false;
          });
          setAccOpen(openState);
        } else {
          console.error('No sources found in the data');
        }
      } catch (error) {
        console.error('Failed to fetch procedure data:', error);
      }
    };

    fetchData();
  }, [isOpen]); // Only re-run the effect if isOpen changes


  const toggleAccordion = (sourceID: number) => {
    setAccOpen(prev => ({ ...prev, [sourceID]: !prev[sourceID] }));
  };
  const handleInputChange = (event: any) => {
    setPdfName(event.target.value);  // Update state with new input value
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSuccessMessage('');
    setErrorMessage('');
  };
  const handleModalSubmit = async () => {
    setIsLoading(true)
    try {
      const productResponse = await CreateProduct(productName, productDesc, orgID);
      if (productResponse.status < 200 || productResponse.status >= 300) {
        throw new Error('Failed to create product due to network or server issue.');
      }
      const productData = productResponse.data;


      setProductDetails(productData);
      console.log('Product created successfully:', productData.message);
      console.log('Product created successfully:', productData.data);
      console.log("dsdsdsdsds" + productData.ProductID);  // Confirm product ID
      console.log('Proceeding to create experience...');

      // Proceed with creating an experience
      const expResponse = await CreateExperience(productData.ProductID, orgID, productName, productDesc);
      if (expResponse.status < 200 || expResponse.status >= 300) {
        throw new Error('Failed to create experience due to network or server issue.');
      }
      const expData = expResponse.data;


      setExpDetails(expData.data);


      const newStepNodeArray = Array.isArray(content) ? content.map((step, index) => ({
        NodeType: "StepNode",
        DisplayText: step.AIStepDescription,
        Title: step.AiStepName,
        ImageUrl: step.AistepMedia ? new URL(step.AistepMedia).pathname : "",
        NodeWorldLocation: { OrientationBehavior: "", GameObject: "" },
        poisitonX: 200 + (200 * index) - 200 * Math.floor(index / 6) * 6,
        poisitonY: 300 + 100 * Math.floor(index / 6),
        TargetObject: "",
        TransformMatrix: {
          position: { X: 0, Y: 0, Z: 0 },
          rotation: { X: 0, Y: 0, Z: 0 },
          scale: { X: 1, Y: 1, Z: 1 }
        },
        EventType: 1,
        ColliderName: "",
        ColliderTypeID: 2,
        SnapToPosition: "",
        PanelObject: "",
        PanelObjectTransformmatrix: {
          position: { X: 0, Y: 0, Z: 0 },
          rotation: { X: 0, Y: 0, Z: 0 },
          scale: { X: 1, Y: 1, Z: 1 }
        },
        TrainingObject: "",
        Constraints: [],
        InstructionAnimationObject: "",
        IAOHandDisplay: 1,
        JSONData: "{\"test\":\"test\"}"
      })) : [];


      if (newStepNodeArray == undefined)
        return;


      let StepNodeIDs: number[] = [];

      // Create step nodes for the experience
      for (const newStepNode of newStepNodeArray) {
        const CreateStepNodeResponse = await CreateStepNode(expData.TrainingSectionID, newStepNode);
        if (CreateStepNodeResponse.code !== 200) {
          throw new Error('Failed to create step nodes');
        }
        console.log("CreateStepNodeResponseData", CreateStepNodeResponse);
        StepNodeIDs.push(CreateStepNodeResponse.data.SectionObjectID);

      }


      let previousStepId = expData.SectionObjectID;
      for (const StepNodeID of StepNodeIDs) {

        const linkjson = {
          "type": "Section",
          "from": previousStepId,
          "to": StepNodeID
        }

        const linknodes = await LinkStepNodes(2743, linkjson)
        if (linknodes.status < 200 || linknodes.status >= 300) {
          throw new Error('Failed to create project and space due to network or server issue.');
        }
        const LinkNodesdata = linknodes.data
        console.log(LinkNodesdata)

        previousStepId = StepNodeID;
      }
      
      localStorageService.setItem("productData_ProductUniqueID",productData.ProductUniqueID)

      const CPAS = {
        "ProjectName": productName,
        "ProjectDesc": productDesc,
        "OrganizationID": orgID,
        "ThumbnailStretched": "/altouradevcontainer/ProjectThumbnails/Default_Thumbnail/ico_default_thumbnail.png",
        "SectionObjectName": productName,
        "SpaceThumbnail": "/altouradevcontainer/ProjectThumbnails/Default_Thumbnail/ico_default_thumbnail.png",
        "MediaFilePropertyBag": `{\"AutoStartExperienceID\":"${productData.ProductUniqueID}"}`
      }
      // Create project and space if needed
      const ProjandSpace = await CreateProjectandSpace(CPAS);
      if (ProjandSpace.status < 200 || ProjandSpace.status >= 300) {
        throw new Error('Failed to create project and space due to network or server issue.');
      }
      const ProjandSpaceData = ProjandSpace.data;
      console.log("ProjandSpaceData", ProjandSpaceData);

      setSuccessMessage('Product and experience created successfully!');
      setErrorMessage('');
    } catch (error) {
      console.error('Error in creating product or experience:', error);
      setErrorMessage(error + 'An unexpected error occurred.');
      setSuccessMessage('');
    } finally {
      console.log('Operation completed.');
    }
    setShowModal(false)
  };

  const resetForm = () => {
    setProductName(''); // Reset product name
    setProductDesc(''); // Reset product description
    setIsLoading(false); // Ensure the loading indicator is turned off
    setErrorMessage(''); // Clear any error messages
    setSuccessMessage(''); // Clear any success messages
  };
  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(steps);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSteps(items);
  };
  const handleBackgroundClick = (event: React.MouseEvent) => {
    // Assuming your modal content has a specific className for identification, e.g., "modal-content"
    // You can adjust the className based on your actual implementation
    if ((event.target as HTMLElement).classList.contains('modal-background')) {
      onClose(); // Close the modal if the background is clicked
      setView('uploadsuccess')
    }
  };
  // Other states
  const modalRef = useRef<HTMLDivElement>(null); // Step 1: Create a ref for the modal container

  useEffect(() => {
    // Function to handle closing the modal if clicked outside
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose(); // Close the modal if the click is outside
        setView('uploadsuccess')

      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside); // Cleanup the event listener
    };
  }, [isOpen, onClose]); // Dependency array to re-run the effect when isOpen changes


  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
      setView('upload');
      // Assume upload starts immediately after a file is dropped for simplicity
      // In a real application, you might have a separate button to start the upload
      uploadFile(e.dataTransfer.files[0]);
    }
  };


  const handleDelete = async (sourceId: any) => {
    try {
      // Attempt to delete the source
      await deleteSourceForImports(sourceId);
      // If successful, filter out the deleted source
      const updatedSources = sourceInfo.filter((source) => source.SourceID !== sourceId);
      setSources(updatedSources);
    } catch (error) {
      console.error("Failed to delete source:", error);
      // Optionally, handle errors in UI, e.g., show a notification
    }
  };
  const uploadFile = async (file: File) => {
    let progress = 0;
    const simulateProgress = () => {
      return new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          progress += 10;
          setUploadProgress(progress);
          if (progress >= 100) {
            clearInterval(interval);
            resolve();
          }
        }, 100);
      });
    };

    try {
      await simulateProgress(); // Wait for the simulated upload to complete
      // Simulate successful upload response
      setPdfName(file.name); // Use the file's name as the uploaded PDF name
      setView('success');

      // Wait for 300ms before navigating
      // await new Promise(resolve => setTimeout(resolve, 500000));
      // navigate("/");
    } catch (error) {
      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(String(error));
      }
      setView('error');
    }

  };

  const [latestFileId, setLatestFileId] = useState<number | null>(null);
  const [latestStatusQueryUri, setLatestStatusQueryUri] = useState('');
  const handleSubmitToAPI = async () => {
    if (!file) {
      alert("Please select a file first.");
      return;
    }

    const fileId = Date.now();
    const fileName = pdfName || file.name;

    setFileStatuses(prev => [...prev, { id: fileId, name: fileName, progress: 0, status: 'uploading' }]);

    try {
      let orgID = localStorageService.getItem("selectedOrgId")
      const response = await importClientPDF(orgID, file, fileName,importImage);
      console.log("Response received");
      console.log(response);

      if (response.code === 201) {
        // setShowProgressSection(true);
        setView('uploadsuccess');

        setLatestFileId(fileId);
        setLatestStatusQueryUri(response.statusQueryGetUri);
        pollStatus(); // Trigger initial poll
      } else {
        throw new Error('Failed to send data');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(error instanceof Error ? error.message : String(error));
      setShowPreloader(false);
      setView('error');
      setFileStatuses(prev => prev.map(f => f.id === fileId ? { ...f, status: 'error' } : f));
    }
  };








  const pollStatus = async () => {
    const checkStatus = async () => {
      try {
        const response = await fetch(latestStatusQueryUri);
        const data = await response.json();
        if (response.ok && data) { // Check for a successful response
          // Update the file status with the latest percentage and status message
          setFileStatuses(prev => prev.map(file => file.id === latestFileId ? {
            ...file,
            progress: data.sourceStatusPercentage,
            status: data.sourceStatusPercentage === 100 ? 'success' : 'uploading'
          } : file));

          // Stop polling if the upload is completed
          if (data.sourceStatusPercentage === 100) {
            console.log("Upload complete");
          } else {
            setTimeout(checkStatus, 2000); // Continue polling every 2 seconds
          }
        } else {
          throw new Error(`Failed to fetch status: ${response.statusText}`);
        }
      } catch (error) {
        console.error('Polling error:', error);
        setFileStatuses(prev => prev.map(file => file.id === latestFileId ? {
          ...file,
          status: 'error'
        } : file));
      }
    };

    checkStatus(); // Initiate the first check
  };

  const fileStatusesRef = useRef(fileStatuses);
  fileStatusesRef.current = fileStatuses;

  useEffect(() => {
    const interval = setInterval(() => {
      setFileStatuses(prev => prev.map(f => f.id === latestFileId ? { ...f, progress: Math.min(f.progress + 10, 100) } : f));
      const currentFileStatus = fileStatusesRef.current.find(f => f.id === latestFileId);
      if (currentFileStatus && currentFileStatus.progress >= 100) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [latestFileId]); // Now, fileStatuses isn't in the dependency array

  useEffect(() => {
    localStorage.setItem('fileStatuses', JSON.stringify(fileStatuses));
  }, [fileStatuses]);
  useEffect(() => {
    const savedFileStatuses = localStorage.getItem('fileStatuses');
    if (savedFileStatuses) {
      setFileStatuses(JSON.parse(savedFileStatuses));
    }
  }, []);


  const [items, setItems] = useState([]);

  const refreshData = async () => {
    if (view === 'upload') {
      return;
    }
    try {
      let orgID = localStorageService.getItem("selectedOrgId")

      const response = await getProcedureData(orgID);
      console.log("Data refreshed:", response);
      if (response && response.data  ) {
        setSourceInfo(response.data.Sources);
        setView('uploadsuccess');  // or any other view state appropriate after refresh
      }else{
        setSourceInfo(response.data.Sources);
      }
    } catch (error) {
      console.error("Failed to refresh data:", error);
      setErrorMessage("Failed to load data");
    }
  };
  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isOpen) {
      interval = setInterval(async () => {
        await refreshData();
        const allCompleted = sourceInfo.every(source => source.SourceStatusMessage === "Completed");
        if (allCompleted) {
          if (interval) {
            clearInterval(interval);
          }
        }
      }, 6100); // Refresh every 5 seconds
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isOpen, sourceInfo]);

  useEffect(() => {
    if (isOpen) {
      refreshData(); // Call the refresh function when the modal opens
    }
  }, [isOpen]); // Depend on isOpen to trigger fetching when the modal opens


  const buttonContent = () => {
    switch (view) {
      case 'success':
        return (
          <>
            <div className="w-full h-[82px] px-4 py-5 bg-slate-800 flex-col justify-center items-center gap-2.5 inline-flex">
              <div className="px-5 py-[11px] bg-blue-800 justify-center items-center gap-2.5 inline-flex">
                <button onClick={handleSubmitToAPI} className="text-white text-lg font-normal font-['Selawk'] leading-tight">
                  Analyze with Prodigy
                </button>

              </div>
            </div>
          </>
        )
        break;
      case 'stepsview':
        return (
          <>
            <div className="w-full h-full py-5 md:py-6 lg:py-7 justify-center items-center gap-5 flex flex-wrap">
              <div className="w-[200px] sm:w-[200px] h-10 bg-blue-800 justify-center items-center gap-2.5 flex">
                <div className="text-white text-base md:text-lg font-normal font-['Selawk']">Export to Excel</div>
              </div>
              <div className="w-[200px] sm:w-[200px] h-10 bg-blue-800 justify-center items-center gap-2.5 flex">
                <div className="text-white text-base md:text-lg font-normal font-['Selawk']">Create Experience</div>
              </div>
            </div>
          </>
        )
        break;
      case 'stepsview':
        return (
          <div className="flex py-5 justify-center items-center gap-5 flex-wrap">
            <div className="flex-initial bg-blue-800 justify-center items-center gap-2.5 flex px-5 py-2.5 min-w-[160px] max-w-[200px]">
              <div className="text-white text-lg font-normal font-['Selawk']">Export to Excel</div>
            </div>
            <div className="flex-initial bg-blue-800 justify-center items-center gap-2.5 flex px-5 py-2.5 min-w-[160px] max-w-[200px]">
              <div className="text-white text-lg font-normal font-['Selawk']">Create Experience</div>
            </div>
          </div>

        );
        break;
    }
  }

  const renderContent = () => {
    switch (view) {
      case 'default':
        return (
          <>

            {/* Other default view content */}
          </>
        );
      case 'upload':
        return (
          <div className="max-w-full max-h-full bg-zinc-700 border-2 border-white flex flex-col justify-center items-center gap-2 p-4 sm:p-8" onClick={handleBackgroundClick}>
            <label htmlFor="file-upload" className="flex justify-center items-center cursor-pointer p-2 w-full max-w-[100px] h-[100px] sm:max-w-xs sm:h-auto">
              <img src={importButton} alt="Files" className="mr-2" />

              {/* You can insert an upload icon or any visual indicator here */}
              <input id="file-upload" type="file" onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  setFile(e.target.files[0]);
                  uploadFile(e.target.files[0]);
                }
              }} accept="application/pdf" style={{ display: 'none' }} />
            </label>
            <div className="text-white text-lg font-normal font-['Selawk'] leading-tight pb-10">Click here to import the file</div>
          </div>

        );
      case 'success':
        return (
          <>
            <div className="w-full h-full bg-zinc-700 border-2 border-white flex flex-col justify-center items-center gap-2">
              <img src={successicon} alt="Success" className="mr-2" />
              <div className="text-white text-lg font-normal font-['Selawk'] leading-tight pb-10">File {pdfName} imported successfully</div>
              {/* <button type="button" className="w-full bg-blue-600 text-white py-2 rounded" onClick={()=>{setView('upload')}}>Clear File</button> */}
            </div>
            <div className="flex flex-col p-4">
              <form>
                <div className="space-y-2">
                  <label className="inline-flex items-center">
                    <input type="radio" name="option" value="option1" defaultChecked className="text-blue-600 form-radio" />
                    <span className="ml-2 text-white">All pages</span>
                  </label>
                  <br></br>
                  {/* <label className="inline-flex items-center">
                    <input type="radio" name="option" value="option2" className="text-blue-600 form-radio" />
                    <span className="ml-2 text-white">Range</span>
                  </label> */}
                  <br></br>
                  <label className="inline-flex items-center">
                    <span className="ml-2 text-white">Name</span>
                    <input
                      type="text"
                      placeholder="Enter file name"
                      value={pdfName}
                      onChange={handleInputChange}
                      className="text-white w-[258px] h-6 pl-2.5 pr-[2px] py-0.5 bg-gray-700 justify-start items-center gap-2.5 inline-flex"
                    />
                  </label>
                  <br></br>
                </div>
                <div className="space-y-2">
                  {/* <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={isAssessment === "true"}
                      onChange={() => setIsAssessment(isAssessment === "true" ? "false" : "true")}
                      className="form-checkbox"
                    />
                    <span className="ml-2 text-white">Is Assessment</span>
                  </label> */}
                  <br></br>
                  {/* <label className="inline-flex items-center">
                    <span className="ml-2 text-white">Number of Questions</span>
                    <input
                      type="number"
                      value={numQues}
                      onChange={(e) => setNumQues(e.target.value)}
                      disabled={isAssessment !== "true"}  // Disable input if isAssessment is not "true"
                      className="form-input"
                    />
                  </label> */}
                  <br></br>
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={importImage === "true"}
                      onChange={() => setImportImage(importImage === "true" ? "false" : "true")}
                      className="form-checkbox"
                    />
                    <span className="ml-2 text-white">Import Image</span>
                  </label>
                </div>
              </form>
            </div>
          </>

        );
      case 'error':
        return (
          <>
            <p>Error uploading file: {errorMessage}</p>
            <button onClick={() => setView('default')}>Try Again</button>
          </>
        );
      case 'aftersuccess':
        return (
          <>
            <div className="w-[780px] h-[114px] px-2.5 pt-2.5 pb-5 border border-white flex-col justify-center items-start gap-2.5 inline-flex">
              <div className="self-stretch justify-start items-center gap-1.5 inline-flex">
                <div className="grow shrink basis-0 text-white text-base font-normal font-['Selawk'] leading-tight">{pdfName}</div>
                <div className="p-[9px] justify-start items-center gap-2.5 flex">
                  <div className="w-6 h-6 justify-center items-center flex">
                    <div className="w-6 h-6 relative">
                    </div>
                  </div>
                </div>
                <div className="p-[5px] justify-start items-center gap-2.5 flex">
                  <div className="w-8 h-8 justify-center items-center flex">
                    <div className="w-8 h-8 relative">
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch h-8 flex-col justify-center items-start gap-1 flex overflow-hidden">
                <div className="self-stretch justify-start items-center gap-1.5 inline-flex">
                  <div className="grow shrink basis-0 text-neutral-300 text-sm font-normal font-['Selawk'] leading-tight">Status: Pdf has been uploaded and added to the queue</div>
                </div>
                <div className="self-stretch grow shrink basis-0 relative">
                  <div className="w-full h-2 left-0 top-0 absolute bg-white" />
                  <div
                    className="h-2 left-0 top-0 absolute bg-blue-800 overflow-hidden"
                    style={{ width: `${Math.min(progressBarWidth, progressBarContainerWidth)}px` }} // Ensure the width does not exceed the container's width
                  />
                </div>
              </div>

            </div>
            {/* Other default view content */}
          </>
        );
      case 'uploadsuccess':
        return (
          <>
            {sourceInfo.map((source) => (
              <div key={source.SourceID} className="max-w-full md:w-[816px] h-auto md:h-[260px] px-2.5 pt-2 border border-white flex flex-col justify-start items-start gap-2.5">
                <div className="self-stretch justify-between items-start pt-0 mt-0 flex gap-0">
                  <div className="cursor-pointer flex items-center" onClick={() => toggleAccordion(source.SourceID)}>
                    <div className="text-white text-base font-normal font-['Selawk'] leading-tight">
                      {source.SourceName}
                    </div>
                  </div>
                  <div className='flex items-center gap-2'>
                    <button onClick={() => deleteSourceForImports(source.SourceID)} className="p-0 gap-0 rounded ">
                      <img src={trashicon} alt="Delete" className="mr-2" />
                    </button>
                    {accOpen[source.SourceID] ? (
                      <MdKeyboardArrowUp className="text-white text-2xl" onClick={() => toggleAccordion(source.SourceID)} />
                    ) : (
                      <MdKeyboardArrowDown className="text-white text-2xl" onClick={() => toggleAccordion(source.SourceID)} />
                    )}
                  </div>
                </div>
                {accOpen[source.SourceID] && source.Experiences && (
                  <div className="self-stretch h-auto mt-0 pt-0 flex flex-col justify-start items-start gap-1.5 overflow-auto">
                    {source.Experiences.map((experience) => (
                      <div key={experience.ExperienceID} className="self-stretch px-2.5 py-1 bg-gray-700 justify-start items-center gap-1.5 flex" onClick={() => navigate(`/experience/importProcedure/${experience.ExperienceID}`)}>
                        <div className="text-white text-[15px] font-normal font-['Selawk'] leading-tight">{experience.ExperienceName}
                        </div>
                        {/* <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCreateExperienceClick();
                            }}
                          className="flex flex-end justify-end ml-20 z-1 right-0 mr-0 h-[2vh] bg-blue-500 text-white text-xs">
                          Create Experience
                        </button> */}

                        <Modal
                          isOpen={showModal}
                          onClose={handleModalClose}
                          onSubmit={handleModalSubmit}
                          productName={productName}
                          productDesc={productDesc}
                          setProductName={setProductName}
                          setProductDesc={setProductDesc}
                          isLoading={isLoading}
                          successMessage={successMessage}
                          errorMessage={errorMessage}
                          resetForm={resetForm}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {sourceInfo.map((file, index) => (
                  source.SourceStatusMessage !== "Completed" && file.SourceID === source.SourceID && (
                    <div key={index} className="w-full h-[52px]  flex-col justify-center items-start gap-2.5 inline-flex">

                      <div className="self-stretch h-8 flex-col justify-center items-start gap-1 flex overflow-hidden mt-0 p-0">
                        <div className="self-stretch justify-start items-center gap-1.5 inline-flex ">
                          <div className="grow shrink basis-0 text-neutral-300 text-xs font-normal font-['Selawk'] leading-tight ">{`Status: ${source.SourceStatusMessage}`}</div>
                        </div>
                        {source.SourceStatusMessage !== "No Content" &&  (
                          <div className="self-stretch grow shrink basis-0 relative">
                            <div className="w-full h-2 left-0 top-0 absolute bg-white"></div>
                            <div
                              className="h-2 left-0 top-0 absolute bg-blue-800 overflow-hidden"
                              style={{ width: `${file.SourceStatusPercentage}%` }}
                            >

                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )
                ))}

              </div>
            ))}
          </>
        );


      default:
        return null;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="w-full  lg:h-[550px] md:w-[548px] h-full md:h-[648px] bg-zinc-700 flex flex-col justify-start items-start rounded-lg" ref={modalRef} onDragOver={handleDragOver} onDrop={handleDrop}>
        <div className="w-full h-8 pl-0 bg-slate-800 flex justify-between items-center ">
          <div className="flex items-center gap-2">
            {view === 'stepsview' && (
              <button onClick={() => setView('uploadsuccess')} className="w-8 h-8 flex justify-center items-center">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15 18l-6-6 6-6" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </button>
            )}
            <div className="text-white text-lg font-normal font-['Selawk'] capitalize pl-2">

              {view === 'stepsview' ? 'Step Details' : 'Prodigy Managed Files'}
            </div>
          </div>
          <div className="flex gap-2">
            {/* Conditional Back Button for stepsview */}

            <button onClick={() => setView('upload')} className="w-8 h-8 flex justify-center items-center">
              <span className="text-white">+</span>
            </button>
            {/* <button onClick={refreshData} className="w-8 h-8 flex justify-center items-center">
              <FontAwesomeIcon icon={faSyncAlt} className="text-white" />
            </button> */}




            <button onClick={() => { onClose(); setView('uploadsuccess'); }} className="w-8 h-8 flex justify-center items-center">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 6L18 18" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 18L18 6" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>

          </div>
        </div>

        <div className="w-full flex flex-col p-4 flex-1 overflow-auto ">
          {renderContent()}
        </div>
        <div className="w-full ">
          {buttonContent()}
        </div>
      </div>
    </div>
  );
};

export default PopupModal;