import React, { DragEventHandler, MouseEventHandler, useState, useEffect } from "react";
import { ObjectData } from "../../../../types/objectTypes";
import { FaChevronUp, FaChevronDown, FaSearch } from "react-icons/fa";
import { useSceneControl } from "../../Providers/SceneContextProvider";
import { HashLoader } from "react-spinners";

interface HorizontalScrollableListProps {
  objects: ObjectData[];
}

export const HorizontalScrollableList: React.FC<HorizontalScrollableListProps> = ({ objects }) => {
  const sceneProvider = useSceneControl();
  const [isOpen, setIsOpen] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating loading for demonstration
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer);
  }, []);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setIsSearchVisible(!isSearchVisible);
  };

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    const clickedObject = (event.currentTarget as any).dataset.object;
    console.log(clickedObject);
  };

  const handleDragStart: DragEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    const draggedObject = (event.currentTarget as any).dataset.object;
    console.log("Drag started: ", draggedObject);
  };

  const handleDragEnd: DragEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    const draggedObject = (event.currentTarget as any).dataset.object;
    sceneProvider.onObjectData.current(draggedObject);
    console.log("Drag ended: ", draggedObject);
  };

  const filteredObjects = objects.filter(object =>
    object.ObjectName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="absolute bottom-0 w-full bg-white shadow-md">
      <button onClick={toggleAccordion} className="w-full p-3 bg-white text-black flex items-center gap-2">
        {isOpen ? <FaChevronUp /> : <FaChevronDown />}
        Objects
      </button>

      {isOpen && (
        <div>
          <div className="relative flex items-center p-3">
            <div className="relative group flex-grow flex items-center">
              <FaSearch
                className="cursor-pointer mr-2"
                onClick={() => setIsSearchVisible(!isSearchVisible)}
              />
              Search
              {isSearchVisible && (
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Search objects..."
                  className="p-2 transition-width duration-300 h-7 ease-in-out ml-4 rounded border border-gray-300"
                  onBlur={() => setIsSearchVisible(false)}
                  onFocus={() => setIsSearchVisible(true)}
                />
              )}
            </div>
          </div>
          {loading ? (
            <div className="flex justify-center items-center p-3">
              <div className="loader">
                <HashLoader size={40}/>
                Loading...
              </div>
            </div>
          ) : filteredObjects.length === 0 ? (
            <div className="flex justify-center items-center p-3">
              <p>No objects found</p>
            </div>
          ) : (
            <div className="flex overflow-x-auto p-3 whitespace-nowrap">
              {filteredObjects.map((object, index) => (
                <div
                  key={index}
                  className="flex-none mr-3 p-3 bg-gray-200 rounded text-center transition-transform duration-300 ease-in-out transform hover:scale-105"
                  data-object={JSON.stringify(object)}
                  onClick={handleClick}
                  draggable
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                >
                  <img
                    src={object.ObjectThumbnail ? `https://altouraonyx.azureedge.net${object.ObjectThumbnail}`: `https://altouraonyx.azureedge.net/altouradevcontainer/ProjectThumbnails/Default_Thumbnail/ico_default_thumbnail.png`}
                    alt={object.ObjectName}
                    className="w-24 h-24 object-cover rounded mb-2"
                  />
                  <div className="text-sm font-bold">{object.ObjectName}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default HorizontalScrollableList;
