import React, { useState, useRef, useEffect } from 'react';

interface CustomDropdownProps {
  list: any[];
  selectedOrgId: number;
  setSelectedOrgId: (id: number) => void;
  updropdown: string;
  downdropdown: string;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({ list, selectedOrgId, setSelectedOrgId, updropdown, downdropdown }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (value: number) => {
    setSelectedOrgId(value);
    setIsDropdownOpen(false);
  };

  const handleClickOutside = (event: Event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <button
        type="button"
        className={`outline-none w-full active:border-[#4295e7] text-gray-500 text-base font-normal font-['Selawk'] leading-normal bg-white  p-2 rounded flex justify-between items-center ${isDropdownOpen ? 'border-blue-500' : 'border-gray-300'}`}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        <span>{list.find((org) => org.OrganizationID === selectedOrgId)?.OrganizationName || 'Select Organisation'}</span>
        <img
          src={isDropdownOpen ? updropdown : downdropdown}
          alt="Dropdown icon"
          className="h-6 w-6 cursor-pointer"
        />
      </button>
      {isDropdownOpen && (
        <ul className="absolute w-full bg-white border border-gray-300  mt-1 rounded shadow-lg max-h-60 overflow-auto z-10">
          {list.map((org) => (
            <li
              key={org.OrganizationID}
              className="p-2 cursor-pointer hover:bg-[#f1f3f5]"
              onClick={() => handleSelect(org.OrganizationID)}
            >
              {org.OrganizationName}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
