import React, { useEffect } from 'react';
import './SkeletonLoader.css';

interface SkeletonLoaderProp {
  count: number;
}

const SkeletonLoader: React.FC<SkeletonLoaderProp> = ({ count }) => {
  useEffect(() => {
    // Scroll to the top of the container when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="skeleton-container">
      {Array.from({ length: count }).map((_, index) => (
        <div key={index} className="skeleton-item">
          <div className="skeleton-card"></div>
          <div className="skeleton-text"></div>
        </div>
      ))}
    </div>
  );
};

export default SkeletonLoader;
