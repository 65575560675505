import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import localStorageService from '../../../services/localStorageService';
import { useParams,useNavigate } from 'react-router-dom';
import grabicon from '../../../logos/ico_grabber.svg'
import lefticon from '../../../logos/ico_chevron_left.svg'
import { StrictModeDroppable } from '../StrictModeDroppable';
import righticon from "../../../logos/ico_right_arrow.svg";
import lefticonav from "../../../logos/ico_left_arrow.svg";
import { CreateProjectandSpace, CreateProduct, CreateExperience, CreateStepNode, LinkStepNodes} from '../../../services/experienceService';
import { AnnotationDetails, Experience, RolePlaySectionObject } from '../../../types/experienceTypes';
import { Modal } from "./CreateExpPopUp/CreateExpPopup"

interface Slide {
  id: string;
  content: string; // URL to the slide image
}

interface NodeRenderDetails {
  title: string;
  text: string;
  image?: string;
  PageNo: number;
  options?: AnnotationDetails[];
}
const initialSlides: Slide[] = [
  { id: 'slide-1', content: 'https://via.placeholder.com/378x213' },
  { id: 'slide-2', content: 'https://via.placeholder.com/378x213' },
  { id: 'slide-3', content: 'https://via.placeholder.com/378x213' },
  { id: 'slide-4', content: 'https://via.placeholder.com/378x213' },
];
// interface Image {
//   imgSrc: string;
//   altText: string;
// }

interface Quiz {
  question: string;
  options: string[];
}
interface Step {
  AIStepID: number;
  AiStepName: string;
  AistepMedia: string;
  AIStepDescription: string;
  AIStepDisplayOrder: number;
}
interface StepsData {
  steps: Step[];
}
// interface Content {
//   text: string;
//   images: { imgSrc: string; altText: string }[];
//   quizzes: Quiz[];
// }

interface ContentState {
  text: string;
  images: { imgSrc: string; altText: string }[];
  quizzes: Quiz[];
}
// interface Step {
//   title: string;
//   text: string;
//   image?: string;
//   PageNo: number;
//   options?: string;
//   CorrectAnswer?: string;
// }

interface InterExperience {
  ExperienceId: number;
  ExperienceName: string;
  Steps: Step[];
}
interface SlidePanelProps {
  content: Step[] | undefined;
  onUpdateCurrentSlide: (newStepIndex: number) => void;
  width?: string; // Add a width prop for dynamic width control
  createNewNode ?:any;
}
interface Procedure {
  ProcedureName: string;
  ProcedureID: number;
}
interface SourceInfo {
  SourceID: number;
  SourceName: string;
  Procedures: Procedure[];
}

interface CreateProductApiResponse {
  code: number;
  data: ProductDetails;
  message: string;
}
interface CreateExpApiResponse {
  code: number;
  data: ExpData;
  message: string;
}

interface ProductDetails {
  ProductID: number | undefined;
  ProductUniqueID: string;
}
interface ExpData {
    TrainingSectionID: number;
    SectionObjectID: number;
}

const SlidePanel: React.FC<SlidePanelProps> = ({ content, onUpdateCurrentSlide, width = "416px" ,createNewNode}) => {

  const [slides, setSlides] = useState<Slide[]>(initialSlides);
  const [currentStep, setCurrentStep] = useState(0);

  const [nodeRenderDetails, setNodeRenderDetails] = useState<NodeRenderDetails>({ title: "", text: "", image: "", PageNo: 0, options: [] });
  const [newcontent, setNewcontent] = useState<ContentState>({ text: '', images: [], quizzes: [] });
  const orgID = localStorageService.getItem("selectedOrgId")
  const [experience, setExperience] = useState<InterExperience | null>(null);
  const [userAnswers, setUserAnswers] = useState<{ [key: number]: string }>({});
  const [productDetails, setProductDetails] = useState<ProductDetails | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  // const [completed, setCompleted] = useState(false);
  const [productName, setProductName] = useState('');
  const [productDesc, setProductDesc] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setDropdownOpen(false); // Close dropdown when accordion is toggled
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleCreateExperienceClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSuccessMessage('');
    setErrorMessage('');
  };



  // const [steps, setSteps] = useState<Step[]>([
  //   // Populate with initial step data
  // ]);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const { experienceID } = useParams();
  const experienceIDNumber = Number(experienceID) || -1;// Assuming the query param is '?myParam=value'

  const navigate = useNavigate()
  // const uniqueDroppableId = `stepsDroppable-${experienceID}`;

  const onNext = () => {
    setCurrentStep(prev => (experience && prev < experience.Steps.length - 1) ? prev + 1 : prev);
  };

  const onPrevious = () => {
    setCurrentStep(prev => prev > 0 ? prev - 1 : prev);
  };
  const onAnswerSelect = (option: string, stepIndex: number) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [stepIndex]: option,
    }));

    // Only move to the next step if it's not the last question
    if (stepIndex < (experience?.Steps.length || 0) - 1) {
      // setTimeout(() => {
      //   // onNext();
      // }, 300); // Delay to allow for a smooth user experience
    } else {
      // If it's the last question, mark the experience as completed

    }
  };
  const onDragEnd = (result:any) => {
    const { destination, source } = result;
  
    // Do nothing if the item is dropped outside the list or dropped into the same place it was dragged from
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }
  
    // Fetch the current experience data from local storage
    const allExperienceData = localStorageService.getItem("experienceData");
    if (!allExperienceData) {
      console.error("No experience data found in localStorage");
      return;
    }
  
    // Find the specific experience being edited
    const experienceData = allExperienceData["Experiences"]?.find((exp:any) => exp.ExperienceId === experienceIDNumber);
    if (!experienceData) {
      console.error("No matching experience data found");
      return;
    }
  
    // Update the Steps array within that experience based on the drag result
    const newSteps = Array.from(experienceData.Steps);
    const [reorderedItem] = newSteps.splice(source.index, 1);
    newSteps.splice(destination.index, 0, reorderedItem);
  
    // Update the specific experience's Steps in the allExperienceData object
    experienceData.Steps = newSteps;
  
    // Find the index of the updated experience in the allExperienceData array
    const experienceIndex = allExperienceData["Experiences"]?.findIndex((exp:any) => exp.ExperienceId === experienceIDNumber);
    if (experienceIndex !== -1) {
      allExperienceData["Experiences"][experienceIndex] = experienceData;
    }
  
    // Save the updated experience data back to local storage
    localStorageService.setItem('experienceData', JSON.stringify(allExperienceData));
  
    // Optionally, if you want to reflect the reordered steps in your component's state, do so here
    setExperience(experienceData); // Update local state to reflect the reordered steps
  
    console.log("Updated Steps Order:", newSteps);
  };



  useEffect(() => {
    const savedOrderString = localStorageService.getItem('slidesOrder');
    if (!savedOrderString) {
      console.log('No saved slides order found, using initial slides order.');
      return; // Exit early if there's no saved order
    }

    try {
      const savedOrder = JSON.parse(savedOrderString);
      if (!Array.isArray(savedOrder)) {
        throw new Error('Saved slides order is not an array.');
      }

      // Map over savedOrder to find matching slides and filter out any undefined values
      const orderedSlides = savedOrder
        .map((id) => initialSlides.find((slide) => slide.id === id))
        .filter((slide): slide is Slide => slide !== undefined); // Type guard to filter out undefined

      if (orderedSlides.length !== initialSlides.length) {
        throw new Error('Mismatch in the number of slides after ordering.');
      }

      setSlides(orderedSlides); // Set the slides to the ordered list
    } catch (e) {
      console.error('Error parsing saved slides order, reverting to initial slides order.', e);
      setSlides(initialSlides); // Fallback to initial slides order in case of any error
    }
  }, []); // The dependency array is empty, meaning this effect runs once on component mount

  const handleModalSubmit = async () => {
    const timeout = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
    let expData: ExpData | undefined;
    let ProjandSpaceData: any = {}; // Declare ProjandSpaceData outside of the try block
  
    setIsLoading(true);
    try {
      const productResponse = await CreateProduct(productName, productDesc, orgID);
      if (productResponse.status < 200 || productResponse.status >= 300) {
        throw new Error('Failed to create product due to network or server issue.');
      }
      const productData = productResponse.data;
  
      setProductDetails(productData);
      console.log('Product created successfully:', productData.message);
      console.log('Product created successfully:', productData.data);
      console.log("ProductID" + productData.ProductID);
      console.log("ProductUniqueID" + productData.ProductUniqueID);
      console.log('Proceeding to create experience...');
  
      // Add timeout before proceeding to the next API call
      await timeout(30);
  
      const expResponse = await CreateExperience(productData.ProductID, orgID, productName, productDesc);
      if (expResponse.status < 200 || expResponse.status >= 300) {
        throw new Error('Failed to create experience due to network or server issue.');
      }
      expData = expResponse.data;
      console.log("expData", expData);
  
      // setExpDetails(expData);
      if (!expData?.TrainingSectionID) {
        throw new Error("Exp details undefined");
      }
  
      const newStepNodeArray = content?.map((step, index) => ({
        NodeType: "StepNode",
        SectionObjectName: expData?.TrainingSectionID + "_Node_" + Math.round(Math.random() * 1000 + Math.random() * 1000),
        DisplayText: step.AIStepDescription,
        Title: step.AiStepName,
        ImageUrl: step.AistepMedia ? new URL(step.AistepMedia).pathname : "",
        NodeWorldLocation: { OrientationBehavior: "", GameObject: "" },
        poisitonX: 200 + (200 * index) - 200 * Math.floor(index / 6) * 6,
        poisitonY: 300 + 100 * Math.floor(index / 6),
        TargetObject: "",
        TransformMatrix: {
          position: { X: 0, Y: 0, Z: 0 },
          rotation: { X: 0, Y: 0, Z: 0 },
          scale: { X: 1, Y: 1, Z: 1 }
        },
        EventType: 1,
        ColliderName: "",
        ColliderTypeID: 2,
        SnapToPosition: "",
        PanelObject: "",
        PanelObjectTransformmatrix: {
          position: { X: 0, Y: 0, Z: 0 },
          rotation: { X: 0, Y: 0, Z: 0 },
          scale: { X: 1, Y: 1, Z: 1 }
        },
        TrainingObject: "",
        Constraints: [],
        InstructionAnimationObject: "",
        IAOHandDisplay: 1,
        JSONData: "{\"test\":\"test\"}"
      }));
  
      if (newStepNodeArray === undefined) return;
  
      let StepNodeIDs = [];
  
      for (const newStepNode of newStepNodeArray) {
        const CreateStepNodeResponse = await CreateStepNode(expData?.TrainingSectionID, newStepNode);
        if (CreateStepNodeResponse.code !== 200) {
          throw new Error('Failed to create step nodes');
        }
        console.log("CreateStepNodeResponseData", CreateStepNodeResponse);
        StepNodeIDs.push(CreateStepNodeResponse.data.SectionObjectID);
  
        // Add timeout before the next iteration
        await timeout(30);
      }
  
      let previousStepId = expData.SectionObjectID;
      for (const StepNodeID of StepNodeIDs) {
        const linkjson = {
          type: "Section",
          from: previousStepId,
          to: StepNodeID
        };
        console.log("BRUHHHHH IM HERE", productData.productID)
        const linknodes = await LinkStepNodes(expData.TrainingSectionID, linkjson);
        if (linknodes.status < 200 || linknodes.status >= 300) {
          throw new Error('Failed to create project and space due to network or server issue.');
        }
        const LinkNodesdata = linknodes.data;
        console.log(LinkNodesdata);
  
        previousStepId = StepNodeID;
  
        // Add timeout before the next iteration
        await timeout(30);
      }
  
      const CPAS = {
        ProjectName: productName,
        ProjectDesc: productDesc,
        OrganizationID: orgID,
        ThumbnailStretched: "/altouradevcontainer/ProjectThumbnails/Default_Thumbnail/ico_default_thumbnail.png",
        SectionObjectName: productName,
        SpaceThumbnail: "/altouradevcontainer/ProjectThumbnails/Default_Thumbnail/ico_default_thumbnail.png",
        MediaFilePropertyBag: `{\"AutoStartExperienceID\":"${productData.ProductUniqueID}"}`
      };
  
      const ProjandSpace = await CreateProjectandSpace(CPAS);
      if (ProjandSpace.status < 200 || ProjandSpace.status >= 300) {
        throw new Error('Failed to create project and space due to network or server issue.');
      }
      ProjandSpaceData = ProjandSpace.data;
      console.log("ProjandSpaceData", ProjandSpaceData);
  
      setSuccessMessage('Product and experience created successfully!');
      setErrorMessage('');
    } catch (error) {
      console.error('Error in creating product or experience:', error);
      setErrorMessage(error + 'An unexpected error occurred.');
      setSuccessMessage('');
    } finally {
      console.log('Operation completed.');
      setIsLoading(false);
      setShowModal(false);
      console.log("ProjandSpaceData", ProjandSpaceData)
      if (ProjandSpaceData?.ProjectID) {
        navigate(`/Project/${ProjandSpaceData?.ProjectID}/ExperienceList`);
      }
    }
  };
  
  

  const resetForm = () => {
    setProductName(''); // Reset product name
    setProductDesc(''); // Reset product description
    setIsLoading(false); // Ensure the loading indicator is turned off
    setErrorMessage(''); // Clear any error messages
    setSuccessMessage(''); // Clear any success messages
};


  return (
  <div className={`bg-stone-50 mt-20 transition-width ease-in-out ${isOpen ? `w-full h-[675px] sm:w-[${width}]` : 'w-full sm:w-16'} flex flex-col relative`}>
  <button onClick={toggleAccordion} className={`flex items-center justify-start p-2 ${isOpen ? 'w-full' : 'flex-col h-full w-full sm:w-auto'}`}>
    <div className="w-full flex justify-between items-center relative">
      <span className={`absolute justify-center text-black text-xl font-normal Selawk leading-[29px] ${isOpen ? 'left-10' : 'md:top-20 md:-rotate-90'}`}>
        Steps
      </span>

      <div className="flex justify-end w-full">
        <img src={lefticon} alt="Toggle" className={`${isOpen ? 'rotate-0' : 'rotate-180'}`} />
      </div>
    </div>
  </button>
        <button onClick={toggleDropdown} className={`ml-2 text-gray-500 ${isOpen ? 'w-full' : 'hidden'}`}>
            Add a new nodes
          </button>
         
          {dropdownOpen && (
        <div className="absolute top-0 w-[18vh] left-full ml-3 p-2 bg-gray-800 border-gray-700 text-white shadow-md z-10">
          <ul>
            <li className="border-b border-gray-700">
              <button onClick={() => createNewNode('StepNode')} className="block w-full text-left px-4 py-2 hover:bg-gray-700">
                Add a StepNode
              </button>
            </li>
            <li className="border-b border-gray-700">
              <button onClick={() => createNewNode('ChoiceNode')} className="block w-full text-left px-2 py-2 hover:bg-gray-700">
                Add a ChoiceNode
              </button>
            </li>
            <li>
              <button onClick={() => createNewNode('LinkNode')} className="block w-full text-left px-4 py-2 hover:bg-gray-700">
                Add a LinkNode
              </button>
            </li>
          </ul>
        </div>
      )}

  {isOpen && (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable droppableId="droppableID">
        {(provided) => (
          <>
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="flex overflow-auto sm:flex-col w-full sm:w-[416px] sm:h-[930px] pr-6 bg-stone-50 border-r-2 border-stone-300 border-opacity-30 justify-start items-start gap-6"
            >
              {content?.map((step, index) => (
                <Draggable key={`${nodeRenderDetails.title}-${index}`} draggableId={`draggable-${index}`} index={index}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="pl-12 bg-stone-50 bg-opacity-0 flex-col justify-start items-start gap-6 inline-flex mt-5"
                      style={provided.draggableProps.style}
                      onClick={() => onUpdateCurrentSlide(index)}
                    >
                      <div className="w-[322px] h-[166px] bg-neutral-100 rounded-xl shadow border-2 hover:border-blue-500 justify-center items-center gap-3 inline-flex">
                        <div className="w-8 h-8 justify-center items-center flex">
                          <div className="w-8 h-8 relative">
                            <img src={grabicon} alt="Grab" className='hover:display ' />
                          </div>
                        </div>
                        <div className="text-zinc-800 text-sm font-normal font-['Selawk'] leading-tight">{index + 1}</div>
                        <div className="w-full h-[213px] pb-0 overflow-auto">
                          <div className="ml-5 text-[5.50px] bg-white w-[185px] mt-9 h-5 flex py-2 ml-2 justify-start items-center mx-auto max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl 2xl:max-w-7xl rounded-sm" style={{
                            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.08), 0 5px 15px rgba(0, 0, 0, 0.08)',
                            boxSizing: 'border-box'
                          }}>
                            <div className="w-[185px] mb-4 flex items-start justify-start ">
                              {true && (
                                <button onClick={onPrevious} className="w-[20px] h-[20px] mt-2 pt-3">
                                  <img src={lefticonav} alt="Previous" />
                                </button>
                              )}

                              <div className="bg-white w-[374px] mt-4 text-[5.5px] flex-grow text-center text-zinc-800 text-sm leading-normal">
                                <div>{step.AiStepName}</div>
                              </div>

                              {true && (
                                <button onClick={onNext} className="w-[20px] mt-5 mr-4 bg-blue-500 hover:bg-blue-700 text-white">
                                  <img src={righticon} alt="Next" />
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row w-full max-w-7xl">
                            <div className="overflow-hidden flex w-[372px] ">
                            {step.AistepMedia ? (
  <div
  className="flex mt-2 ml-3 mb-2 flex-col overflow-auto rounded-lg border-b border-transparent px-2 gap-2 bg-white max-w-full h-[110px]"
  style={{
    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.08), 0 5px 15px rgba(0, 0, 0, 0.08)',
    boxSizing: 'border-box'
  }}
>
  <img src={step.AistepMedia} alt={step.AIStepDescription} className="self-stretch grow shrink basis-0 w-[45px] h-full object-contain" />
</div>
) :(<></>)}

                              <div
                                className={`flex mt-2 w-[150px] ${step.AistepMedia ? 'ml-1' : 'ml-10'} mb-2 flex-col rounded-lg border-b border-transparent px-4 py-2 gap-2 bg-white max-w-full h-[110px]`}
                                style={{
                                  boxShadow: '0 6px 12px rgba(0, 0, 0, 0.08), 0 5px 15px rgba(0, 0, 0, 0.08)',
                                  boxSizing: 'border-box',
                                  fontSize: '2px',
                                }}
                              >
                                {newcontent.quizzes.length > 0 && (newcontent.text === nodeRenderDetails.text) ? (
                                  <>
                                    <div className="text-zinc-800 text-lg font-normal font-['Selawk'] leading-relaxed text-3">
                                      {newcontent.text}
                                    </div>
                                    <div className="pt-6 flex flex-col justify-start items-start gap-4 w-full">
                                      {newcontent.quizzes.map((quiz, quizIndex) => (
                                        <React.Fragment key={quizIndex}>
                                          <div className="text-zinc-800 text-lg font-normal font-['Selawk'] leading-relaxed items-start justify-start">
                                            Choose one of the following options.
                                          </div>
                                          <div className="flex flex-col justify-start items-center gap-3 w-full">
                                            {quiz.options.map((option, optionIndex) => (
                                              <div
                                                key={optionIndex}
                                                className="px-5 py-4 bg-blue-500 justify-center items-center gap-2.5 flex w-full cursor-pointer"
                                                onClick={() => onAnswerSelect(option, currentStep)}
                                              >
                                                <div className="text-center text-white text-lg font-normal font-['Selawk'] leading-relaxed">
                                                  {option}
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {step.AIStepDescription && (
                                      <div className="mt-0 overflow-hidden text-[5px]">
                                        <div className="text-[5.5px] h-[85px] text-xs overflow-hidden text-zinc-800 text-lg font-normal font-['Selawk'] leading-relaxed"
                                          style={{
                                            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.08), 0 5px 15px rgba(0, 0, 0, 0.08)',
                                            boxSizing: 'border-box',
                                            fontSize: '7px',
                                          }}
                                        >
                                          {step.AIStepDescription}
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          </>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  )}
<button
  onClick={handleCreateExperienceClick}
  className={`fixed bottom-2 left-12 right-10 z-0 bg-blue-500 text-white font-normal Selawk leading-[29px] rounded-lg text-xl ${
    isOpen ? 'w-full sm:w-[322px] bottom-0' : 'min-w-[43px] hidden'
  } sm:min-w-[3px] sm:text-xl sm:h-[6vh] text-sm h-[4vh]`}
>
  Create Experience
</button>



  <Modal
    isOpen={showModal}
    onClose={handleModalClose}
    onSubmit={handleModalSubmit}
    productName={productName}
    productDesc={productDesc}
    setProductName={setProductName}
    setProductDesc={setProductDesc}
    isLoading={isLoading}
    successMessage={successMessage}
    errorMessage={errorMessage}
    resetForm={resetForm}
  />
</div>


  );
};

export default SlidePanel;