import React, { useEffect, useState, useCallback } from "react";
import { Header } from "../../../components/shared/HeaderFooter/Header";
import { StepNavigation } from "../../../components/shared/StepNavigationExperience";
import { Footer } from "../../../components/shared/HeaderFooter/Footer";
import { useParams, useNavigate } from "react-router-dom";
import Confetti from "react-confetti";
import Canvas from "../../CanvasComponent/CanvasComponent";
import { useWindowSize } from "react-use";
import { useLocation } from "react-router-dom";
import { getExperienceData } from "../../../services/experienceService";
import {
  CreateExperienceSession,
  GetExperienceSessionActivePath,
  GetExperienceSessionPath,
  GetLastExperienceSessionStatus,
  SendResumeExperience,
  SendUpdateExperienceSessionMode,
  AddExperienceSessionEndTime,
  AddExperienceSessionPath,
  AddUsersUnderExperienceSession,
} from "../../../services/experienceSessionUtils";
import {
  AnnotationDetails,
  DisplayTemplate,
  Experience,
  ExperienceResponseData,
  findExperienceByExperienceID,
  findMediaFileByDisplayTemplateID,
  findNextNode,
  findNodeByID,
  getAudioTextTextData,
  getLinkedNodeData,
  getMediaFilePath,
  getRolePlayChoiceTextData,
  getRolePlayStepByStepTextData,
  getStartNodeForExperience,
  RolePlaySectionObject,
  SOTypeID,
} from "../../../types/experienceTypes";
import { Stack } from "../../../types/stack";
import "video-react/dist/video-react.css";
import { Player, BigPlayButton } from "video-react";
import { AudioPlayer } from "../../../components/shared/AudioPlayer/AudioPlayer";
import SlidePanel from "../../../components/shared/SidePanel/SidePanel";
import winningicon from "../../../logos/12699838_Smart guy getting award 1.svg";
import { Organization } from "../../../types/projectTypes";
import { listProjectsAndOrgs } from "../../../services/projectService";
import debounce from "lodash/debounce";
import {
  defalutNodeRenderDetails,
  NodeRenderDetails,
  pathFragment,
} from "../../../types/nodeDataTypes";
import altourablue from "../../../logos/altourablue.svg";
import close from "../../../logos/ico_close_.svg";
import { HashLoader } from "react-spinners";
import "./expcss.css";
import StartTrainingModal from "./startTrainingModal";
const pathStack: Stack<pathFragment> = new Stack<pathFragment>();
const linkStack: Stack<pathFragment> = new Stack<pathFragment>();

interface SavedProgress {
  nodeID: number;
  experienceID: number;
}

export const ExperienceComponent = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [audio, setAudio] = useState<boolean>(false);
  const [player] = useState(() => new AudioPlayer());
  const [audioUrl, setAudioUrl] = useState(
    "https://altouraonyx.azureedge.net/altouradevcontainer/blank.mp3"
  );
  const [loading, setLoading] = useState(true);

  const [Experiences, setExperiences] = useState<Experience[]>([]);
  const [mainExperience, setMainExperience] = useState<Experience | undefined>(
    undefined
  );
  const [currentExperience, setCurrentExperience] = useState<
    Experience | undefined
  >(undefined);
  const [currentNode, setCurrentNode] = useState<
    RolePlaySectionObject | undefined
  >(undefined);

  const [nodeRenderDetails, setNodeRenderDetails] = useState<NodeRenderDetails>(
    defalutNodeRenderDetails
  );

  const [userAnswers, setUserAnswers] = useState<{ [key: number]: string }>({});
  const [completed, setCompleted] = useState(false);
  const [hoveredOption, setHoveredOption] = useState<number | null>(null);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const location = useLocation();
  const [newExpsessionID, setNewExpsessionID] = useState<any>();
  const [newtrainingsectionID, setNewtrainingsectionID] = useState<any>();

  const { experienceID } = useParams();
  const experienceIDNumber = Number(experienceID) || -1;
  const [hasModalBeenShown, setHasModalBeenShown] = useState(false);

  const navigate = useNavigate();
  const dp = localStorage.getItem("displayPicture");
  const [isRunFirstTime, setIsRunFirstTime] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [savedProgress, setSavedProgress] = useState<SavedProgress | null>(
    null
  ); // State to track saved progress
  const [hasSavedProgress, setHasSavedProgress] = useState(false); // New state
  const paramsnum = useParams();
  console.log("")
 
  const saveProgress = () => {
    if (currentExperience && currentNode) {
      localStorage.setItem(
        `experience_${currentExperience.ExperienceID}_progress`,
        JSON.stringify({
          nodeID: currentNode.SectionObjectID,
          experienceID: currentExperience.ExperienceID,
        })
      );
    }
  };

  useEffect(() => {
    if (currentNode && isRunFirstTime && !hasModalBeenShown) {
      if (currentNode.SOTypeID === SOTypeID.StartNode) {
        setIsModalOpen(true); // Show the modal when the start node is reached
        setHasModalBeenShown(true); // Set the flag to true so the modal is not shown again
      }
    }
  }, [currentNode, isRunFirstTime, hasModalBeenShown]);

  const handleStartTraining = (resume = false) => {
    if (resume && savedProgress && savedProgress.nodeID) {
      const savedNode = findNodeByID(
        currentExperience?.rolePlaySectionObjects || [],
        savedProgress.nodeID
      );
      if (savedNode) {
        setCurrentNode(savedNode);
      }
    } else if (mainExperience) {
      const startNode = getStartNodeForExperience(mainExperience);
      setCurrentNode(startNode);
    }

    setIsModalOpen(false); // Close the modal
    setHasModalBeenShown(true); // Prevent the modal from showing again
  };

  const { width, height } = useWindowSize();

  useEffect(() => {
    let isMounted = true; // To track if the component is still mounted

    const fetchExperiences = async () => {
      setLoading(true);
      try {
        const response = await getExperienceData(experienceIDNumber);

        if (isMounted && response.code === 200) {
          const responseData: ExperienceResponseData = response.data;

          const mainTsid = responseData.responseData.MAIN_TSID;

          const mainExperienceData = findExperienceByExperienceID(
            responseData.responseData.Experiences,
            Number(mainTsid)
          );

          setExperiences(responseData.responseData.Experiences);
          setMainExperience(mainExperienceData);
          setCurrentExperience(mainExperienceData);

          const savedProgress = JSON.parse(
            localStorage.getItem(
              `experience_${experienceIDNumber}_progress`
            ) || "{}"
          );

          if (
            savedProgress.nodeID &&
            savedProgress.experienceID === experienceIDNumber
          ) {
            // setHasSavedProgress(true); // Set flag for saved progress
            setIsModalOpen(true) ; // Open resume/start modal
            // setSavedProgress(savedProgress);
          } else if (mainExperienceData) {
            const currentNodeData =
              getStartNodeForExperience(mainExperienceData);
            setCurrentNode(currentNodeData);
            setIsModalOpen(true); // Show welcome message
          }

          setIsRunFirstTime(true);
        } else {
          console.error("Failed to fetch experiences:", response.message);
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching experiences:", error);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchExperiences();

    return () => {
      isMounted = false; // Cleanup function to set isMounted to false when the component unmounts
    };
  }, [experienceIDNumber]);
  useEffect(() => {
    const fetchData = async () => {
      if (currentNode) {
        // Reset nodeRenderDetails before setting new data
        setNodeRenderDetails(defalutNodeRenderDetails);

        if (
          currentNode.SOTypeID === SOTypeID.StartNode &&
          currentExperience?.rolePlaySectionObjects
        ) {
          const nextNode = findNextNode(
            currentExperience.rolePlaySectionObjects,
            currentNode
          );
          if (nextNode) {
            setCurrentNode(nextNode);
          }
        } else if (currentNode.SOTypeID === SOTypeID.StepNode) {
          let nodeDataToSet = { ...defalutNodeRenderDetails };

          let rolePlayStepByStepMediaFile = findMediaFileByDisplayTemplateID(
            currentNode.mediaFiles,
            DisplayTemplate.RolePlayStepByStep
          );
          if (rolePlayStepByStepMediaFile) {
            let rolePlayStepByStepTextData =
              getRolePlayStepByStepTextData(rolePlayStepByStepMediaFile);
            nodeDataToSet.title = rolePlayStepByStepTextData.Title;
          }

          let audioTextMediaFile = findMediaFileByDisplayTemplateID(
            currentNode.mediaFiles,
            DisplayTemplate.AudioText
          );
          if (audioTextMediaFile) {
            let audioTextTextData = getAudioTextTextData(audioTextMediaFile);
            nodeDataToSet.text = audioTextTextData.DisplayText;
            nodeDataToSet.audio =
              "https://altouraonyx.azureedge.net" +
              audioTextMediaFile.MediaFilePath;
          }

          let rolePlayVideoMediaFile = findMediaFileByDisplayTemplateID(
            currentNode.mediaFiles,
            DisplayTemplate.RolePlayVideo
          );
          if (rolePlayVideoMediaFile) {
            let MediaFilePath = getMediaFilePath(rolePlayVideoMediaFile);
            nodeDataToSet.video = MediaFilePath;
          }

          let rolePlayDisplayImageMediaFile =
            findMediaFileByDisplayTemplateID(
              currentNode.mediaFiles,
              DisplayTemplate.RolePlayDisplayImage
            );
          if (nodeDataToSet.video === "" && rolePlayDisplayImageMediaFile) {
            let MediaFilePath = getMediaFilePath(rolePlayDisplayImageMediaFile);
            nodeDataToSet.image = MediaFilePath;
          }
          nodeDataToSet.sectionObjectName = currentNode.SectionObjectName;

          nodeDataToSet.onNext = onNextClick;
          nodeDataToSet.onBack = onBackClick;
          nodeDataToSet.onAnswerSelect = onAnswerSelect;

          setNodeRenderDetails(nodeDataToSet);
        } else if (currentNode.SOTypeID === SOTypeID.LinkNode) {
          const nextExperienceMediafileData = findMediaFileByDisplayTemplateID(
            currentNode.mediaFiles,
            DisplayTemplate.LinkedNode
          );

          if (nextExperienceMediafileData && currentExperience) {
            linkStack.push({
              node: currentNode,
              ExperienceID: currentExperience?.ExperienceID,
            });

            const nextExperienceData =
              getLinkedNodeData(nextExperienceMediafileData);
            const ExperienceData = findExperienceByExperienceID(
              Experiences,
              Number(nextExperienceData.TrainingSectionID)
            );
            try {
              console.log("Fetching last session status...");
              
            } catch (error) {
              console.error("Error fetching last session status:", error);
            }
            setCurrentExperience(ExperienceData);
            if (ExperienceData) {
              const currentNodeData =
                getStartNodeForExperience(ExperienceData);
              setCurrentNode(currentNodeData);
            }
          }
        } else if (currentNode.SOTypeID === SOTypeID.ChoiceNode) {
          let nodeDataToSet: NodeRenderDetails = {
            ...defalutNodeRenderDetails,
          };

          let rolePlayChoiceTextDataMediaFile =
            findMediaFileByDisplayTemplateID(
              currentNode.mediaFiles,
              DisplayTemplate.RolePlayChoice
            );
          if (rolePlayChoiceTextDataMediaFile) {
            let rolePlayChoiceTextData =
              getRolePlayChoiceTextData(rolePlayChoiceTextDataMediaFile);
            nodeDataToSet.title = rolePlayChoiceTextData.HeaderText;
            nodeDataToSet.text = rolePlayChoiceTextData.QuestionText;
            nodeDataToSet.options =
              rolePlayChoiceTextDataMediaFile.Annotations;
          }

          setNodeRenderDetails(nodeDataToSet);
        }
      }
    };

    fetchData();
  }, [currentNode, currentExperience, Experiences]);

  useEffect(() => {
    if (nodeRenderDetails.audio) {
      setAudioUrl(nodeRenderDetails.audio);
    } else {
      setAudioUrl(
        "https://altouraonyx.azureedge.net/altouradevcontainer/blank.mp3"
      );
    }
  }, [nodeRenderDetails]);

  const onNextClick = async () => {
    if (currentExperience && currentNode) {
      let nextNode: RolePlaySectionObject | null = null;
      
      const expid = await CreateExperienceSession();

      const path = await GetExperienceSessionActivePath(
        expid.data.ExperienceSessionID
      );
      console.log("path");
      console.log(path);
      const res = await GetExperienceSessionPath(
        expid.data.ExperienceSessionID,
        currentNode.SectionObjectID
      );
      console.log("path");
      console.log(res);
      const lastexpsession = await GetLastExperienceSessionStatus(paramsnum);
      console.log("lastexpsession");
      console.log(lastexpsession);
      const sendres = await SendUpdateExperienceSessionMode();
      console.log("sendres");
      console.log(sendres);
      const addExperienceSessionEndTime = await AddExperienceSessionEndTime();
      const addExperienceSessionPath = await AddExperienceSessionPath();
      // const addUsersUnderExperienceSession = await AddUsersUnderExperienceSession();
      console.log("addExperienceSessionEndTime");
      console.log(addExperienceSessionEndTime);
      console.log(addExperienceSessionPath);
      // console.log("addUsersUnderExperienceSession");
      // console.log(addUsersUnderExperienceSession);

      if (currentNode.SOTypeID == SOTypeID.StepNode) {
        nextNode = findNextNode(
          currentExperience.rolePlaySectionObjects,
          currentNode
        );
        if (nextNode == null && !linkStack.isEmpty()) {
          const linkNode: pathFragment | undefined = linkStack.pop();
          if (linkNode) {
            let ExperienceOfLinkNode = findExperienceByExperienceID(
              Experiences,
              linkNode.ExperienceID
            );
            setCurrentExperience(ExperienceOfLinkNode);

            if (ExperienceOfLinkNode)
              nextNode = findNextNode(
                ExperienceOfLinkNode.rolePlaySectionObjects,
                linkNode.node
              );
          }
        }
      }

      if (nextNode) {
        pathStack.push({
          node: currentNode,
          ExperienceID: currentExperience.ExperienceID,
        });
        setCurrentNode(nextNode);
      }
      if (currentNode.DisplayOrder == 0 && linkStack.isEmpty()) {
        setCompleted(true);
        nodeRenderDetails.isCompleted = true;
      }

      // saveProgress(); // Save progress when navigating to the next node
    }
  };

  useEffect(() => {
    // Cleanup function to trigger SendResumeExperience on component unmount
    return () => {
      SendResumeExperience();
    };
  }, []); // Empty dependency array ensures it runs only on mount and unmount

  const debouncedOnNextClick = useCallback(
    debounce(() => onNextClick(), 30),
    [onNextClick]
  );

  const onBackClick = () => {
    if (currentExperience && currentNode && pathStack.size() != 0) {
      const nextNodeData: pathFragment | undefined = pathStack.pop();
      if (nextNodeData != undefined) {
        const nextNode = nextNodeData.node;
        setCurrentExperience(
          findExperienceByExperienceID(
            Experiences,
            nextNodeData.ExperienceID
          )
        );

        if (nextNode) {
          setCurrentNode(nextNode);
        }
      }

      // saveProgress(); // Save progress when navigating back
    }
  };

  const debouncedOnBackClick = useCallback(
    debounce(() => onBackClick(), 300),
    [onBackClick]
  );

  const onAnswerSelect = (SectionObjectLinkID: number) => {
    if (currentExperience && currentNode) {
      const nextNode: RolePlaySectionObject | null = findNodeByID(
        currentExperience.rolePlaySectionObjects,
        SectionObjectLinkID
      );
      console.log("nextnode");
      console.log(nextNode);
      if (nextNode) {
        pathStack.push({
          node: currentNode,
          ExperienceID: currentExperience.ExperienceID,
        });
        console.log("pathStack");
        console.log(pathStack);

        setCurrentNode(nextNode);
        // saveProgress(); // Save progress when an answer is selected
      }
    }
  };
  const updateCurrentSlide = (newStepIndex: any) => {
    setCurrentStep(newStepIndex);
  };
  const onPrevious = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };
  const debouncedOnPrevious = useCallback(
    debounce(
      () => onPrevious(),
      300 // Delay in ms
    ),
    [onPrevious]
  ); // Dependency array

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight + 100,
      });
    };

    window.addEventListener("resize", updateDimensions);
    updateDimensions();

    return () =>
      window.removeEventListener("resize", updateDimensions);
  }, []);

  const onRetry = () => {
    if (mainExperience) {
      const startNode = getStartNodeForExperience(mainExperience);
      setCurrentStep(0);
      setCurrentNode(startNode);
      setCurrentExperience(mainExperience);
      setUserAnswers({});
      setCompleted(false);
    }
  };

  const [list, setList] = useState<Organization[]>([]);
  const [projresp, setProjresp] = useState<any>([]);
  const [clickedOption, setClickedOption] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectResponse = await listProjectsAndOrgs(
          parseInt(localStorage.getItem("userID") || "-1")
        );
        setProjresp(projectResponse);
        if (
          projectResponse.orgsList.length === 0 ||
          projectResponse.data.length === 0
        ) {
          throw new Error("Empty data array received from API");
        }
        setList(projectResponse.orgsList);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const handleOptionClick = (optionIndex: number) => {
    setClickedOption(optionIndex); // Temporarily set the clicked option to show blue border

    // After a short delay, revert the border to white
    setTimeout(() => {
      setClickedOption(null);
      setSelectedOption(optionIndex); // Option remains selected but with white border
    }, 1000);
  };

  const isFirstStep =
    currentExperience && currentNode
      ? currentExperience.rolePlaySectionObjects.findIndex(
          (obj) => obj.DisplayOrder === currentNode.DisplayOrder
        ) === 1
      : true;

  const isLastStep = currentNode ? currentNode.DisplayOrder === -1 : true;

  if (loading) {
    return (
      <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-r from-white overflow-hidden">
        <div className="flex flex-col items-center">
          <HashLoader color="#4295e7" loading={true} />
          <div className="text-2xl mt-4 text-gray-800 font-bold">
            <img src={altourablue} />
          </div>
        </div>
      </div>
    );
  }

  if (completed) {
    return (
      <>
        <Header
          data={""}
          list={list}
          onOrgChange={() => {}}
          isDropdownDisabled={true}
          projectResponse={projresp}
          displaypic={dp}
        />
        <div className="overflow-hidden flex justify-center items-center min-h-screen bg-gray-100">
          <div className="overflow-hidden mt-[8vh] h-[742px] min-w-[572px] max-w-[592px] relative bg-white rounded-xl shadow px-4 py-8">
            <div className="confetti-wrapper absolute top-0 left-0 w-full h-[800px] overflow-hidden z-0 pointer-events-none">
              <Confetti width={dimensions.width} height={dimensions.height} />
            </div>
            <div className="flex flex-col justify-center items-center gap-6 z-0 relative">
              <div className="relative flex flex-col justify-center items-center p-4">
                <img
                  src={winningicon}
                  alt=""
                  className="w-[407px] h-[349px] mt-[4vh]"
                />
              </div>

              <div className="text-center text-zinc-800 text-[24px] mt-[-5vh]">
                Congratulations!!
              </div>
              <div className="w-[95%] z-0 bg-white text-gray-500 text-[53px] md:text-sm leading-relaxed px-2 md:text-left text-center">
                You have successfully completed the experience{" "}
                <b>
                  <span className="text-zinc-800">
                    {mainExperience?.ExperienceName ?? ""}.{" "}
                  </span>{" "}
                </b>{" "}
                <br /> Click continue to go to Altoura home or click retry to
                try the experience again.
              </div>

              <div className="flex flex-col justify-center items-center gap-4 w-full px-4 sm:px-0">
                <button
                  className="w-full sm:w-[95%] h-[40px] px-6 py-3 bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] rounded-md flex justify-center items-center text-white text-base font-semibold"
                  style={{ fontFamily: "'Selawk'" }}
                  onClick={() => navigate("/")}
                >
                  Continue
                </button>

                <button
                  className="w-full sm:w-[95%] h-[40px] px-6 py-3 bg-white text-blue-500 border border-blue-500 rounded-md flex justify-center items-center text-base font-semibold hover:bg-[#f2f9fe] active:bg-[#e0f2ff]"
                  style={{ fontFamily: "'Selawk'" }}
                  onClick={onRetry}
                >
                  Retry
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header
        data={""}
        list={list}
        onOrgChange={() => {}}
        isDropdownDisabled={true}
        projectResponse={projresp}
        displaypic={dp}
      />
      <div className="main-container flex flex-col w-full">
        <div className="flex flex-col md:flex-row justify-center items-center mt-1 w-full">
          <div className="font-selwak flex flex-col items-center justify-center w-full">
            <div className="fixed right-3 top-14 z-50 sm:right-3.5 sm:top-20"></div>
            <div className="flex justify-center w-full">
              <div className="flex flex-col lg:flex-row mx-auto max-w-5xl w-full">
                <div className="w-full">
                  <div className="pt-[8vh] mb-[-220vh] ml-20 flex justify-center items-center gap-2 inline-flex w-full">
                    <div className="flex grow justify-start items-center gap-4">
                      <div className="flex justify-start items-center gap-2.5">
                        <div className="text-center text-stone-900 text-2xl font-normal leading-[42.72px]">
                          {mainExperience?.ExperienceName}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap justify-start items-center gap-1 mr-[18vh] min-h-[4rem]">
                      <div className="pl-2 pr-4 py-2 rounded flex justify-center items-center gap-1">
                        <div
                          className="w-6 h-6 flex justify-center items-center"
                          onClick={() => {}}
                        >
                          <div className="w-6 h-6 relative">
                            <img src={""} alt=""></img>
                          </div>
                        </div>
                      </div>
                      <div
                        className="pl-2 hover:bg-gray-200 pr-4 py-2 rounded flex justify-center items-center gap-1 cursor-pointer"
                        onClick={() => {
                          navigate(`/experience/${experienceID}/edit`);
                        }}
                      >
                        <div className="w-6 h-6 mt-1 relative">
                          <svg
                            width="22"
                            height="23"
                            viewBox="0 0 32 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_1_678)">
                              <path
                                d="M8.1875 19.9L19.4 8.6875L23.8125 13.1L12.5937 24.3188L8.23125 24.3"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                              />
                              <path
                                d="M8.2375 24.2937L8.1875 19.9"
                                stroke="black"
                                strokeWidth="2"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1_678">
                                <rect
                                  width="32"
                                  height="32"
                                  fill="white"
                                  transform="translate(0 0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>
                        <div className="text-center text-stone-900 text-base font-normal leading-normal hover:bg-gray-200">
                          Edit experience
                        </div>
                      </div>

                      <div
                        className="pl-2 pr-4 py-2 hover:bg-gray-200 rounded flex justify-center items-center gap-1"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        <div className="w-6 h-6 flex justify-center items-center">
                          <div className="w-6 h-6 relative">
                            <img src={close} alt=""></img>
                          </div>
                        </div>
                        <div className="text-center text-stone-900 text-base font-normal leading-normal cursor-pointer" onClick={()=>handleStartTraining()}>
                          Close
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-[-8vh]">
                    <StepNavigation
                      onPrevious={() => debouncedOnBackClick()}
                      onNext={() => debouncedOnNextClick()}
                      title={nodeRenderDetails.title}
                      isFirstStep={isFirstStep}
                      isLastStep={isLastStep}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center w-full">
              <div className="flex flex-col lg:flex-row mx-auto max-w-4xl w-full">
                {nodeRenderDetails.video === "" && nodeRenderDetails.image && (
                  <div
                    className="mr-5 flex flex-col justify-center items-center overflow-y rounded-lg border-b border-transparent px-8 gap-2 bg-white w-full lg:w-[910px] h-[90vh] md:h-[68vh] mb-4 ml-4"
                    style={{
                      boxShadow:
                        "0 6px 12px rgba(0, 0, 0, 0.08), 0 5px 15px rgba(0, 0, 0, 0.08)",
                      boxSizing: "border-box",
                      overflowY: "auto",
                    }}
                  >
                    <img
                      src={
                        "https://altouraonyx.azureedge.net" +
                        nodeRenderDetails.image
                      }
                      alt="image"
                      className="object-contain"
                      style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                      }}
                    />
                  </div>
                )}
                {nodeRenderDetails.video && (
                  <div
                    className=" ml-[2vh] flex flex-col justify-center items-center overflow-hidden rounded-lg border-b border-transparent px-8 gap-2 bg-white w-full lg:w-[910px] h-[90vh] md:h-[68vh] mb-4 ml-2"
                    style={{
                      boxShadow:
                        "0 6px 12px rgba(0, 0, 0, 0.08), 0 5px 15px rgba(0, 0, 0, 0.08)",
                      boxSizing: "border-box",
                    }}
                  >
                    <Player
                      playsInline
                      //poster="/assets/poster.png"
                      src={nodeRenderDetails.video}
                    >
                      <BigPlayButton position="center" />
                    </Player>
                  </div>
                )}

                <div
                  className="mr-5 flex flex-col overflow-y rounded-lg border-b border-transparent px-8 gap-2 bg-white w-full lg:w-[910px] h-[90vh] md:h-[68vh] mb-4 ml-4"
                  style={{
                    boxShadow:
                      "0 6px 12px rgba(0, 0, 0, 0.08), 0 5px 15px rgba(0, 0, 0, 0.08)",
                    boxSizing: "border-box",
                    overflowY: "auto",
                  }}
                >
                  {nodeRenderDetails.options &&
                  nodeRenderDetails.options.length > 0 ? (
                    <>
                      <div className="p-8 pl-0 flex flex-col text-zinc-800 text-lg font-normal leading-relaxed">
                        {nodeRenderDetails.text}
                      </div>
                      <div className="flex flex-col justify-start items-start gap-4 w-full">
                        <div className="flex flex-col text-zinc-800 text-lg font-normal leading-relaxed items-start justify-start">
                          Choose one of the following options.
                        </div>
                        <div className="flex flex-col justify-start items-center gap-3 w-full">
                          {nodeRenderDetails.options.map(
                            (option, optionIndex) => (
                              <label
                                key={optionIndex}
                                className={`w-full h-auto sm:h-14 p-4 bg-white rounded-lg border justify-start items-center gap-4 inline-flex cursor-pointer ${
                                  clickedOption === optionIndex
                                    ? "border-[#4295e7]" // Show blue border when clicked
                                    : selectedOption === optionIndex
                                    ? "border-white" // White border if selected after click
                                    : hoveredOption === optionIndex
                                    ? "border-[#e2ecf5]" // Light blue border on hover
                                    : "border-white" // Default white border
                                } hover:border-[#e2ecf5]`}
                                onMouseEnter={() =>
                                  setHoveredOption(optionIndex)
                                }
                                onMouseLeave={() => setHoveredOption(null)}
                                onClick={() => handleOptionClick(optionIndex)}
                              >
                                <input
                                  type="radio"
                                  name={`quiz_${optionIndex}`}
                                  value={option.TextContent}
                                  onChange={() =>
                                    onAnswerSelect(option.SectionObjectLinkID)
                                  }
                                  className="opacity-0 absolute"
                                />
                                <div className="w-6 h-6 rounded-full border border-zinc-800 flex justify-center items-center">
                                  <div
                                    className={`w-4 h-4 rounded-full ${
                                      selectedOption === optionIndex
                                        ? "bg-[#4295e7]"
                                        : ""
                                    }`}
                                  ></div>
                                </div>
                                <div className="grow shrink basis-0 self-stretch text-zinc-800 text-lg font-normal leading-relaxed truncate">
                                  {option.TextContent}
                                </div>
                              </label>
                            )
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    nodeRenderDetails.text && (
                      <div className="w-full mt-10">
                        <div className="h-full text-[#1F1F1F] text-lg font-normal leading-relaxed">
                          {nodeRenderDetails.text}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <StartTrainingModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onStart={() => handleStartTraining(false)} // Start from the first step
        onResume={() => handleStartTraining(true)} // Resume from the saved step
        hasSavedProgress={hasSavedProgress} // Pass the new prop
      />
    </>
  );
};

export default ExperienceComponent;
