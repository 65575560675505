import React, { useEffect, useRef } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { Vector3 } from 'three';
import { useSceneControl } from '../Providers/SceneContextProvider';


const KeyboardControls: React.FC = () => {
  const { camera } = useThree();
  const { yaw, pitch,isInteracting } = useSceneControl();
  const velocity = useRef(new Vector3(0, 0, 0));
  const direction = useRef(new Vector3(0, 0, 0));
  const speed = 5;
  const fixedY = useRef(1.6);

  const keys = useRef({
    w: false,
    a: false,
    s: false,
    d: false,
    q: false,
    e: false,
    arrowUp: false,
    arrowDown: false,
    arrowLeft: false,
    arrowRight: false,
  });

  const onKeyDown = (event: KeyboardEvent) => {
    switch (event.code) {
      case 'KeyW':
        keys.current.w = true;
        break;
      case 'KeyA':
        keys.current.a = true;
        break;
      case 'KeyS':
        keys.current.s = true;
        break;
      case 'KeyD':
        keys.current.d = true;
        break;
      case 'KeyQ':
        keys.current.q = true;
        break;
      case 'KeyE':
        keys.current.e = true;
        break;
      case 'ArrowUp':
        keys.current.arrowUp = true;
        break;
      case 'ArrowDown':
        keys.current.arrowDown = true;
        break;
      case 'ArrowLeft':
        keys.current.arrowLeft = true;
        break;
      case 'ArrowRight':
        keys.current.arrowRight = true;
        break;
    }
  };

  const onKeyUp = (event: KeyboardEvent) => {
    switch (event.code) {
      case 'KeyW':
        keys.current.w = false;
        break;
      case 'KeyA':
        keys.current.a = false;
        break;
      case 'KeyS':
        keys.current.s = false;
        break;
      case 'KeyD':
        keys.current.d = false;
        break;
      case 'KeyQ':
        keys.current.q = false;
        break;
      case 'KeyE':
        keys.current.e = false;
        break;
      case 'ArrowUp':
        keys.current.arrowUp = false;
        break;
      case 'ArrowDown':
        keys.current.arrowDown = false;
        break;
      case 'ArrowLeft':
        keys.current.arrowLeft = false;
        break;
      case 'ArrowRight':
        keys.current.arrowRight = false;
        break;
    }
  };

  const onWheel = (event: WheelEvent) => {
    if (event.deltaY < 0) {
      // Scroll up
      keys.current.w = true;
      keys.current.s = false;
    } else if (event.deltaY > 0) {
      // Scroll down
      keys.current.s = true;
      keys.current.w = false;
    }

    // Stop movement after a short delay
    setTimeout(() => {
      keys.current.w = false;
      keys.current.s = false;
    }, 100);
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);
    document.addEventListener('wheel', onWheel);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
      document.removeEventListener('wheel', onWheel);
    };
  }, []);

  useFrame((state, delta) => {
    if(isInteracting.current==true)
        return;


    direction.current.set(0, 0, 0);
    if (keys.current.w) direction.current.z -= 1;
    if (keys.current.s) direction.current.z += 1;
    if (keys.current.a) direction.current.x -= 1;
    if (keys.current.d) direction.current.x += 1;
    if (keys.current.q) fixedY.current += .05;
    if (keys.current.e) fixedY.current -= .05;

    direction.current.normalize();
    direction.current.applyEuler(camera.rotation); // Apply the camera's rotation to the direction

    velocity.current.copy(direction.current).multiplyScalar(speed * delta);

    camera.position.add(velocity.current);
    camera.position.y = fixedY.current; // Lock the camera's y-position to 1.6 meters

    // Prevent roll
    camera.rotation.z = 0;

    // Update yaw and pitch for arrow key controls
    if (keys.current.arrowLeft) {
      yaw.current += 0.02;
    }
    if (keys.current.arrowRight) {
      yaw.current -= 0.02;
    }
    if (keys.current.arrowUp) {
      pitch.current = Math.min(Math.PI / 2, pitch.current + 0.02);
    }
    if (keys.current.arrowDown) {
      pitch.current = Math.max(-Math.PI / 2, pitch.current - 0.02);
    }

    // Apply the yaw and pitch rotations to the camera
    camera.rotation.set(pitch.current, yaw.current, 0);
  });

  return null;
};

export default KeyboardControls;