import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import { Mesh, Raycaster, Vector2, Vector3,AudioListener } from 'three';
import { GridGround } from '../Environment/GridGround';
import TextPanel from '../UIComponents/Panels/TextPanel';
import { Physics } from '@react-three/cannon';
import CameraControls from '../PlayerControls/CameraControls';
import KeyboardControls from '../PlayerControls/KeyboardControls';
import './Scene.css';
import { NodeRenderDetails } from '../../../types/nodeDataTypes';
import DottedBezierLine from '../UIComponents/Spline/DottedSpline';
import Confetti from '../UIComponents/Spline/Confetti';
import ExplosionConfetti from '../UIComponents/Spline/Confetti';
import GLBModelViewer from './Loaders/GLBModelViewer';
import { Html, useProgress } from '@react-three/drei';
import LoaderCube from './Loaders/LoaderCube';
import { AssetData, SpaceObject } from '../../../types/spaceTypes';
import GLBModelViewerSpaceObject from './Loaders/GLBModelViewerSpaceObject';
import HdrEnvironment from './Loaders/HdrEnvironment';
import hdrPath from '../../../assessts/environment/autoshop_01_1k.hdr'
import { HierarchyView, SceneExtractor, SceneNode } from '../UIComponents/SceneTools/SceneHierarchy';
import { GetObjectAndTags } from '../../../services/experienceService';
import { listProjectsAndOrgs } from '../../../services/projectService';
import { Organization } from '../../../types/projectTypes';
import { ObjectData } from '../../../types/objectTypes';
import HorizontalScrollableList from './components/HorizontalScrollbar';
import { SceneContextProvider } from '../Providers/SceneContextProvider';
import ObjectSpawner from './Loaders/ObjectSpawner';
import { CreateObjects } from '../../../services/experienceService';

const CameraSetup: React.FC = () => {
  const { camera } = useThree();

  useEffect(() => {
    console.log("CameraSetup is called");
    camera.position.set(0, 1.6, 3); // Set the camera height to 1.6 meters
    const listener = new AudioListener();
    camera.add(listener);
    
  }, []); // Empty dependency array ensures this runs only once

  return null;
};



interface SceneProps{
  spaceobjects:AssetData|undefined;
}

export const SceneEdit:React.FC<SceneProps> = ({spaceobjects}) => {


  const [cubePosition, setCubePosition] = useState(new Vector3(0, 0, 0));
  const [panelPosition, setpanelPosition] = useState(new Vector3(0, 1.6, 0));

  const [isLoading, setIsLoading] = useState(true);
  const [sceneData, setSceneData] = useState<SceneNode | null>(null);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState<Organization[]>([]);
  const [error, setError] = useState("");
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
  const [objects, setobjects] = useState<any>([]);
  const [filteredobjects, setFilteredobjects] = useState<ObjectData[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [totalobjects, setTotalobjects] = useState(20);
  const [isFocused, setIsFocused] = useState(false);
  const [sortType, setSortType] = useState<string>("latest");
  const [tagsdata , setTagsData ] = useState<any>();
  const [objresponse  , setObjresponse] = useState<any>();
  const [searchFilteredobjects, setSearchFilteredobjects] = useState<
    ObjectData[]
  >([]);


  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);
  const inputRef = useRef<HTMLInputElement>(null);



  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const userId = parseInt(localStorage.getItem("userID") || "-1");
  
        const projectResponse = await listProjectsAndOrgs(userId);
        console.log("projectResponse");
        console.log(projectResponse);
  
        if (projectResponse.orgsList.length === 0 || projectResponse.data.length === 0) {
          throw new Error("Empty data array received from API");
        }
  
        setList(projectResponse.orgsList);
        const projectIDs = projectResponse.data.flatMap((project) =>
          project.projectSections.map((section) => section.ProjectID)
        );
  
        if (projectIDs.length === 0) {
          throw new Error("No project section IDs found");
        }
  
        const storedOrgId = localStorage.getItem("selectedOrgId");
        if (storedOrgId) {
          const orgId = parseInt(storedOrgId, 10);
          setSelectedOrgId(orgId);
  
          const filteredProjectsData = projectResponse.data.filter(
            (project) => project.OrganizationID === orgId
          );
  
          if (filteredProjectsData.length === 0) {
            throw new Error("No projects found for the selected organization");
          }
  
          const projectID = filteredProjectsData[0].projectSections[0].ProjectID;
  
          const ObjsResponse = await GetObjectAndTags(projectID);
          setobjects(ObjsResponse.data.objects);
          setTagsData(ObjsResponse.data.tags);
          setObjresponse(ObjsResponse.data);
          console.log(ObjsResponse.data.tags);
  
          setFilteredobjects(ObjsResponse.data.objects);
          setSearchFilteredobjects(ObjsResponse.data.objects);
        } else {
          const projectID = projectIDs[0];
          const ObjsResponse = await GetObjectAndTags(projectID);
          setobjects(ObjsResponse.data.objects);
          setTagsData(ObjsResponse.data.tags);
          setObjresponse(ObjsResponse.data);
          console.log(ObjsResponse.data.tags);
  
          setFilteredobjects(ObjsResponse.data.objects);
          setSearchFilteredobjects(ObjsResponse.data.objects);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load projects.");
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [setLoading, setList, setobjects, setTagsData, setFilteredobjects, setSearchFilteredobjects, setSelectedOrgId, setObjresponse]);
  

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
            <SceneContextProvider>
      <Canvas onCreated={() => setIsLoading(false)}>

      <Suspense fallback={null}>
        <HdrEnvironment hdrPath={hdrPath} />
      </Suspense>

        <color attach="background" args={["#404040"]} />
        <CameraSetup />
        <directionalLight position={[2.5, 8, 5]} ></directionalLight>
        <ambientLight intensity={0.5} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <pointLight position={[-10, -10, -10]} />
        <CameraControls />
        <KeyboardControls />
        <Suspense fallback={null}>
          <Physics>
            <GridGround />
          </Physics>
        </Suspense>
        {/* <RaycastManager /> */}

        {spaceobjects && (spaceobjects?.spaceObjects.map((spaceObject: SpaceObject) => (   <Suspense fallback={<LoaderCube position={[0,0,0]} />}><GLBModelViewerSpaceObject SpaceObject={spaceObject} /></Suspense>)))}


        

          
        <SceneExtractor onChange={setSceneData} />

        <ObjectSpawner />

      </Canvas>
      <HorizontalScrollableList objects={objects} />

      <div style={{ position: 'absolute', top: 10, right: 10 }}>
        <HierarchyView data={sceneData} />
      </div>
      </SceneContextProvider>
    </div>
  );
};


//   const { camera, scene } = useThree();
//   const raycaster = useRef(new Raycaster());
//   const mouse = useRef(new Vector2());

//   const handleMouseClick = (event: MouseEvent) => {
//     if (!document.pointerLockElement) return;

//     mouse.current.x = 0; // Center of the screen
//     mouse.current.y = 0; // Center of the screen

//     raycaster.current.setFromCamera(mouse.current, camera);

//     const intersects = raycaster.current.intersectObjects(scene.children, true);
//     if (intersects.length > 0) {
//       const object = intersects[0].object as Mesh;
//       if (object.userData && object.userData.onClick) {
//         object.userData.onClick();
//       }
//     }
//   };

//   useEffect(() => {
//     document.addEventListener('click', handleMouseClick);
//     return () => {
//       document.removeEventListener('click', handleMouseClick);
//     };
//   }, []);

//   return null;
// };