import React, { useState } from "react";
import { Project } from "../../../../types/projectTypes";
import "./ProjectCardComponent.css";

export const ProjectCardComponent: React.FC<{ project: Project }> = ({
  project,
}) => {
  // Generate SVG for the logo
  const maxLength = 17;
  const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
  };

  return (
    <>
      <div
        className={`w-[196px] h-[196px] rounded-xl border-[1px] border-[#e2ecf5] justify-center items-center inline-flex overflow-hidden hover:border-[2px] hover:border-[#e2ecf5] transition-all duration-300 ease-in-out ${
          isClicked ? "border-blue-500" : ""
        }`}
        onClick={handleClick}
      >
        <img
          className="w-[196px] h-[196px] rounded-xl transition-transform duration-300 ease-in-out hover:scale-105 z-[1]"
          src={project.Thumbnail ? `https://altouraonyx.azureedge.net${project.Thumbnail}`: `https://altouraonyx.azureedge.net/altouradevcontainer/ProjectThumbnails/Default_Thumbnail/ico_default_thumbnail.png`}

          alt="Gallery"
        />
      </div>

      <div className="w-20 pt-0 p-3.5 bg-white justify-start items-start flex">
        <div className="w-64 h-[35px] justify-start items-start gap-2.5 inline-flex">
          <div className="text-center text-l font-normal font-['Selawk'] leading-[29px] whitespace-nowrap">
            {truncateText(project.ProjectName, maxLength)}
          </div>
        </div>
      </div>
    </>
  );
};
