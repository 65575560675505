import React, { useEffect, useRef, useCallback } from 'react';
import { useThree } from '@react-three/fiber';
import { useSceneControl } from '../Providers/SceneContextProvider';

const CameraControls: React.FC = () => {
  const { camera } = useThree();
  const { yaw, pitch, isInteracting } = useSceneControl();
  const isDragging = useRef(false);
  const previousMousePosition = useRef({ x: 0, y: 0 });

  useEffect(() => {
    camera.rotation.order = 'YXZ';
  }, [camera]);

  const handlePointerDown = useCallback((event: PointerEvent) => {
    if (event.button !== 0 || isInteracting.current) return;
    isDragging.current = true;
    previousMousePosition.current = { x: event.clientX, y: event.clientY };
    (event.target as HTMLElement).setPointerCapture(event.pointerId);
  }, [isInteracting]);

  const handlePointerUp = useCallback((event: PointerEvent) => {
    isDragging.current = false;
    (event.target as HTMLElement).releasePointerCapture(event.pointerId);
  }, []);

  const handlePointerMove = useCallback((event: PointerEvent) => {
    if (!isDragging.current || isInteracting.current) return;

    const deltaX = event.clientX - previousMousePosition.current.x;
    const deltaY = event.clientY - previousMousePosition.current.y;
    previousMousePosition.current = { x: event.clientX, y: event.clientY };

    yaw.current -= deltaX * 0.002;
    pitch.current = Math.max(-Math.PI / 2, Math.min(Math.PI / 2, pitch.current - deltaY * 0.002));

    camera.rotation.set(pitch.current, yaw.current, 0);
  }, [isInteracting, yaw, pitch, camera]);

  useEffect(() => {
    const canvas = document.querySelector('canvas');
    if (!canvas) return;

    const handleEvents = (e: Event) => {
      if (e instanceof PointerEvent) {
        switch (e.type) {
          case 'pointerdown':
            handlePointerDown(e);
            break;
          case 'pointerup':
            handlePointerUp(e);
            break;
          case 'pointermove':
            handlePointerMove(e);
            break;
        }
      }
    };

    canvas.addEventListener('pointerdown', handleEvents);
    canvas.addEventListener('pointerup', handleEvents);
    canvas.addEventListener('pointermove', handleEvents);

    return () => {
      canvas.removeEventListener('pointerdown', handleEvents);
      canvas.removeEventListener('pointerup', handleEvents);
      canvas.removeEventListener('pointermove', handleEvents);
    };
  }, [handlePointerDown, handlePointerUp, handlePointerMove]);

  return null;
};

export default CameraControls;