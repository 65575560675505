// DottedBezierLine.tsx
import React from 'react';
import { QuadraticBezierLine } from '@react-three/drei';
import { Vector3 } from 'three';

interface DottedBezierLineProps {
  start: Vector3;
  end: Vector3;
  segments?: number;
  color?: string;
  lineWidth?: number;
  dashed?: boolean;
  dashSize?: number;
  gapSize?: number;
}

const DottedBezierLine: React.FC<DottedBezierLineProps> = ({
  start,
  end,
  segments = 100,
  color = 'white',
  lineWidth = 1,
  dashed = true,
  dashSize = 0.01,
  gapSize = 0.1,
}) => {
  return (
    <QuadraticBezierLine
      start={start}
      end={end}
      lineWidth={lineWidth}
      color={color}
      dashed={dashed}
      dashSize={dashSize}
      gapSize={gapSize}
    />
  );
};

export default DottedBezierLine;