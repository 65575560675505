import React, { useEffect, useRef } from 'react';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { SpaceObject } from '../../../../types/spaceTypes';
import { Object3D, Group } from 'three';
import { unityToThreePosition, unityToThreeEuler, toThreeVector3 } from '../../../../types/transformTypes';
import { useFindGameObject } from '../../../../utility/threeUtlility';

interface GLBModelViewerSpaceObjectProps {
  SpaceObject: SpaceObject;
}

const GLBModelViewerSpaceObject: React.FC<GLBModelViewerSpaceObjectProps> = ({ SpaceObject }) => {
  const parentRef = useFindGameObject(SpaceObject.SpaceObjectDetails.LocationEnabled.ReferenceGameObject);
  const gltf = useLoader(GLTFLoader, SpaceObject.MediaFilePath + "?key=" + SpaceObject.UniqueId);
  
  const groupRef = useRef<Group>(null);
  const pivotRef = useRef<Group>(null);

  useEffect(() => {
    if (!parentRef || !gltf.scene || !groupRef.current || !pivotRef.current) {
      return; // Early return if any required element is missing
    }

    const localPosition = unityToThreePosition(SpaceObject.SpaceObjectDetails.LocationEnabled.LocalPosition);
    const localRotation = unityToThreeEuler(SpaceObject.SpaceObjectDetails.LocationEnabled.LocalRotation);
    const localScale = toThreeVector3(SpaceObject.SpaceObjectDetails.LocationEnabled.LocalScale);

    // Set up the hierarchy
    groupRef.current.position.copy(localPosition);
    groupRef.current.rotation.copy(localRotation);
    groupRef.current.scale.copy(localScale);

    pivotRef.current.name = 'pivot';
    groupRef.current.name=SpaceObject.MediaFileName;
    
    groupRef.current.add(pivotRef.current);
    pivotRef.current.add(gltf.scene);

    // Determine the parent to attach to
    let attachTarget: Object3D;
    if (parentRef.name.toLowerCase() !== 'Floor') {
      const parentPivot = parentRef.children.find(child => child.name.toLowerCase() === 'pivot');
      attachTarget = parentPivot || parentRef;
    } else {
      attachTarget = parentRef;
    }

    // Add the top-level group to the determined parent
    attachTarget.add(groupRef.current);

    return () => {
      // Clean up
      if (attachTarget && groupRef.current) {
        attachTarget.remove(groupRef.current);
      }
    };
  }, [parentRef, gltf, SpaceObject]);

  return (
    <group ref={groupRef}>
      <group ref={pivotRef} />
    </group>
  );
};

export default GLBModelViewerSpaceObject;