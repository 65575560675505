export class AudioPlayer {
  private audioContext: AudioContext;
  private gainNode: GainNode;
  private source: AudioBufferSourceNode | null = null;
  private isPlaying: boolean = false;

  constructor() {
    this.audioContext = new AudioContext();
    this.gainNode = this.audioContext.createGain();
    this.gainNode.connect(this.audioContext.destination);
  }

  async loadAndPlay(url: string): Promise<void> {
    // Stop and disconnect the previous source if it's playing
    if (this.isPlaying) {
      this.stop(); // Ensure any playing audio is stopped
    }

    // Fetch and decode the new audio file
    const response = await fetch(url);
    const arrayBuffer = await response.arrayBuffer();
    const audioBuffer = await this.audioContext.decodeAudioData(arrayBuffer);

    // Create a new source node
    this.createSource(audioBuffer);
  }

  private createSource(audioBuffer: AudioBuffer): void {
    this.source = this.audioContext.createBufferSource();
    this.source.buffer = audioBuffer;
    this.source.connect(this.gainNode);
    this.source.onended = () => {
      this.isPlaying = false; // Update the state when playback finishes
      this.source?.disconnect(); // Disconnect the source to free up resources
      this.source = null; // Clean up the source
    };
    this.startPlayback();
  }

  private startPlayback(): void {
    if (this.source && !this.isPlaying) {
      if (this.audioContext.state === 'suspended') {
        this.audioContext.resume().then(() => {
          this.source?.start(0);
          this.isPlaying = true;
        });
      } else {
        this.source.start(0);
        this.isPlaying = true;
      }
    }
  }

  stop(): void {
    if (this.source && this.isPlaying) {
      this.source.stop();
      this.isPlaying = false;
    }
  }

  toggleMute(): void {
    this.gainNode.gain.value = this.gainNode.gain.value === 0 ? 1 : 0;
  }
}
