import { Header } from '../../../components/shared/HeaderFooter/Header';
import { ExperienceListView } from './components/ExperienceListView';
import { Organization, Project } from '../../../types/projectTypes';
import { listProjectsAndOrgs } from '../../../services/projectService';
import { useState,useEffect } from 'react';

export const ExperienceList = () => {


  const [list, setList] = useState<Organization[]>([]);
  const [projects, setProjects] = useState<any>([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectResponse = await listProjectsAndOrgs(parseInt(localStorage.getItem('userID') || "-1"));
        if (projectResponse.orgsList.length === 0 || projectResponse.data.length === 0) {
          throw new Error("Empty data array received from API");
        }
        setList(projectResponse.orgsList);
        setProjects(projectResponse);
        console.log("projectResponse.data");
        console.log(projectResponse.DisplayPicture);

      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
      }
    };

    fetchData();
  }, []);

  return (
    <>
    <div className="flex flex-col min-h-screen bg-stone-50 ">
      <div className='z-30'>
    <Header data={""} list={list} onOrgChange={()=>{  }} isDropdownDisabled={true} projectResponse={projects}/>
    </div>

      <div className="flex-grow mt-[8vh] bg-stone-50">
        {/* <StepNavigation onPrevious={onPrevious} onNext={onNext}/> */}
        {/* <div className="max-w-full w-full min-h-[74px] px-4 py-3 bg-stone-50 flex justify-start items-center gap-4 sm:gap-8 lg:gap-14 xl:px-48">
          <div className="flex-grow flex-shrink basis-0 text-slate-800 text-base lg:text-xl font-normal font-['Selawk'] leading-normal lg:leading-[49.84px]">Experiences</div>
        </div> */}
        <ExperienceListView />
      </div>
    </div>
    </>
  );
}

export default ExperienceList;
