import apiClient from "./apiClient";
import {
  AudioData,
  SpaceData,
  CreateObjectData,
  UpdateObjectData,
} from "../types/apitypes";

export const getExperienceList = async (projectID: number) => {
  try {
    const response = await apiClient.get(
      `/training/getAllExperiencesByProjectID/${projectID}`
    );
    // You can handle the response further, e.g., storing the user token
    console.log("list coming", response.data);

    return response.data;
  } catch (error) {
    console.log("listttt not coming", error);
    throw error;
  }
};

export const getExperienceData = async (experienceID: number) => {
  try {
    const response = await apiClient.get(
      `/training/product/${experienceID}/getSectionObjectsConnectors/v4`
    );
    // You can handle the response further, e.g., storing the user token
    console.log("hello", response);

    return response.data;
  } catch (error) {
    console.error(
      "Error during fetchAllDataByExperienceIDfetchAllDataByExperienceIDfetchAllDataByExperienceID-up:",
      error
    );
    throw error;
  }
};

export const getProcedureData = async (orgID: number) => {
  try {
    const response = await apiClient.get(`/prodigy/importListByOrg/${orgID}`);
    // You can handle the response further, e.g., storing the user token
    console.log("Prodigy Import list coming", response.data);

    return response.data;
  } catch (error) {
    console.log("Prodigy Import listttt not coming", error);
    throw error;
  }
};

export const importClientPDF = async (
  OrganizationID: string,
  file: File,
  fileName: string,
  importImage: string
) => {
  try {
    // Prepare the form data
    const formData = new FormData();
    formData.append("OrganizationID", OrganizationID);
    formData.append("filename", file, fileName);
    formData.append("importimage", importImage);
    // Post the form data to the server
    const response = await apiClient.post(`/prodigy/importPDF/V1`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("list coming", response.data);
    return response.data;
  } catch (error) {
    console.log("listttt not coming", error);
    throw error;
  }
};

export const importExcel = async (projectID: number) => {
  try {
    const response = await apiClient.post(`/training/importExcel}`);
    // You can handle the response further, e.g., storing the user token
    console.log("list coming", response.data);

    return response.data;
  } catch (error) {
    console.log("listttt not coming", error);
    throw error;
  }
};
export const exportExcel = async (projectID: number) => {
  try {
    const response = await apiClient.post(`/training/exportExcel}`);
    // You can handle the response further, e.g., storing the user token
    console.log("list coming", response.data);

    return response.data;
  } catch (error) {
    console.log("listttt not coming", error);
    throw error;
  }
};
export const uploadImage = async (projectID: number) => {
  try {
    const response = await apiClient.post(`/training/uploadImage}`);
    // You can handle the response further, e.g., storing the user token
    console.log("list coming", response.data);

    return response.data;
  } catch (error) {
    console.log("listttt not coming", error);
    throw error;
  }
};
export const getStepsfromProcedure = async (projectID: number) => {
  try {
    const response = await apiClient.get(`/training/getStepData/${projectID}`);
    // You can handle the response further, e.g., storing the user token
    console.log("Prodigy Import list coming", response.data);

    return response.data;
  } catch (error) {
    console.log("Prodigy Import listttt not coming", error);
    throw error;
  }
};

export const deleteSourceForImports = async (projectID: number) => {
  try {
    const response = await apiClient.delete(
      `/training/deleteSourceForImports/${projectID}`
    );
    // You can handle the response further, e.g., storing the user token
    console.log("Delete this list", response.data);

    return response.data;
  } catch (error) {
    console.log("Not deleting", error);
    throw error;
  }
};

export const CreateProduct = async (
  ProductName: String,
  ProductDesc: String,
  OrgID: number
) => {
  const productDetails = {
    ProductName: ProductName,
    ProductDesc: ProductDesc,
    OrganizationID: OrgID,
  };
  try {
    const response = await apiClient.post(
      `/training/${OrgID}/product/new`,
      productDetails
    );
    // You can handle the response further, e.g., storing the user token
    console.log("Prodigy Import list coming", response.data);

    return response.data;
  } catch (error) {
    console.log("Prodigy Import listttt not coming", error);
    throw error;
  }
};

export const CreateExperience = async (
  ProjectID: Number,
  orgid: number,
  TrainingSectionName: String,
  TrainingSectionDesc: String
) => {
  const body = {
    OrganizationID: orgid,
    TrainingSectionName: TrainingSectionName,
    TrainingSectionDesc: TrainingSectionDesc,
  };
  try {
    const response = await apiClient.post(
      `/training/product/${ProjectID}/trainingSection/new`,
      body,
      {
        headers: {
          source: "aibot",
        },
      }
    );
    // You can handle the response further, e.g., storing the user token
    console.log("Prodigy Import list coming", response.data);

    return response.data;
  } catch (error) {
    console.log("Prodigy Import listttt not coming", error);
    throw error;
  }
};

export const CreateStepNode = async (
  projectID: number,
  newStepNodeArray: any
) => {
  const body = newStepNodeArray;
  try {
    const response = await apiClient.post(
      `/training/product/${projectID}/rolePlayObject/new`,
      body,
      {
        headers: {
          source: "aibot",
        },
      }
    );
    // You can handle the response further, e.g., storing the user token
    console.log("CreateStepNode", response.data);

    return response.data;
  } catch (error) {
    console.log("CreateStepNode error", error);
    throw error;
  }
};

export const CreateChoiceNode = async (
  projectID: number,
  newStepNodeArray: any
) => {
  const body = newStepNodeArray;
  try {
    const response = await apiClient.post(
      `/training/product/${projectID}/rolePlayObject/new`,
      body,
      {
        headers: {
          source: "aibot",
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log("CreateStepNode error", error);
    throw error;
  }
};
export const CreateProjectandSpace = async (CPAS: any) => {
  const body = CPAS;
  try {
    const response = await apiClient.post(
      `/project/createProjectAndSpace`,
      body
    );
    // You can handle the response further, e.g., storing the user token
    console.log("Project and space last api", response.data);

    return response.data;
  } catch (error) {
    console.log();
    throw error;
  }
};

export const LinkStepNodes = async (id: Number, nodes: any) => {
  try {
    const response = await apiClient.post(
      `/training/product/${id}/rolePlayObject/linkNode`,
      nodes,
      {
        headers: {
          source: "aibot",
        },
      }
    );
    // You can handle the response further, e.g., storing the user token
    console.log("linknodes last api", response.data);

    return response.data;
  } catch (error) {
    console.log();
    throw error;
  }
};

export const UpdateStepNodes = async (
  id: Number,
  fulljson: any,
  sectionObjectID: any
) => {
  try {
    const response = await apiClient.post(
      `/training/product/${id}/rolePlayObject/${sectionObjectID}/updateRolePlayObject`,
      fulljson
    );
    // You can handle the response further, e.g., storing the user token
    console.log("UpdatedStepNodes", response.data);

    return response.data;
  } catch (error) {
    console.log();
    throw error;
  }
};

export const UpdateLocation = async (locationData: any) => {
  try {
    const response = await apiClient.post(
      `/training/sectionObject/updateLocation`,
      { locationData: locationData }
    );
    console.log("updated location", response);
    return response.data;
  } catch (error) {
    console.log();
    throw error;
  }
};

export const DeleteExperience = async (projectID: number) => {
  try {
    const response = await apiClient.delete(
      `/training/section/${projectID}/deleteTrainingSection`
    );
  } catch (error) {
    console.log();
    throw error;
  }
};
export const DeleteProduct = async (productID: number) => {
  try {
    const response = await apiClient.delete(
      `/training/section/${productID}/deleteProduct`
    );
  } catch (error) {
    console.log();
    throw error;
  }
};

export const LinkChoiceNodes = async (id: number, nodes: any) => {
  try {
    const response = await apiClient.post(
      `/training/product/${id}/rolePlayObject/linkNode`,
      nodes
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const DelinkStepNodes = async (id: number, nodes: any) => {
  try {
    const response = await apiClient.post(
      `/training/product/${id}/rolePlayObject/deLinkNode`,
      {
        headers: {
          source: "aibot",
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
export const DelinkChoiceNodes = async (id: number, nodes: any) => {
  try {
    const response = await apiClient.delete(
      `/training/product/${id}/rolePlayObject/deLinkNode`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const DeleteAllNode = async (expid: number, nodeID: number) => {
  try {
    const response = await apiClient.delete(
      `/training/section/${expid}/deleteNode/${nodeID}`
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Node not deleted!");
  }
};

export const GenerateAudio = async (audio: AudioData) => {
  try {
    const response = await apiClient.post(
      `/congnitiveservice/convertTextToSpeech`,
      audio
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Audio not generated");
  }
};

export const CreateSpace = async (spaceData: SpaceData) => {
  try {
    const response = await apiClient.post(
      `/project/space/create/v1`,
      spaceData
    );
    console.log("response createspace");
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Space not generated");
  }
};

export const GetObjectsDetails = async (objectID: number) => {
  try {
    const response = await apiClient.get(
      `/objects/${objectID}/getObjectDetailsByID`
    );
    console.log("get objs details");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("objects details not fetched");
  }
};
export const GetObjectAndTags = async (projectID: number) => {
  try {
    const response = await apiClient.get(
      `/objects/${projectID}/getobjectandtags`
    );
    console.log("get objs");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("objects not fetched");
  }
};
export const ListObjectsByTags = async (projectID: number) => {
  try {
    const response = await apiClient.get(
      `/objects/${projectID}/listobjectsbytags`
    );
    console.log("get objs list and tags");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("objects list and tags not fetched");
  }
};

export const CreateObjects = async (formData: FormData) => {
  try {
    const response = await apiClient.post(`/objects/createObject/v1`, formData);
    console.log("Create Objs");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("objects not created fetched");
  }
};

export const updateObject = async (updateObjects: FormData) => {
  try {
    const response = await apiClient.post(
      `/objects/updateObject/v1`,
      updateObjects
    );
    console.log("updateObject Objs");
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("objects not update fetched");
  }
};

export const SectionIDresponse = async (projectId: number, body: any) => {
  try {
    const response = await apiClient.post(
      `project/${projectId}/section/5/createNewSection`,
      body
    );
    console.log("SectionIDresponse");
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(error);
    throw new Error("Sectionid ");
  }
};
