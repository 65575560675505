// src/config.ts

// Define the interface for your configuration values
interface AppConfig {
    apiUrl: string;
    storageUrl: string;
    environment: 'development' | 'staging' | 'production';
    [key: string]: any; // This allows for additional dynamic properties if needed
}

// Create the configuration object
const config: AppConfig = {
    apiUrl: 'https://portaldev.altoura.com/',
    storageUrl:'https://altouraonyx.azureedge.net',
    environment: process.env.NODE_ENV as 'development' | 'staging' | 'production',
};

// Export the configuration object
export default config;