import React, { createContext, useContext, useRef, ReactNode } from 'react';
import { emptyObjectData, ObjectData } from '../../../types/objectTypes';

interface SceneContextProviderProps {
  yaw: React.MutableRefObject<number>;
  pitch: React.MutableRefObject<number>;
  isInteracting: React.MutableRefObject<boolean>;
  onObjectData: React.MutableRefObject<(objectdata:ObjectData) => void>;

}

const SceneContext = createContext<SceneContextProviderProps | null>(null);

interface SceneContextProps {
  children: ReactNode;
}

export const SceneContextProvider: React.FC<SceneContextProps> = ({ children }) => {
  const yaw = useRef(0);
  const pitch = useRef(0);
  const isInteracting = useRef(false);
  const onObjectData = useRef<(objectdata:ObjectData) => void>((objectdata:ObjectData) => {});

 

  return (
    <SceneContext.Provider value={{ yaw, pitch, isInteracting, onObjectData }}>
      {children}
    </SceneContext.Provider>
  );
};

export const useSceneControl = (): SceneContextProviderProps => {
  const context = useContext(SceneContext);
  if (!context) {
    throw new Error('useCameraControl must be used within a CameraControlProvider');
  }
  return context;
};