import React, { useEffect } from 'react';
import './Logout.css';
import { signOut } from '../../../services/authService';

export const Logout = () => {

    useEffect(() => {
        const callLogout = async () => {
            try {
                await signOut();

                // Redirect to the homepage or login page
                window.location.href = '/';
            } catch (error) {
                console.error('Logout failed', error);
            }
        };

        callLogout();
    }, []);

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="text-center p-5 border border-gray-300 rounded-lg bg-white shadow-md">
                <div className="flex items-center justify-center mb-4">
                    <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-blue-500"></div>
                </div>
                Logging Out ...
            </div>
        </div>
    );
};
