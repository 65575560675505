import React, { useEffect, useState } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls, Environment } from "@react-three/drei";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import trashicon from "../../../../logos/ico_trash_3.svg";

interface GlbProps {
  glbFile: File;
  handleDeleteGlb?: () => void;
}

const GLBViewer: React.FC<GlbProps> = ({ glbFile, handleDeleteGlb }) => {
  const [scene, setScene] = useState<THREE.Group | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const url = URL.createObjectURL(glbFile);
    const loader = new GLTFLoader();

    loader.load(
      url,
      (gltf) => {
        const obj = gltf.scene;
        const box = new THREE.Box3().setFromObject(obj);
        const size = box.getSize(new THREE.Vector3()).length();
        const center = box.getCenter(new THREE.Vector3());

        obj.position.x += obj.position.x - center.x;
        obj.position.y += obj.position.y - center.y;
        obj.position.z += obj.position.z - center.z;

        setScene(obj);
      },
      undefined,
      (err) => {
        console.error("Error loading GLB file:", err);
        setError("Error loading GLB file");
      }
    );

    return () => {
      URL.revokeObjectURL(url);
    };
  }, [glbFile]);

  if (error) {
    return <div>{error}</div>;
  }

  const FitCameraToObject = ({ object, size, center }: any) => {
    const { camera } = useThree();

    useEffect(() => {
      if (object) {
        const distance = size * 1.2;
        const direction = new THREE.Vector3()
          .subVectors(camera.position, center)
          .normalize();

        camera.position.copy(direction.multiplyScalar(distance).add(center));
        camera.near = size / 100;
        camera.far = size * 100;
        camera.updateProjectionMatrix();
        camera.lookAt(center);
      }
    }, [object, size, center, camera]);

    return null;
  };

  return (
    scene && (
      <>
        <Canvas
          gl={{ alpha: false, antialias: true }}
          camera={{ position: [0, 0, 5] }}
          onCreated={({ gl }) => {
            gl.setClearColor("#808080");
          }}
        >
          <ambientLight intensity={0.5} />
          <directionalLight position={[10, 10, 5]} intensity={1} />
          <directionalLight position={[-10, -10, -5]} intensity={1} />
          <pointLight position={[10, 10, 10]} intensity={1.5} />
          <Environment preset="sunset" />
          <primitive object={scene} scale={[1.5, 1.5, 1.5]} />
          <FitCameraToObject
            object={scene}
            size={new THREE.Box3().setFromObject(scene).getSize(new THREE.Vector3()).length()}
            center={new THREE.Box3().setFromObject(scene).getCenter(new THREE.Vector3())}
          />
          <OrbitControls />
        </Canvas>
        <button
          className="absolute top-0 right-0 p-1 bg-white rounded-md"
          onClick={handleDeleteGlb}
        >
          <img src={trashicon} alt="Delete" className="w-4 h-4" />
        </button>
      </>
    )
  );
};

export default GLBViewer;
