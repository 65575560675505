
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Login } from './pages/Auth/Login/Login';
import { Signup } from './pages/Auth/Signup/Signup';
import { ResetPassword } from './pages/Auth/ResetPassword/ResetPassword';
import {ExperienceList } from './pages/Experience/ExperienceList/ExperienceList';
import {ObjectList} from '../src/pages/Project/ObjectList/ObjectList'
import { ExperienceComponent } from './pages/Experience/Experience/ExperienceComponent';
import { ExperienceComponent3D } from './pages/Experience/Experience3D/ExperienceComponent3D';
import {ImportExperienceComponent} from './pages/ImportExperience/ImportExperience'
import ProtectedRoute from './components/shared/ACL/ProtectedRoute'; // Adjust the path as necessary
import { Logout } from './pages/Auth/Logout/Logout';
import ProjectList from './pages/Project/ProjectList/ProjectList';
import { Scene } from './pages/3DViewer/Scene/Scene';
import {EditExperienceComponent} from './pages/Experience/EditExperience/EditExperience';
import CreateWithProdigy from "../src/pages/Experience/ExperienceList/CreateWithProdigy/CreateWithProdigy"
import CanvasComponent from "../src/pages/CanvasComponent/CanvasComponent";
import AuthCallback from './pages/Auth/authCallback/authCallback';
import { Space3DEdit } from './pages/Experience/Space3DEdit/Space3DEdit';
const App = () => {

return(
  <Router>
    <Routes>

      <Route path='/' element={<ProtectedRoute component={Login} isSkipLogin={true}/>} />
      <Route path='/dashboard' element={<ProtectedRoute component={ProjectList} />}  />
      <Route path='/Project/:ProjectID/ExperienceList' element={<ProtectedRoute component={ExperienceList} />}  />
      <Route path='/ObjectList' element={<ProtectedRoute component={ObjectList} />}  />
      <Route path='/signup' element={<Signup/>} />
      <Route path='/signout' element={<ProtectedRoute component={Logout} />}  />
      <Route path='/resetPassword' element={<ResetPassword/>} />
      <Route path='space/:spaceID/experience/:experienceID' element={<ProtectedRoute component={ExperienceComponent} /> } />
      <Route path='/project/:projectID/projectSection/:projectSectionID/space/:spaceID/experience/:experienceID/3D' element={<ProtectedRoute component={ExperienceComponent3D} /> } />
      <Route path='/project/:projectID/projectSection/:projectSectionID/space/:spaceID/3D/Edit' element={<ProtectedRoute component={Space3DEdit} /> } />
      <Route path='/experience/importProcedure/:procedureID' element={<ProtectedRoute component={ImportExperienceComponent} /> } />
      <Route path='/experience/:experienceID/edit' element={<ProtectedRoute component={EditExperienceComponent} /> } />
      <Route path='/CreateWithProdigy' element={<ProtectedRoute component={CreateWithProdigy} view={1}/> } />
      <Route path='/CreateWithExperience' element={<ProtectedRoute component={CreateWithProdigy} view={4}/> } />
      <Route path='/CanvasView' element={<ProtectedRoute component={CanvasComponent} /> } />
      <Route path='/auth/callback' element={<AuthCallback />} />

      

    </Routes>
  </Router>
)
};


export default App;