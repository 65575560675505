import React, { useState, useEffect } from 'react';
import "./createexp.css";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: { name: string; description: string }) => void;
  productName: string;
  productDesc: string;
  setProductName: React.Dispatch<React.SetStateAction<string>>;
  setProductDesc: React.Dispatch<React.SetStateAction<string>>;
  successMessage: string;
  errorMessage: string;
  isLoading: boolean;
  resetForm: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  productName,
  productDesc,
  setProductName,
  setProductDesc,
  successMessage,
  errorMessage,
  isLoading,
  resetForm
}) => {
  const [productNameError, setProductNameError] = useState('');
  const [productDescError, setProductDescError] = useState('');
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  useEffect(() => {
    if (successMessage === "Product and experience created successfully!") {
      setShowSuccessPopup(true); // Show success popup
      const timer = setTimeout(() => {
        setShowSuccessPopup(false); // Hide popup
        resetForm();               // Reset the form fields and errors
      }, 3000);                    // Delay of 3 seconds
      return () => clearTimeout(timer); // Cleanup the timer
    }
  }, [successMessage, onClose, resetForm]);

  if (!isOpen) return null;

  const handleClose = () => {
    resetForm();
    onClose();
    setShowSuccessPopup(false);
    setProductNameError('');
    setProductDescError('');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!productName.trim() || !productDesc.trim()) {
      setProductNameError('Product name is required.');
      setProductDescError('Product description is required.');
      return;
    }
    setProductNameError('');
    setProductDescError('');
    onSubmit({ name: productName, description: productDesc });
  };

  return (
    <div className="fixed inset-0 bg-stone-500 bg-opacity-50 flex justify-center items-center" onClick={handleClose}>
      <div className="w-[480px] px-8 py-6 bg-white rounded-xl flex flex-col justify-start items-center gap-4" onClick={(e) => e.stopPropagation()}>
        <div className="text-stone-900 text-2xl font-normal font-['Selawk'] leading-[42.72px]">Create Experience</div>
        <form onSubmit={handleSubmit} className="w-full flex flex-col gap-4">
          <div className="flex flex-col gap-1">
            <label className="text-stone-900 text-sm font-normal font-['Selawk'] leading-tight">Experience Name</label>
            <div className="w-full px-2 py-[12.50px] bg-stone-50 rounded-md border border-slate-200">
              <input 
                type="text" 
                value={productName} 
                onChange={(e) => setProductName(e.target.value)} 
                className="w-full bg-transparent border-none outline-none text-stone-900 text-base font-normal font-['Selawik'] leading-normal" 
              />
            </div>
            {productNameError && <p className="text-red-500">{productNameError}</p>}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-stone-900 text-sm font-normal font-['Selawk'] leading-tight">Description</label>
            <div className="w-full h-[115px] px-2 py-3 bg-stone-50 rounded-md border border-slate-200">
              <textarea 
                value={productDesc} 
                onChange={(e) => setProductDesc(e.target.value)} 
                className="w-full h-full bg-transparent border-none outline-none text-stone-900 text-base font-normal font-['Selawik'] leading-normal" 
              />
            </div>
            {productDescError && <p className="text-red-500">{productDescError}</p>}
          </div>
          {isLoading && (
            <div className="text-center creating-loader mt-4">
              Creating
              <span className="dots">
                <span className="dot"></span>
                <span className="dot"></span>
                <span className="dot"></span>
              </span>
            </div>
          )}
          {errorMessage && <div className="text-red-500 text-center mt-2">{errorMessage}</div>}
          {successMessage && <div className="text-green-500 text-center mt-2">{successMessage}</div>}
          {showSuccessPopup && (
            <div className="success-popup">
              <p>{successMessage}</p>
            </div>
          )}
          <div className="flex justify-between mt-4">
            <button 
              type="button" 
              onClick={handleClose} 
              className="w-[48%] h-9 px-8 py-2 rounded-md border border-blue-500 flex justify-center items-center text-blue-500 text-sm font-normal font-['Selawk'] leading-tight"
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className="w-[48%] h-9 px-8 py-2 bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] rounded-md flex justify-center items-center text-white text-sm font-normal font-['Selawk'] leading-tight"
            >
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modal;
