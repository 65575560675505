import React, { Suspense, useEffect, useState } from "react";
import { Canvas, useThree } from "@react-three/fiber";
import { Vector3 } from "three";
import { PlaneGround } from "../Environment/PlaneGround";
import TextPanel from "../UIComponents/Panels/TextPanel";
import { Physics } from "@react-three/cannon";
import CameraControls from "../PlayerControls/CameraControls";
import KeyboardControls from "../PlayerControls/KeyboardControls";
import "./Scene.css";
import { NodeRenderDetails } from "../../../types/nodeDataTypes";
import DottedBezierLine from "../UIComponents/Spline/DottedSpline";
import GLBModelViewer from "./Loaders/GLBModelViewer";
import LoaderCube from "./Loaders/LoaderCube";
import { AssetData, SpaceObject } from "../../../types/spaceTypes";
import GLBModelViewerSpaceObject from "./Loaders/GLBModelViewerSpaceObject";
import HdrEnvironment from "./Loaders/HdrEnvironment";
import hdrPath from "../../../assessts/environment/autoshop_01_1k.hdr";
import { SceneContextProvider } from "../Providers/SceneContextProvider";
import Confetti from 'react-confetti'; 
import { SceneNode } from "../UIComponents/SceneTools/SceneHierarchy";
import { AudioListener, PositionalAudio } from "three";

const CameraSetup: React.FC = () => {
  const { camera } = useThree();

  useEffect(() => {
    console.log("CameraSetup is called");
    camera.position.set(0, 1.6, 3); // Set the camera height to 1.6 meters
    const listener = new AudioListener();
    camera.add(listener);
  }, []);

  return null;
};

interface SceneProps {
  nodeRenderDetails: NodeRenderDetails | undefined;
  spaceobjects: AssetData | undefined;
}

export const Scene: React.FC<SceneProps> = ({
  nodeRenderDetails,
  spaceobjects,
}) => {
  const [cubePosition, setCubePosition] = useState(new Vector3(0, 0, 0));
  const [panelPosition, setpanelPosition] = useState(new Vector3(0, 1.6, 0));

  const [isLoading, setIsLoading] = useState(true);
  const [sceneData, setSceneData] = useState<SceneNode | null>(null);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth * 1.5,  // Increase width by 50%
        height: window.innerHeight * 1.5, // Increase height by 50%
      });
    };

    // Set initial dimensions
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <SceneContextProvider>
        <Canvas onCreated={() => setIsLoading(false)}>
          <Suspense fallback={null}>
            <HdrEnvironment hdrPath={hdrPath} />
          </Suspense>

          <color attach="background" args={["#404040"]} />
          <CameraSetup />
          <directionalLight position={[2.5, 8, 5]}></directionalLight>
          <ambientLight intensity={0.5} />
          <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
          <pointLight position={[-10, -10, -10]} />
          <CameraControls />
          <KeyboardControls />
          <Suspense fallback={null}>
            <Physics>
              <PlaneGround />
            </Physics>
          </Suspense>
          <TextPanel
            nodeRenderDetails={nodeRenderDetails}
            onDragPanel={(position) => {
              setpanelPosition(position);
            }}
          />
          {nodeRenderDetails?.threeDfile && (
            <Suspense fallback={<LoaderCube position={[0, 0, 0]} />}>
              <GLBModelViewer url={nodeRenderDetails?.threeDfile!} />
            </Suspense>
          )}

          {spaceobjects &&
            spaceobjects?.spaceObjects.map((spaceObject: SpaceObject) => (
              <Suspense fallback={<LoaderCube position={[0, 0, 0]} />}>
                <GLBModelViewerSpaceObject SpaceObject={spaceObject} />
              </Suspense>
            ))}

          <DottedBezierLine
            start={cubePosition}
            end={panelPosition.add(new Vector3(0, -0.5, 0))}
            lineWidth={2}
            color="white"
            dashed
            dashSize={0.02}
            gapSize={0.02}
          />
        </Canvas>
      </SceneContextProvider>
      {nodeRenderDetails?.isCompleted && (
        <Confetti
          numberOfPieces={1600}
          gravity={0.1}
          colors={[
            "#ff3e3e",
            "#3eff3e",
            "#3e3eff",
            "#ff3eff",
            "#ff963e",
            "#3eff96",
          ]}
          recycle={false}
          width={dimensions.width}
          height={dimensions.height}
          style={{ position: 'fixed', top: 0, left: 0 ,right:0, width:"100%" }}
        />
      )}
    </div>
  );
};
