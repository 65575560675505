import React from 'react';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

interface GLBModelViewerProps {
  url: string;
}

const GLBModelViewer: React.FC<GLBModelViewerProps> = ({ url }) => {
  try{
  const gltf = useLoader(GLTFLoader, url);
  return <primitive object={gltf.scene} />;
  }
  catch(e){
return null;
  }
};

export default GLBModelViewer;