import React, { useEffect, useState, useCallback } from "react";
import importicon from "../../../../../logos/Frame 433.svg";
import trashicon from "../../../../../logos/ico_trash_3.svg";
import localStorageService from "../../../../../services/localStorageService";
import { useLocalStorage } from "react-use";
import { listProjectsAndOrgs } from "../../../../../services/projectService";
import { Organization } from "../../../../../types/projectTypes";
import { CreateProjectandSpace } from "../../../../../services/experienceService";
import axios from "axios";
import "./NewProjPop.css";
import updropdown from "../../../../../logos/ico_chevron_up.svg";
import downdropdown from "../../../../../logos/ico_down.svg";
import CustomDropdown from "./CustomDropDown/CustomDropDown";

interface NewProjectPopupProps {
  isVisible: boolean;
  onClose: () => void;
  projectresponse?: any;
}

interface ProductDetails {
  ProductID: number | undefined;
  ProductUniqueID: string;
}

const NewProjectPopup: React.FC<NewProjectPopupProps> = ({
  isVisible,
  onClose,
  projectresponse,
}) => {
  const [uploadedImage, setUploadedImage] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isProjectNameFocused, setIsProjectNameFocused] = useState(false);
  const [isOrganisationFocused, setIsOrganisationFocused] = useState(false);
  const [isProjectNameError, setIsProjectNameError] = useState(false);
  const [isOrganisationError, setIsOrganisationError] = useState(false);
  const [projName, setProjName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const orgID = localStorageService.getItem("selectedOrgId");
  const [productDetails, setProductDetails] = useState<ProductDetails | null>(
    null
  );
  const [selectedOrgId, setSelectedOrgId] = useLocalStorage(
    "selectedOrgId",
    ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [list, setList] = useState<Organization[]>([]);

  const resetForm = useCallback(() => {
    setProjName("");
    setProductDesc("");
    setUploadedImage(null);
    setFile(null);
    setIsProjectNameError(false);
    setIsOrganisationError(false);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Element;
      if (target.classList.contains("modal-overlay")) {
        onClose();
        resetForm();
        setIsProjectNameError(false);
        setIsProjectNameFocused(false);
        setIsOrganisationFocused(false);
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, onClose, resetForm]);

  useEffect(() => {
    if (projectresponse) {
      setList(projectresponse.orgsList);
    }
  }, [projectresponse]);

  const handleImageUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files[0]) {
        const selectedFile = event.target.files[0];
        setFile(selectedFile);

        const reader = new FileReader();
        reader.onload = (e) => {
          if (e.target && typeof e.target.result === "string") {
            setUploadedImage(e.target.result);
          }
        };
        reader.readAsDataURL(selectedFile);
      }
    },
    []
  );

  const handleUpload = useCallback(
    async (file: File) => {
      if (!file) {
        setErrorMessage("Please select a file first");
        return null;
      }

      setIsLoading(true);
      setErrorMessage("");
      const userToken = localStorage.getItem("userToken");

      if (!userToken) {
        setErrorMessage("User token not found");
        return null;
      }

      try {
        // Generate SAS URL
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/upload/generateStorageSignaureForSlides`,
          {
            params: {
              fileName: file.name,
              trainingSectionID: orgID,
              token: userToken,
            },
          }
        );

        const sasUrl = response.data.sasUrl;

        // Upload the file using the SAS URL
        await axios.put(sasUrl, file, {
          headers: {
            "x-ms-blob-type": "BlockBlob",
            "Content-Type": file.type,
          },
        });

        setSuccessMessage("File uploaded successfully");
        return sasUrl;
      } catch (error) {
        console.error("Error uploading file", error);
        setErrorMessage("Error uploading file");
        return null;
      } finally {
        setIsLoading(false);
      }
    },
    [orgID]
  );

  const handleMediaUpload = useCallback(
    async (file: File): Promise<{ data: { uri: string } } | null> => {
      if (!file) return null;

      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = async () => {
          const result = reader.result;
          if (typeof result === "string") {
            try {
              // Generate SAS URL
              const response = await axios.get(
                `https://portaldev.altoura.com/upload/generateStorageSignaureForProjectThumbnail?fileName=${file.name}&mediaType=Image`,
                {
                  params: {
                    fileName: file.name,
                    token: localStorage.getItem("token"),
                  },
                }
              );

              const uri = response.data.data.uri;

              if (!uri) {
                console.error("URI is undefined or null");
                reject(null);
                return;
              }

              // Upload the file using the SAS URL
              await axios.put(uri, file, {
                headers: {
                  "x-ms-blob-type": "BlockBlob",
                  "Content-Type": file.type,
                },
              });

              // Return the URI inside an object
              resolve({ data: { uri: uri.split("?")[0] } });
            } catch (error) {
              console.error("Error uploading file", error);
              reject(null);
            }
          }
        };
        reader.readAsDataURL(file);
      });
    },
    []
  );

  const handleModalSubmit = useCallback(async () => {
    if (!projName) {
      setIsProjectNameError(true);
      return;
    } else {
      setIsProjectNameError(false);
    }

    if (!selectedOrgId) {
      setIsOrganisationError(true);
      return;
    } else {
      setIsOrganisationError(false);
    }

    setIsLoading(true);
    try {
      let thumbnailUrl =
        "/altouradevcontainer/ProjectThumbnails/Default_Thumbnail/ico_default_thumbnail.png";

      if (file) {
        // Upload the image and get the SAS URL
        let uploadedUrl = await handleMediaUpload(file);
        if (uploadedUrl?.data) {
          const path = extractPath(uploadedUrl.data.uri);
          if (path) {
            thumbnailUrl = path;
          }
        }
      }

      const CPAS = {
        ProjectName: projName,
        ProjectDesc: productDesc,
        OrganizationID: selectedOrgId,
        ThumbnailStretched: thumbnailUrl, // Use the uploaded image URL here
        SectionObjectName: projName,
        SpaceThumbnail: thumbnailUrl, // Optionally use the same thumbnail for SpaceThumbnail
        MediaFilePropertyBag: `{\"AutoStartExperienceID\":"${orgID}"}`,
      };

      const ProjandSpace = await CreateProjectandSpace(CPAS);
      if (ProjandSpace.status < 200 || ProjandSpace.status >= 300) {
        throw new Error(
          "Failed to create project and space due to network or server issue."
        );
      }

      const ProjandSpaceData = ProjandSpace.data;

      console.log("ProjandSpaceData", ProjandSpaceData);

      setSuccessMessage("Product and experience created successfully!");
      setErrorMessage("");
    } catch (error) {
      console.error("Error in creating product or experience:", error);
      setErrorMessage(error + " An unexpected error occurred.");
      setSuccessMessage("");
    } finally {
      console.log("Operation completed.");
      setIsLoading(false);
      window.location.reload();
      onClose();
    }
  }, [
    projName,
    productDesc,
    selectedOrgId,
    file,
    handleMediaUpload,
    orgID,
    onClose,
  ]);

  const extractPath = useCallback((url: string): string | null => {
    const regex =
      /https:\/\/[^\/]+(\/altouradevcontainer\/ProjectThumbnails\/[^?]+)/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }, []);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-10 bg-black bg-opacity-50 flex justify-center items-center modal-overlay backdrop-blur-sm">
      <div
        className="w-full max-w-lg p-6 bg-white rounded-xl"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="text-stone-900 text-2xl font-normal font-['Selawk'] leading-[42.72px] text-center">
          Create Project
        </div>
        <div className="mt-4 flex flex-col justify-start items-start gap-8">
          <div className="flex flex-col justify-start items-start gap-6 w-full">
            <div className="flex flex-col justify-start items-start w-full">
              <div className="w-full flex flex-col justify-start items-start gap-1">
                <div className="w-full pl-2 justify-start items-start gap-2.5 inline-flex">
                  <div className="text-gray-700 text-sm font-normal font-['Selawk'] leading-tight">
                    Project Name
                  </div>
                </div>
                <div className="w-full flex flex-col justify-start items-start">
                  <div
                    className={`w-full pl-2 pr-2 py-2 bg-white hover:border-[#e2ecf5] rounded-lg border ${
                      isProjectNameFocused
                        ? "border-blue-500"
                        : isProjectNameError
                        ? "border-red-300"
                        : "border-gray-300"
                    } flex items-center`}
                  >
                    <input
                      type="text"
                      placeholder="Add project name here"
                      className="outline-none w-full text-black-500 text-base font-normal font-['Selawk'] leading-normal"
                      onFocus={() => {
                        setIsProjectNameFocused(true);
                        setIsProjectNameError(false);
                      }}
                      onBlur={() => setIsProjectNameFocused(false)}
                      value={projName}
                      onChange={(e) => {
                        setProjName(e.target.value);
                        setIsProjectNameError(false);
                      }}
                      onClick={() => {
                        setIsProjectNameFocused(true);
                      }}
                    />
                  </div>
                  {isProjectNameError && (
                    <div className="w-full pl-2 justify-start items-start gap-2.5 inline-flex">
                      <div className="text-red-700 text-xs font-normal font-['Selawk'] leading-[17.40px]">
                        Invalid Project Name
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col mt-4 justify-start items-start gap-1 w-full">
                <div className="px-2 justify-start items-start inline-flex">
                  <div className="text-gray-700 text-sm font-normal font-['Selawk'] leading-tight">
                    Organisation
                  </div>
                </div>
                <div
                  className={`w-full  py-1 bg-white rounded-lg border ${
                    isOrganisationFocused
                      ? "border-blue-500"
                      : isOrganisationError
                      ? "border-red-300"
                      : "border-gray-300"
                  } flex items-center`}
                >
                  <CustomDropdown
                    list={list}
                    selectedOrgId={selectedOrgId ? parseInt(selectedOrgId) : 0} // Convert to number or default to 0
                    setSelectedOrgId={(id: number) =>
                      setSelectedOrgId(id.toString())
                    } // Wrap to convert number to string
                    updropdown={updropdown}
                    downdropdown={downdropdown}
                  />
                </div>
                {isOrganisationError && (
                  <div className="w-full pl-2 justify-start items-start gap-2.5 inline-flex">
                    <div className="text-red-700 text-xs font-normal font-['Selawk'] leading-[17.40px]">
                      Invalid Organisation
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col justify-start items-start gap-1 w-full">
              <div className="self-stretch px-2 justify-start items-start inline-flex">
                <div className="text-gray-700 text-sm font-normal font-['Selawk'] leading-tight">
                  Project Thumbnail
                </div>
              </div>
              <div className="w-full h-36 p-2.5 rounded-md border border-gray-300 flex items-center justify-center relative">
                <label
                  htmlFor="imageUpload"
                  className="w-full h-full flex flex-col justify-center items-center gap-2 cursor-pointer"
                >
                  {uploadedImage ? (
                    <div className="rounded justify-center items-center gap-2.5 flex relative">
                      <img
                        src={uploadedImage}
                        alt="Uploaded"
                        className="w-24 h-24 object-fit rounded-md"
                      />
                      <div
                        className="w-6 h-28 justify-center items-start inline-flex"
                        onClick={() => setUploadedImage(null)}
                      >
                        <div className="p-2 bg-white/opacity-0 rounded-full justify-start items-start flex hover:bg-[#f1f3f5] active:bg-[#e8eaed]">
                          <div className="w-4 h-4 justify-center items-center flex hover:bg-[#f1f3f5] active:bg-[#e8eaed]">
                            <div className="w-4 h-4 relative">
                              <img
                                src={trashicon}
                                alt="Delete"
                                className="w-4 h-4"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="p-4 bg-gray-100 rounded justify-center items-center gap-2.5 flex">
                        <img
                          src={importicon}
                          alt=""
                          className="w-12 h-12 opacity-25"
                        />
                      </div>
                      <div className="text-center text-gray-500 text-sm font-normal font-['Selawk'] leading-tight">
                        Click to add image
                      </div>
                    </>
                  )}
                </label>
                <input
                  type="file"
                  id="imageUpload"
                  className="hidden"
                  onChange={handleImageUpload}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center items-center gap-4">
            <button
              onClick={onClose}
              className="h-[39px] w-1/2 px-8 py-2 rounded-lg border border-blue-500 flex justify-center items-center hover:bg-[#f2f9fe] active:bg-[#e0f2ff]"
            >
              <div className="text-center text-blue-500 text-base font-normal font-['Selawk'] leading-normal">
                Cancel
              </div>
            </button>
            <button
              onClick={handleModalSubmit}
              className="h-[39px] w-1/2 px-8 py-2 bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] rounded-lg flex justify-center items-center"
            >
              <div className="text-center text-white text-base font-normal font-['Selawk'] leading-normal">
                Continue
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProjectPopup;
