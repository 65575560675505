import React, { Suspense, useEffect, useState } from "react";
import { useLoader, useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useSceneControl } from "../../Providers/SceneContextProvider";
import { GetObjectsDetails } from "../../../../services/experienceService";
import { ObjectData, Objectdetails } from "../../../../types/objectTypes";
import GLBModelViewerSpawnedObject from "./GLBModelViewerSpawnedObject";
import { Vector3 } from "three";
import LoaderCube from "./LoaderCube";
import config from "../../../../config";

export const ObjectSpawner: React.FC = () => {
  const { camera } = useThree();
  const SceneControl = useSceneControl();
  const [objectDetailsArray, setObjectDetailsArray] = useState<Objectdetails[]>(
    []
  );

  useEffect(() => {
    const fetchObjectDetails = async () => {

        SceneControl.onObjectData.current = async (objectData) => {

          

      try {
        const jsonparse: ObjectData = JSON.parse(
            objectData as unknown as string
        );
        console.log("jsonparse");
        console.log(jsonparse.ObjectID);
        
        const responseglb = await GetObjectsDetails(jsonparse.ObjectID);



      
          const direction = new Vector3(0, 0, -1).applyQuaternion(camera.quaternion).normalize();

          // Calculate the intersection point in front of the camera at y = 0
          const point = camera.position.clone().add(direction.multiplyScalar(2));

          point.y = 0;

          responseglb.data.position=new Vector3(point.x,point.y,point.z);

          responseglb.data.MediaFilePath=config.storageUrl+responseglb.data.MediaFilePath+"?key=" + Date.now()

          
      
       
          console.log(`Intersection point: ${point.x}, ${point.y}, ${point.z}`);
   

        // Add the new object details to the array
        setObjectDetailsArray((prevArray) => [...prevArray, responseglb.data]);
      } catch (error) {
        console.error("Error fetching object details:", error);
      }

    }
    };

    fetchObjectDetails();
  }, [SceneControl.onObjectData]);

  useEffect(() => {
    console.log("objectDetailsArray");
    console.log(objectDetailsArray);
  }, [objectDetailsArray]);

  return (
    <>
      {objectDetailsArray.map((objdetail, index) => (
       <Suspense fallback={<LoaderCube position={[
        objdetail.position?.x || 0, 
        objdetail.position?.y || 0, 
        objdetail.position?.z || 0
      ]} />}>  <GLBModelViewerSpawnedObject key={index} SpaceObject={objdetail} /></Suspense>
      ))}
    </>
  );
};
export default ObjectSpawner;
