// components/HdrEnvironment.tsx
import React, { useEffect, useMemo } from 'react';
import { useLoader, useThree } from '@react-three/fiber';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { PMREMGenerator } from 'three';
import { Texture } from 'three';

type HdrEnvironmentProps = {
  hdrPath: string;
};

const HdrEnvironment: React.FC<HdrEnvironmentProps> = ({ hdrPath }) => {
  const { gl, scene } = useThree();
  const pmremGenerator = useMemo(() => new PMREMGenerator(gl), [gl]);

  const hdrTexture = useLoader(RGBELoader, hdrPath) as Texture;
  const envMap = useMemo(() => {
    const generatedMap = pmremGenerator.fromEquirectangular(hdrTexture).texture;
    hdrTexture.dispose();
    return generatedMap;
  }, [hdrTexture, pmremGenerator]);

  useEffect(() => {
    scene.environment = envMap;
    return () => {
      scene.environment = null;
      envMap.dispose();
    };
  }, [envMap, scene]);

  useEffect(() => {
    return () => {
      pmremGenerator.dispose();
    };
  }, [pmremGenerator]);

  return null;
};

export default HdrEnvironment;