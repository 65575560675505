import React, { useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../../../components/shared/HeaderFooter/Header-Loggedout";
import { Footer } from "../../../components/shared/HeaderFooter/Footer";
import "./Login.css";
import logo from "../../../logos/Vectorlogo.svg";
import { loadFull } from "tsparticles";
import { SignInData } from "../../../types/authTypes";
import { signIn, isSSO, isDomain } from "../../../services/authService"; // Import isSSO function
import Particles, { initParticlesEngine } from "@tsparticles/react";
import {
  type Container,
  type ISourceOptions,
  MoveDirection,
  OutMode,
  Engine,
} from "@tsparticles/engine";
import { useLocation } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import eye from "../../../logos/ico_visiblepasswordico.svg";
import axios from "axios";

import icoaltoura from "../../../logos/ico_altouralogo.svg";
import bgimg from "../../../logos/Digits 4.png"
export const Login = () => {
  const [credentials, setCredentials] = useState<SignInData>({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<{ email: string; password: string }>({
    email: "",
    password: "",
  });
  const [apiErrors, setApiErrors] = useState<string>("");
  const [loginError, setLoginError] = useState<string>("");
  const [init, setInit] = useState(false);
  const [view, setView] = useState<"login" | "sso">("login");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordText, setShowPasswordText] = useState(false);
  const [hovered, setHovered] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPasswordText(!showPasswordText);
  };
  const location = useLocation();
  const params = useParams();

  useEffect(() => {
    initParticlesEngine(async (engine: Engine) => {
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);


  const validate = () => {
    let isValid = true;
    const errors = { email: "", password: "" };

    if (!credentials.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(credentials.email)) {
      errors.email = "Email is invalid";
      isValid = false;
    }

    if (!credentials.password && showPassword) {
      errors.password = "Password is required";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let response;

    try {
      if (view === "login") {
        response = await isSSO(credentials.email);
        localStorage.setItem("email", credentials.email);
      } else if (view === "sso") {
        const body = {
          domain: credentials.email,
        };
        response = await isDomain(body);
        localStorage.setItem("email", credentials.email);
      }

      // Debugging: Log the response data
      console.log("response.data");
      console.log(response.data);

      // Check for "NO_USER_WITH_EMAIL" and handle the error
      if (response && response.data === "NO_USER_WITH_EMAIL") {
        console.log("NO_USER_WITH_EMAIL error detected"); // Debugging line
        setApiErrors(
          "No user found with this email. Please check your email or sign up."
        );
        return; // Stop further processing
      }

      // Handle LOGIN_BLOCKED error
      if (
        response &&
        response.code === 198 &&
        response.data === "LOGIN_BLOCKED"
      ) {
        setApiErrors(
          "Too many failed attempts, please contact Altoura Support."
        );
        return; // Stop further processing
      }

      if (response && response.data && response.data.isSSOUser !== undefined) {
        if (response.data.isSSOUser === false) {
          setShowPassword(true);
          localStorage.setItem("email", credentials.email);
        } else {
          setShowPassword(false);
          if (view === "login") {
            response.data.redirectUrl = `https://portaldev.altoura.com/user/login/saml?email=${credentials.email}&source=WEB_CLIENT`;
          } else if (view === "sso") {
            response.data.redirectUrl = `https://portaldev.altoura.com/user/login/saml/v1?domain=${credentials.email}&source=WEB_CLIENT`;
            localStorage.setItem("newurl", response.data.redirectUrl);
          }
          window.location.href = response.data.redirectUrl;

          return;
        }
      }
    } catch (error) {
      console.error("Error checking SSO:", error);
      setShowPassword(false);
      if (
        axios.isAxiosError(error) &&
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500 &&
        error.response.status >= 100 &&
        error.response.status < 200
      ) {
        setApiErrors(
          error.response.data.message ||
            "Client error occurred. Please try again."
        );
      } else {
        setApiErrors("Invalid credentials. Please try again.");
      }
    }

    if (!validate()) return;

    if (showPassword) {
      try {
        await signIn(credentials);
        window.location.reload();
      } catch (error: any) {
        console.error("Login failed", error);
        if (
          axios.isAxiosError(error) &&
          error.response &&
          error.response.status >= 400 &&
          error.response.status < 500
        ) {
          setLoginError("Invalid credentials. Please try again.");
        } else {
          setApiErrors("An error occurred. Please try again.");
        }
      }
    }
  };

  const handleSSOLogin = () => {
    setView("sso");
  };
  const handlePasswordLogin = () => {
    setView("login");
  };

  return (
    <>
      {/* {init && (
        <Particles
          id="tsparticles"
          particlesLoaded={particlesLoaded}
          options={options}
        />
      )} */}
      <div className="min-h-screen flex flex-col " style={{ zIndex: 10 }}>
        {view === "login" ? (
          <div
            className="flex flex-grow justify-center items-center px-4"
            style={{
              background: "linear-gradient(40deg, #FFFFFF 0%, #4295E7 120%)",
            }}
          >
<div
  className="w-full max-w-5xl h-full max-h-[70vh] min-h-[70vh] flex bg-white rounded-xl flex-col lg:flex-row items-center gap-8 relative"
  style={{
    backgroundImage: `linear-gradient(90deg, rgba(0, 67, 130, 0.75) 0%, rgba(0, 133, 255, 0.75) 150%)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.35)",
  }}
>
  {/* Background image */}
  <img
    src={bgimg}
    alt="Background"
    className="absolute inset-0 w-full h-full object-cover  rounded-xl"
  />

  <div className="relative flex flex-col justify-start items-start text-start lg:w-1/2">
    <img
      src={icoaltoura}
      alt="Logo"
      className="absolute top-[-20vh] left-5 w-20 h-20"
      style={{ filter: "brightness(0) invert(1)" }}
    />
    <div className="text-white lg:font-selwak text-4xl pl-10 lg:text-4.8xl font-bold w-[66vh] mt-[8vh]">
      AI-POWERED LEARNING FOR
    </div>
    <div className="text-white lg:font-selwak pl-10 text-4xl lg:text-4.8xl font-semibold">
      EVERYONE
    </div>
    <div className="text-white mt-5 lg:font-selwak text-1xl pl-10 lg:text-2.8xl font-semibold w-[66vh]">
      We create AI-powered learning software that the world’s best
    </div>
    <div className="text-white lg:font-selwak pl-10 text-1xl lg:text-2.8xl font-semibold">
      brands use to improve frontline readiness and productivity
    </div>
  </div>

  <div className="flex flex-col pl-[15vh]">
    <div
      className="bg-white p-6 rounded-tr-2xl rounded-br-2xl shadow-md w-full max-w-sm h-[70vh]"
      style={{ zIndex: 10 }}
    >
      <h2 className="text-stone-900 text-xl ml-2 mt-[10vh] lg:text-3xl font-semibold mb-4">
        Welcome to <br />
        <span className="text-[#416ba9] text-3xl font-bold">
          Altoura
        </span>
      </h2>
      <h4 className="text-stone-900 text-m font-bold p-2">Log In</h4>
      <form onSubmit={handleSubmit} className="space-y-4 ml-2 ">
        <div>
          <label
            htmlFor="email"
            className="block text-xs font-medium mt-2 text-stone-900"
          >
            Email Id
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={credentials.email}
            onChange={handleChange}
            required
            className="mt-1 p-2 w-full bg-stone-50 rounded-md border border-stone-900/20"
          />
          {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
        </div>
        {showPassword && (
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-stone-900"
            >
              Password
            </label>
            <div className="relative">
              <input
                type={showPasswordText ? "text" : "password"}
                id="password"
                name="password"
                value={credentials.password}
                onChange={handleChange}
                required
                className="mt-1 p-2 w-full bg-stone-50 rounded-md border border-stone-900/20"
              />
              <div className="absolute inset-y-0 right-0 flex items-center px-2">
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="focus:outline-none hover:bg-[#f1f3f5] active:bg-[#e8eaed] rounded-full p-1"
                >
                  {showPassword ? (
                    <img src={eye} alt="eye icon" />
                  ) : (
                    <FaEye />
                  )}
                </button>
              </div>
            </div>
            {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>}
          </div>
        )}
        {apiErrors && <p className="text-red-500 text-xs mt-1">{apiErrors}</p>}
        {loginError && <p className="text-red-500 text-xs mt-1">{loginError}</p>}
        <button
          type="submit"
          className="w-full mt-4 text-white py-3 text-xs rounded-md hover:bg-[#1581eb] active:bg-[#4295E7]"
          style={{ backgroundColor: "#4295E7" }}
          onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#1581eb")}
          onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#4295E7")}
        >
          Log In
        </button>
        <div className="relative flex items-center text-xs">
          <div className="absolute left-1/2 transform -translate-x-1/2 bg-white px-2">or</div>
          <div className="w-[332px] h-[0px] border border-stone-900/opacity-10"></div>
        </div>
        <button
          type="button"
          style={{ backgroundColor: "#4295E7" }}
          onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#1581eb")}
          onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#4295E7")}
          onClick={handleSSOLogin}
          className="w-full bg-[#4295e7] hover:bg-[#1581eb] text-xs active:bg-[#4295e7] text-white py-3 rounded-md mt-2"
        >
          Log In with SSO
        </button>
        <Link
          to="/resetPassword"
          style={{ color: hovered ? "#1278dd" : "#4295E7" }}
          className="text-sm block text-center ml-[25vh] text-xs"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        ></Link>
      </form>
    </div>
  </div>
</div>

          </div>
        ) : (
          <div
            className="flex flex-grow justify-center items-center px-4 py-8 lg:py-16"
            style={{
              background: "linear-gradient(40deg, #FFFFFF 0%, #4295E7 120%)",
            }}
          >
<div
  className="w-full max-w-5xl h-full max-h-[70vh] min-h-[70vh] flex bg-white rounded-xl flex-col lg:flex-row items-center gap-8 relative"
  style={{
    backgroundImage: `linear-gradient(90deg, rgba(0, 67, 130, 0.75) 0%, rgba(0, 133, 255, 0.75) 150%)`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.35)"
  }}
>
  {/* Background image */}
  <img
    src={bgimg}
    alt="Background"
    className="absolute inset-0 w-full h-full object-cover rounded-xl "
  />

  <div className="relative flex flex-col justify-start items-start text-start lg:w-1/2">
    <img
      src={icoaltoura}
      alt="Logo"
      className="absolute top-[-20vh] left-5 w-20 h-20"
      style={{ filter: "brightness(0) invert(1)" }}
    />
    <div className="text-white lg:font-selwak text-4xl pl-10 lg:text-4.8xl font-bold w-[66vh] mt-[8vh]">
      AI-POWERED LEARNING FOR
    </div>
    <div className="text-white lg:font-selwak pl-10 text-4xl lg:text-4.8xl font-semibold">
      EVERYONE
    </div>
    <div className="text-white mt-5 lg:font-selwak text-1xl pl-10 lg:text-2.8xl font-semibold w-[66vh]">
      We create AI-powered learning software that the world’s best
    </div>
    <div className="text-white lg:font-selwak pl-10 text-1xl lg:text-2.8xl font-semibold">
      brands use to improve frontline readiness and productivity
    </div>
  </div>

  <div className="flex flex-col pl-[15vh] w-full">
  <div
      className="bg-white p-6 rounded-tr-2xl rounded-br-2xl shadow-md   min-w-[38.6vh] max-w-[60vh] h-[70vh]"
      style={{ zIndex: 10 }}
    >
      <h2 className="text-stone-900 text-xl ml-2 mt-[10vh] lg:text-3xl font-semibold mb-4">
        Welcome to
        <br />
        <span className="text-[#416ba9] text-3xl font-bold">Altoura</span>
      </h2>
      <h4 className="text-stone-900 text-m font-bold p-2">Log In with SSO</h4>
      <form onSubmit={handleSubmit} className="space-y-4 ml-2 w-[38.2vh]">
        <div>
          <label
            htmlFor="email"
            className="block text-xs font-medium mt-2 text-stone-900"
          >
            Domain
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={credentials.email}
            onChange={handleChange}
            required
            className="mt-1 p-2 w-full min-w-[35vh] bg-stone-50 rounded-md border border-stone-900/20"
          />
          {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
        </div>

        {apiErrors && <p className="text-red-500 text-xs mt-1">{apiErrors}</p>}
        {loginError && <p className="text-red-500 text-xs mt-1">{loginError}</p>}
        <button
          type="submit"
          className="w-full min-w-[35vh] mt-4 text-white py-3 text-xs rounded-md hover:bg-[#1581eb] active:bg-[#4295E7]"
          style={{ backgroundColor: "#4295E7" }}
          onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#1581eb")}
          onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#4295E7")}
        >
          Continue
        </button>

        <button
          type="button"
          style={{ backgroundColor: "#4295E7" }}
          onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#1581eb")}
          onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#4295E7")}
          onClick={handlePasswordLogin}
          className="w-full min-w-[35vh] bg-[#4295e7] hover:bg-[#1581eb] text-xs active:bg-[#4295e7] text-white py-3 rounded-md mt-2"
        >
          Back
        </button>
        <Link
          to="/resetPassword"
          style={{ color: hovered ? "#1278dd" : "#4295E7" }}
          className="text-sm block text-center ml-[25vh] text-xs"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        ></Link>
      </form>
    </div>
  </div>
</div>

          </div>
        )}
        <Footer />
      </div>
    </>
  );
};
