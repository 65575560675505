
import React, { useState } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import { Header } from '../../../components/shared/HeaderFooter/Header-Loggedout';
import { Footer } from '../../../components/shared/HeaderFooter/Footer';

import { SignUpData } from '../../../types/authTypes';
import { signUp } from '../../../services/authService';


export const Signup = () => {


    const [userData, setUserData] = useState<SignUpData>({ username: '', email: '', password: '' });
    const [errors, setErrors] = useState<{ username: string; email: string; password: string }>({ username: '', email: '', password: '' });

    const validate = () => {
        let isValid = true;
        const errors = { username: '', email: '', password: '' };

        if (!userData.email) {
            errors.email = 'Email is required';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
            errors.email = 'Email is invalid';
            isValid = false;
        } else {
            var nameMatch = userData.email.match(/^([^@]*)@/);
            userData.username = nameMatch ? nameMatch[1] : "";
        }

        if (!userData.password) {
            errors.password = 'Password is required';
            isValid = false;
        } else if (userData.password.length < 6) {
            errors.password = 'Password must be at least 6 characters';
            isValid = false;
        }
        setErrors(errors);
        return isValid;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };


    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!validate()) return;
        try {
            await signUp(userData);
            navigate("/");
            // Redirect or update state as needed
        } catch (error) {
            console.error('Signup failed', error);
        }
    };

    return (
        <div>
            <Header />
            <div className="bg-gray-100">





                <div className="flex items-center justify-center min-h-screen">
                    <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-sm">
                        <h2 className="mb-6 text-2xl font-semibold text-center">Create Account</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                                <input type="email" id="email" name="email" required value={userData.email} onChange={handleChange}
                                    className="mt-1 p-2 w-full border rounded-md" />
                            </div>
                            <div className="mb-6">
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                                <input type="password" id="password" name="password" required value={userData.password} onChange={handleChange}
                                    className="mt-1 p-2 w-full border rounded-md" />
                            </div>
                            <button type="submit"
                                className="w-full bg-black text-white p-2 rounded-md">
                                Sign up
                            </button>
                            <div className="mt-3 text-black text-sm flex justify-center">
                                Already have an account?
                                <Link to='/'>
                                    <span className="text-black ml-2 font-semibold"> Login here</span>
                                </Link>

                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
};
