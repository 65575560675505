import { useState, useEffect, useCallback, useRef , useMemo } from "react";
import { Header } from "../../../components/shared/HeaderFooter/Header";
import { Footer } from "../../../components/shared/HeaderFooter/Footer";
import { ObjectListView } from "./ObjectListView";
import { Project, Organization } from "../../../types/projectTypes";
import { listProjectsAndOrgs } from "../../../services/projectService";
import localStorageService from "../../../services/localStorageService";
import "./ObjectList.css";
import add from "../../../logos/ico_add.svg";
import notfoundicon from "../../../logos/Group.svg";
import closesearch from "../../../logos/ico_close.svg";
import searchicon from "../../../logos/ico_search.svg";
import sorticon from "../../../logos/ico_sort.svg";
import chevronUp from "../../../logos/ico_chevron_up.svg";
import chevronDown from "../../../logos/ico_down.svg";
import SkeletonLoader from "../../Project/ProjectList/components/Skeletonloader";
import cactus from "../../../logos/EmptyProjects.svg";
import debounce from "lodash/debounce";
import { GetObjectAndTags, ListObjectsByTags } from "../../../services/experienceService";
import { ObjectData, TagsObjects, Tags } from "../../../types/objectTypes";
import CreateObj from './components/CreateObj';

interface ObjectListProps {
  projectID: number[];
}

interface SortOption {
  value: string;
  label: string;
}

const sortOptions: SortOption[] = [
  { value: "latest", label: "Latest" },
  { value: "oldest", label: "Oldest" },
  { value: "A-Z", label: "A-Z" },
  { value: "Z-A", label: "Z-A" },
];

interface CustomDropdownProps {
  options: SortOption[];
  value: string;
  onChange: (option: SortOption) => void;
}

const CustomDropdown: React.FC<CustomDropdownProps> = ({ options, value, onChange }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: SortOption) => {
    onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-[160px]" ref={dropdownRef}>
      <div
        className={`h-10 rounded border hover:bg-gray-100 active:bg-gray-300  ${isOpen ? 'border-blue-500' : 'border-gray-300'} bg-white cursor-pointer flex justify-between items-center px-2`}
        onClick={handleToggle}
      >
        <div className="flex items-center">
          <div className="w-6 h-6 justify-center items-center flex">
            <div className="w-6 h-6 relative">
              <img src={sorticon} alt="Sort" />
            </div>
          </div>
          <div className="ml-2 text-sm text-stone-900 truncate">
            Sort By: {options.find((opt) => opt.value === value)?.label}
          </div>
        </div>
        <img src={isOpen ? chevronUp : chevronDown} alt="Chevron" className="w-4 h-4 ml-2" />
      </div>
      {isOpen && (
        <div className="absolute mt-1 w-full rounded bg-white z-10">
          {options.map((option) => (
            <div
              key={option.value}
              className="px-4 py-2 cursor-pointer flex justify-between items-center hover:bg-gray-200"
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
              {option.value === value && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={2}
                  stroke="#4295e7"
                  className="w-4 h-4 text-blue-500"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export const ObjectList = () => {
  const [list, setList] = useState<Organization[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(null);
  const [projects, setProjects] = useState<any>([]);
  const [filteredProjects, setFilteredProjects] = useState<ObjectData[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const [totalProjects, setTotalProjects] = useState(20);
  const [isFocused, setIsFocused] = useState(false);
  const [sortType, setSortType] = useState<string>("latest");
  const [tagsdata, setTagsData] = useState<any>();
  const [objresponse, setObjresponse] = useState<any>();
  const [searchFilteredProjects, setSearchFilteredProjects] = useState<ObjectData[]>([]);

  const openModal = () => setModalVisible(true);
  const closeModal = () => setModalVisible(false);
  const [projres , setProjres] = useState<any>();
  const inputRef = useRef<HTMLInputElement>(null);
  localStorage.setItem("isObjects" , "true");
  localStorage.setItem("isProjects" , "false");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const userId = parseInt(localStorage.getItem("userID") || "-1");
        const projectResponse = await listProjectsAndOrgs(userId);
        if (projectResponse.orgsList.length === 0 || projectResponse.data.length === 0) {
          throw new Error("Empty data array received from API");
        }
        setList(projectResponse.orgsList);
        setProjres(projectResponse);

        const projectIDs = projectResponse.data.flatMap((project) =>
          project.projectSections.map((section) => section.ProjectID)
        );
        if (projectIDs.length === 0) {
          throw new Error("No project section IDs found");
        }
        const storedOrgId = localStorage.getItem("selectedOrgId");
        if (storedOrgId) {
          const orgId = parseInt(storedOrgId, 10);
          setSelectedOrgId(orgId);
          const filteredProjectsData = projectResponse.data.filter(
            (project) => project.OrganizationID === orgId
          );
          if (filteredProjectsData.length === 0) {
            throw new Error("No projects found for the selected organization");
          }
          const projectID = filteredProjectsData[0].projectSections[0].ProjectID;
          const ObjsResponse = await GetObjectAndTags(projectID);
          setProjects(ObjsResponse.data.objects);
          setTagsData(ObjsResponse.data.tags);
          setObjresponse(ObjsResponse.data);
          setFilteredProjects(ObjsResponse.data.objects);
          setSearchFilteredProjects(ObjsResponse.data.objects);
        } else {
          const projectID = projectIDs[0];
          const ObjsResponse = await GetObjectAndTags(projectID);
          setProjects(ObjsResponse.data.objects);
          setTagsData(ObjsResponse.data.tags);
          setObjresponse(ObjsResponse.data);
          setFilteredProjects(ObjsResponse.data.objects);
          setSearchFilteredProjects(ObjsResponse.data.objects);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load projects.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const filterProjects = () => {
      let filtered = Array.isArray(filteredProjects) ? filteredProjects : [];
      if (selectedOrgId !== null) {
        filtered = filtered.filter((project) => project.OrganizationID === selectedOrgId);
      }
      if (searchQuery) {
        filtered = filtered.filter((project) =>
          project.ObjectName.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
      if (sortType === "oldest") {
        filtered = filtered.sort(
          (a, b) => new Date(a.CreatedOn).getTime() - new Date(b.CreatedOn).getTime()
        );
      } else if (sortType === "latest") {
        filtered = filtered.sort(
          (a, b) => new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime()
        );
      } else if (sortType === "A-Z") {
        filtered = filtered.sort((a, b) => a.ObjectName.localeCompare(b.ObjectName));
      } else if (sortType === "Z-A") {
        filtered = filtered.sort((a, b) => b.ObjectName.localeCompare(a.ObjectName));
      }
      setSearchFilteredProjects(filtered);
    };
    filterProjects();
  }, [searchQuery, selectedOrgId, filteredProjects, sortType]);

  const handleOrgChange = useCallback((orgId: number) => {
    localStorageService.setItem("selectedOrgId", orgId.toString());
    setSelectedOrgId(orgId);
  }, []);

  const toggleSearch = () => {
    setIsSearchActive(!isSearchActive);
    setSearchQuery("");
  };

  useEffect(() => {
    if (isSearchActive && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchActive]);

  const debouncedSearch = useMemo(
    () => debounce((query: string) => setSearchQuery(query), 300),
    []
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  const handleSortChange = (option: SortOption) => {
    setSortType(option.value);
  };

  if (loading) {
    return (
      <>
        <div className="flex flex-col min-h-screen">
          <div className="z-30">
            <Header data={""} list={list} onOrgChange={handleOrgChange} projectResponse={projres} />
          </div>
          <div className="flex-grow flex flex-col items-center mt-16">
            <div className="w-full max-w-[1200px] px-4 sm:px-6 lg:px-8">
              <div className="bg-white w-full min-h-[90px] px-4 py-3 flex justify-between items-center gap-4 sm:gap-8 lg:gap-14">
                <div className="text-4xl lg:text-2xl font-normal font-['Selawk'] leading-normal lg:leading-[49.84px]">
                  Objects
                </div>
                <div className="flex items-center gap-4">
                  {isSearchActive ? (
                    <div className="flex items-center">
                      <div
                        className={`w-[250px] h-10 pl-3 pr-2 py-2 bg-white rounded border flex-col justify-center items-center inline-flex ${
                          isFocused ? "border-blue-500" : "border-gray-300"
                        }`}
                      >
                        <div className="self-stretch justify-start items-center gap-1 inline-flex">
                          <input
                            type="text"
                            className="grow shrink basis-0 focus:outline-none text-black placeholder-gray-400 text-sm font-normal font-['Selawk'] leading-tight"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder="Search Object"
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            ref={inputRef}
                          />
                          <div className="p-1 rounded-3xl justify-start items-start flex">
                            <div
                              className="w-4 h-4 justify-center items-center flex cursor-pointer"
                              onClick={toggleSearch}
                            >
                              <img src={closesearch} alt="closesearch" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        onClick={openModal}
                        className="w-[138px] h-10 pl-2 pr-4 py-2 mr-8 rounded border border-gray-300 justify-center items-center gap-1 inline-flex cursor-pointer"
                      >
                        <div className="w-6 h-6 justify-center items-center flex">
                          <div className="w-6 h-6 relative">
                            <img src={add} alt="Add" />
                          </div>
                        </div>
                        <div className="text-center text-sm text-stone-900 text-base font-normal font-['Selawk'] leading-normal">
                          New Object
                        </div>
                      </div>
                      
                      <div className="ml-[-4vh]">
                        <CustomDropdown
                          options={sortOptions}
                          value={sortType}
                          onChange={handleSortChange}
                        />
                      </div>
                      <img
                        src={searchicon}
                        alt="searchicon"
                        className="cursor-pointer ml-[-1vh] hover:bg-[#f1f3f5] active:bg-[#e8eaed] p-2 rounded-full "

                        onClick={toggleSearch}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="flex w-full max-w-[1200px] mt-[-3vh] justify-center items-center px-4 sm:px-6 lg:px-8">
              <SkeletonLoader count={totalProjects} />
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }

  if (error) {
    return (
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <strong className="font-bold">Error:</strong>
        <span className="block sm:inline"> {error}</span>
      </div>
    );
  }

  if (filteredProjects.length === 0) {
    return (
      <>
        <div className="flex flex-col min-h-screen">
          <div className="z-30">
            <Header data={""} list={list} onOrgChange={handleOrgChange} />
          </div>
          <div className="flex-grow flex flex-col items-center justify-center">
            <div className="flex justify-center items-center h-[70vh] lg:h-[75vh] px-4 sm:px-6 lg:px-8 overflow-hidden">
              <div className="flex flex-col justify-center items-center gap-8 w-full max-w-screen-lg mx-auto">
                <div className="flex flex-col justify-start items-center gap-4">
                  <div className="w-full max-w-[200vh] h-auto mt-4">
                    <img
                      src={cactus}
                      className="w-full max-w-[411.97px] h-auto"
                      alt="Not Found"
                    />
                  </div>
                  <div className="flex flex-col justify-start items-center mt-2">
                    <div className="text-center text-stone-900 text-xl font-normal font-['Selawk'] leading-[29px]">
                      No Objects found
                    </div>
                  </div>
                </div>
                <div
                  onClick={openModal}
                  className="w-[166px] h-10 pl-4 pr-8 py-2 bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] rounded-lg justify-center items-center gap-2 inline-flex cursor-pointer"
                >
                  <div className="w-6 h-6 justify-center items-center flex">
                    <div className="w-6 h-6 relative">
                      <img src={add} alt="Add" />
                    </div>
                  </div>
                  <div className="text-center text-white text-base font-normal font-['Selawk'] leading-normal">
                    New Object
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
        <div className="z-10">
          <CreateObj isVisible={isModalVisible} onClose={closeModal} objresponse={objresponse} />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <div className="z-30">
          <Header data={""} list={list} onOrgChange={handleOrgChange} projectResponse={projres}/>
        </div>
        <div className="flex-grow flex flex-col items-center mt-16">
          <div className="w-full fixed max-w-[1200px] z-10 px-4 sm:px-6 lg:px-8">
            <div className="bg-white w-full min-h-[90px] px-4 py-3 flex justify-between items-center gap-4 sm:gap-8 lg:gap-14">
              <div className="text-4xl lg:text-2xl font-normal font-['Selawk'] leading-normal lg:leading-[49.84px]">
                Objects
              </div>
              <div className="flex items-center gap-4">
                {isSearchActive ? (
                  <div className="flex items-center">
                    <div
                      className={`w-[250px] h-10 pl-3 pr-2 py-2 bg-white rounded border flex-col justify-center items-center inline-flex ${
                        isFocused ? "border-blue-500" : "border-gray-300"
                      }`}
                    >
                      <div className="self-stretch justify-start items-center gap-1 inline-flex">
                        <input
                          type="text"
                          className="grow shrink basis-0 focus:outline-none text-black placeholder-gray-400 text-sm font-normal font-['Selawk'] leading-tight"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          placeholder="Search Object"
                          onFocus={() => setIsFocused(true)}
                          onBlur={() => setIsFocused(false)}
                          ref={inputRef}
                        />
                        <div className="p-1 rounded-3xl justify-start items-start flex">
                          <div
                            className="w-4 h-4 justify-center items-center flex cursor-pointer"
                            onClick={toggleSearch}
                          >
                            <img src={closesearch} alt="closesearch" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      onClick={openModal}
                      className="w-[138px] h-10 pl-2 pr-4 py-2 mr-8 rounded border border-gray-300 justify-center items-center gap-1 inline-flex cursor-pointer hover:bg-[#f1f3f5] active:bg-[#e8eaed]"
                    >
                      <div className="w-6 h-6 justify-center items-center flex">
                        <div className="w-6 h-6 relative">
                          <img src={add} alt="Add" />
                        </div>
                      </div>
                      <div className="text-center text-sm text-stone-900 text-base font-normal font-['Selawk'] leading-normal">
                        New Object
                      </div>
                    </div>
                    
                    <div className="ml-[-4vh]">

                      <CustomDropdown
                        options={sortOptions}
                        value={sortType}
                        onChange={handleSortChange}
                      />
                    </div>
                    <img
                      src={searchicon}
                      alt="searchicon"
                      className="cursor-pointer  ml-[-1vh] hover:bg-[#f1f3f5] active:bg-[#e8eaed] p-2 rounded-full "

                      onClick={toggleSearch}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="flex w-full max-w-[1600px] mt-8 justify-center items-center mt-4 px-4 sm:px-6 lg:px-8">
            {searchFilteredProjects.length === 0 ? (
              <div className="flex justify-center items-center h-[70vh] lg:h-[75vh] px-4 sm:px-6 lg:px-8 overflow-hidden">
                <div className="flex flex-col justify-center items-center gap-8 w-full max-w-screen-lg mx-auto">
                  <div className="flex flex-col justify-start items-center gap-4">
                    <div className="w-full max-w-[200vh] h-auto mt-4">
                      <img
                        src={notfoundicon}
                        className="w-full max-w-[411.97px] h-auto"
                        alt="Not Found"
                      />
                    </div>
                    <div className="flex flex-col justify-start items-center mt-2">
                      <div className="text-center text-stone-900 text-xl font-normal font-['Selawk'] leading-[29px]">
                        No results found for "{searchQuery}"
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex w-full max-w-[1600px] justify-center items-center ml-7 mt-10 px-4 sm:px-6 lg:px-8">
                <ObjectListView projects={searchFilteredProjects} />
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
      <div className="z-10">
        <CreateObj isVisible={isModalVisible} onClose={closeModal} objresponse={objresponse} />
      </div>
    </>
  );
};

export default ObjectList;
