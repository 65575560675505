import { useEffect, useRef, useState } from 'react';
import { Audio } from 'three';
import { Html } from '@react-three/drei';
import * as THREE from 'three';
import useAudioListener from './AudioListener';

const AudioPlayer: React.FC<{ url: string }> = ({ url }) => {
  const listener = useAudioListener();
  const audioRef = useRef<Audio | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showPopup, setShowPopup] = useState(true);

  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
      setShowPopup(false);
    }
  };

  useEffect(() => {
    if (!listener) return;

    const sound = new Audio(listener);
    audioRef.current = sound;

    const audioLoader = new THREE.AudioLoader();
    audioLoader.load(url, (buffer) => {
      if (audioRef.current) {
        audioRef.current.setBuffer(buffer);
        audioRef.current.setVolume(0.5);
        handlePlay(); // Autoplay when the buffer is loaded
      }
    });

    return () => {
      if (audioRef.current) {
        audioRef.current.stop();
        audioRef.current.disconnect();
      }
    };
  }, [listener, url]);

  return (
    <>
   
    </>
  );
};

export default AudioPlayer;