import React, { useEffect, useState, ElementType } from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../../../services/authService';  // Assuming this is correctly implemented
import "./ProtectedRoute.css";
// Define an interface for the props
interface ProtectedRouteProps {
    component: ElementType;  // ElementType covers both class and functional components
    isSkipLogin?: boolean;  // Assuming you meant this to be a boolean
    view?:number;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, view,isSkipLogin = false, ...rest}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
        const checkAuth = async () => {
            const authenticated = await isAuthenticated();  // Assuming isAuthenticated correctly handles the auth check
            setIsAuth(authenticated);
            setIsLoading(false);
        };

        checkAuth();
    }, []);

    if (isLoading) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen bg-transparent">
                {/* <div className="spinner border-t-4 border-blue-900 rounded-full w-16 h-16 animate-spin"></div>
                <div className="text-2xl mt-4">
                    {'Altoura'.split('').map((char, index) => (
                        <span key={index} className="letter">{char}</span>
                    ))}
                </div> */}
            </div>
        );
    }
    


    if (isAuth && isSkipLogin) {
        return <Navigate to="/dashboard" />;
    } else if (!isAuth && isSkipLogin) {
        return <Component {...rest} view={view}/>;
    } else {
        return isAuth ? <Component {...rest} view={view}/> : <Navigate to="/" />;
    }
};

export default ProtectedRoute;
