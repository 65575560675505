import React from "react";
import righticon from "../../logos/ico_right_arrow.svg";
import lefticon from "../../logos/ico_left_arrow.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import {
  AnnotationDetails,
  AudioTextTextData,
  Choice,
  DisplayTemplate,
  Experience,
  ExperienceResponseData,
  findExperienceByExperienceID,
  findMediaFileByDisplayTemplateID,
  findNextNode,
  findNodeByID,
  getAudioTextTextData,
  getLinkedNodeData,
  getMediaFilePath,
  getRolePlayChoiceTextData,
  getRolePlayStepByStepTextData,
  getStartNodeForExperience,
  RolePlaySectionObject,
  SOTypeID,
} from "../../types/experienceTypes";
import "./StepNavigationEdit.css";
// Define an interface for the component's props
interface StepNavigationProps {
  onPrevious: () => void; // Assumes these functions return nothing
  onNext: () => void;
  title: React.ReactNode; // New prop for the step title
  showNavigationButtons?: boolean; // Optional prop to show/hide navigation buttons
  isFirstStep: boolean; // Indicates if the current step is the first step
  isLastStep: boolean;
  onClick?: () => void;

}

export const StepNavigation: React.FC<StepNavigationProps> = ({
  onPrevious,
  onNext,
  title,
  showNavigationButtons = true, // Default to true if not provided
  isFirstStep,
  isLastStep,
}) => (
  <div
    className=" lg:w-[865px] mt-5 mx-4 my-4 md:mx-20 p-7 pt-0 bg-white flex justify-end items-end shadow-lg rounded-md"
    style={{
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.08), 0 1px 3px rgba(0, 0, 0, 0.1)",
    }}
  >
    <div className="flex justify-center items-start w-full max-w-7xl">
      <div className="mt-5 flex justify-between items-center w-full px-4 sm:px-6 lg:px-8">
        {!isFirstStep && (
         <button onClick={onPrevious} className="mr-5 p-2 sm:p-3 md:p-4 hover:bg-[#f1f3f5] active:bg-[#e8eaed] rounded">
         <img src={lefticon} alt="Previous" className="w-5 h-5 sm:w-6 sm:h-6"  style={{ transform: "scale(2)" }} />
       </button>
        )}
        <div className="step-navigation rounded flex-grow flex flex-col justify-center items-center text-center text-zinc-800 text-sm sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-normal leading-normal">
          {title}
        </div>
        {!isLastStep && title !== "Assessment" && (
          <button
            onClick={onNext}
            className="ml-5 p-2 rounded sm:p-3 md:p-4 bg-[#4295e7] hover:bg-[#1581eb] active:bg-[#4295e7] text-white"
          >
            <img
              src={righticon}
              alt="Next"
              className="w-5 h-5 " // Adjust these values accordingly to scale the icon within the button
              style={{ transform: "scale(2)" }} // Adjust scale value to desired size
            />
          </button>
        )}
      </div>
    </div>
  </div>
);
